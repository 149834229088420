import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import HeaderNavWrapper from '@layouts/HeaderNavWrapper';
import { IconWarningIllustration } from '@icons/index';
import { containerStyles } from './styles/expiredInvitation.styles';

const ExpiredInvitation = () => {
  return (
    <HeaderNavWrapper hideBackButton hideFinishLater>
      <Stack {...containerStyles}>
        <IconWarningIllustration />
        <Stack alignItems="center" spacing="16px">
          <Text textStyle="headline-2xl">Invitation link expired</Text>
          <Stack spacing="8px" alignItems="center">
            <Text textStyle="md">Sorry, the invitation link is no longer valid.</Text>
            <Text textStyle="md">Please contact your landlord.</Text>
          </Stack>
        </Stack>
      </Stack>
    </HeaderNavWrapper>
  );
};

export default ExpiredInvitation;
