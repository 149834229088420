import { isMobile } from 'react-device-detect';

export const containerStyles = {
  bgColor: 'white',
  minHeight: '100%',
  height: '100%',
  maxH: '100%',
  spacing: 0,
  w: '100%',
  overflow: 'auto',
};

export const leftElementsContainerStyles = {
  p: isMobile ? '25px 16px' : '30px',
  boxShadow: isMobile ? 'none' : 'inset 0px -1px 0px #F3F4F7',
  gridTemplateColumns: isMobile ? '1fr 3fr 1fr' : '1fr 1fr',
};
