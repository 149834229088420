// @flow
import React from 'react';

import {
  Text,
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  VStack,
  Stack,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useGate } from 'statsig-react';

import BaselaneButton from '@shared/components/BaselaneButton';
import { useErrorModal } from '@core/contexts/ErrorModalContext';
import IconWarningWithBackground from '@icons/IconWarningWithBackground';
import { useAuth } from '@core/contexts/AuthContext';
import { openMessenger } from '@core/utils/messengerHelpers';
import { DASHBOARD } from '@routing/constants/route.constants';

import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';

import {
  accentTextStyles,
  errorModalButtonGroupStyles,
  errorModalContentStyles,
} from '@core/apollo/components/BaselaneErrorModal/style';
import CriticalErrorModal from '../CriticalErrorModal';

// Component
function BaselaneErrorModal() {
  const history = useHistory();
  const { value: zendeskEnabled } = useGate('enable_zendesk_bubble');
  const isDesktopScreen = useIsLargerThanSmallScreen();
  const { isOpen, onClose, errorModalDetails } = useErrorModal();
  const { tenant } = useAuth();

  function handleRedirectToPage(path: string) {
    onClose();
    history.push(path);
  }

  const handleContactSupport = () => {
    handleRedirectToPage(DASHBOARD);
    openMessenger(zendeskEnabled);
  };

  const title = errorModalDetails?.title ?? 'Something Went Wrong';

  const description =
    errorModalDetails?.description ??
    `We’re sorry, we’re having an issue. Please try again or select an option below to
get you back on track`;

  if (errorModalDetails?.isCritical) {
    return (
      <CriticalErrorModal
        title={title}
        description={description}
        isOpen={isOpen}
        onClose={onClose}
        criticalActionParams={errorModalDetails?.criticalActionParams}
        isDesktopScreen={isDesktopScreen}
      />
    );
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent {...errorModalContentStyles(isDesktopScreen)}>
        <ModalBody p="0">
          <VStack spacing="40px">
            <IconWarningWithBackground width={isDesktopScreen ? 96 : 64} />

            <Box>
              <Text variant="accent" {...accentTextStyles(isDesktopScreen)}>
                {title}
              </Text>
              <Text variant="secondary" color="brand.neutral.600">
                {description}
              </Text>
            </Box>

            {tenant && !errorModalDetails && (
              <Stack {...errorModalButtonGroupStyles(isDesktopScreen)}>
                <BaselaneButton
                  size="lg"
                  variant="primary"
                  onClick={() => handleRedirectToPage(DASHBOARD)}
                >
                  Go to Dashboard
                </BaselaneButton>
                <BaselaneButton size="lg" variant="secondary" onClick={handleContactSupport}>
                  Contact Support
                </BaselaneButton>
              </Stack>
            )}

            {(!tenant || errorModalDetails) && (
              <BaselaneButton size="lg" variant="primary" onClick={onClose}>
                Close
              </BaselaneButton>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default BaselaneErrorModal;
