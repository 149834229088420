import { body, headlines } from '@theme/text.style';

export const Text = {
  variants: {
    accent: {
      color: 'brand.neutral.700',
      ...headlines['headline-lg'],
    },
    primary: {
      color: 'brand.neutral.700',
      ...headlines['headline-sm'],
    },
    secondary: {
      color: 'brand.neutral.700',
      ...body.sm,
    },
    light: {
      color: 'brand.neutral.600',
      ...body.xs,
    },
    white: {
      color: 'brand.neutral.white',
    },
    link: {
      color: 'brand.blue.800A',
      ...body.sm,
      cursor: 'pointer',

      _hover: {
        textDecoration: 'underline',
      },
    },
  },
};
