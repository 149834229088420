// @flow
import React from 'react';
import { Icon } from '@chakra-ui/react';
import type { IconProps } from '@icons/types';

function IconClose({ width = 10, height = 10, color = 'currentColor', ...rest }: IconProps) {
  return (
    <Icon
      {...rest}
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.529247 0.528514C0.789596 0.268165 1.21171 0.268165 1.47206 0.528514L5.00065 4.05711L8.52925 0.528514C8.7896 0.268165 9.21171 0.268165 9.47206 0.528514C9.73241 0.788864 9.73241 1.21097 9.47206 1.47132L5.94346 4.99992L9.47206 8.52851C9.73241 8.78886 9.73241 9.21097 9.47206 9.47132C9.21171 9.73167 8.7896 9.73167 8.52925 9.47132L5.00065 5.94273L1.47206 9.47132C1.21171 9.73167 0.789596 9.73167 0.529247 9.47132C0.268897 9.21097 0.268897 8.78886 0.529247 8.52851L4.05784 4.99992L0.529247 1.47132C0.268897 1.21097 0.268897 0.788864 0.529247 0.528514Z"
        fill={color}
      />
    </Icon>
  );
}

export default IconClose;
