export const radioGroupItemStyles = {
  padding: '12px',
  position: 'relative',
  fontWeight: 'medium',
  lineHeight: '44px',
  _focus: {
    boxShadow: 'none',
  },
  _checked: {
    background: 'brand.blue.700',
    borderColor: 'brand.blue.700',
    color: 'brand.neutral.white',
    _before: {
      content: '""',
      display: 'inline-block',
      position: 'relative',
      width: '50%',
      height: '50%',
      borderRadius: '50%',
      background: 'currentColor',
    },
    _after: {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
      borderRadius: '4px',
      backgroundColor: 'brand.blue.50',
    },
  },
};

export const radioGroupContainerStyles = {
  fontWeight: 'normal',
  color: 'brand.neutral.600',
};

export const radioLabelWrapper = {
  display: 'inline-block',
  wordBreak: 'normal',
};
