/**
 * Checks Zendesk feature flag in Statsig and conditional renders Zendesk chat bubble
 * Currently, it needs to sit within the StatsigProvider in order to check feature flag
 *
 * Cleanup:
 * Though IntercomProvider is conditionally rendered here, this isn't in effect as Intercom is actually integrated via Segment. It's not possible to turn it off as it's shared across all environments
 * The Provider is still necessary because of autoBootProps, and until Zendesk feature flag is turned on
 * When Zendesk has fully been migrated and Intercom turned off on prod, we need to remove react-use-intercom package and Zendesk script can be moved outside of StatsigProvider so it can be rendered earlier
 */

import React from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { useGate } from 'statsig-react';
import Zendesk from './zendesk';

const INTERCOM_APP_ID = 'qxgwg4ya';

const ZendeskScript = ({ children }: any) => {
  const { value: zendeskEnabled, isLoading } = useGate('enable_zendesk_bubble');

  const zendeskKey = process.env.REACT_APP_ZENDESK_KEY;

  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      autoBoot
      autoBootProps={{ hideDefaultLauncher: !isLoading && zendeskEnabled }}
    >
      {children}
      {!isLoading && zendeskEnabled && <Zendesk defer zendeskKey={zendeskKey} />}
    </IntercomProvider>
  );
};

export default ZendeskScript;
