import React, { useState, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import {
  SEND_OTP_REGISTERED_USER,
  VERIFY_OTP_REGISTERED_USER,
} from '@core/apollo/requests/user.graphql';
import { ERROR_MESSAGES } from '@shared/components/VerifyEmailModal/helpers/verifyEmailModal.helpers';

const OTPContext = React.createContext({});

export const OTPProvider = ({ children }: any) => {
  const [emailOtpError, setEmailOtpError] = useState(null);
  const [isGetEmailOtpSuccessful, setIsGetEmailOtpSuccessful] = useState(false);
  const [isSendEmailOtpLoading, setIsSendEmailOtpLoading] = useState(false);
  const [isVerifyEmailOtpLoading, setIsVerifyEmailOtpLoading] = useState(false);

  const [sendOtpRegisteredUser] = useMutation(SEND_OTP_REGISTERED_USER);
  const [verifyOtpRegisteredUser] = useMutation(VERIFY_OTP_REGISTERED_USER);

  const state = useMemo(() => {
    const handleVerifyOtpRegisteredUser = ({ code, handleVerifyEmailOtpSuccess = () => {} }) => {
      setIsGetEmailOtpSuccessful(false);
      setIsVerifyEmailOtpLoading(true);
      verifyOtpRegisteredUser({ variables: { channel: 'email', code } })
        .then(({ data, errors }) => {
          if (errors) {
            setEmailOtpError(ERROR_MESSAGES.OTP_SEND_ERROR);
            setIsVerifyEmailOtpLoading(false);
            return;
          }

          if (data.verifyOtpRegisteredUser === 'true') {
            setEmailOtpError(null);
            handleVerifyEmailOtpSuccess();
          } else if (data.verifyOtpRegisteredUser === 'false') {
            setEmailOtpError(ERROR_MESSAGES.OTP_VERIFY_ERROR);
            setIsVerifyEmailOtpLoading(false);
          } else {
            setEmailOtpError(ERROR_MESSAGES.OTP_SEND_ERROR);
            setIsVerifyEmailOtpLoading(false);
          }
        })
        .catch(() => {
          // console.error(error);
          setEmailOtpError(ERROR_MESSAGES.OTP_SEND_ERROR);
          setIsVerifyEmailOtpLoading(false);
        });
    };

    const handleSendOtpRegisteredUser = ({
      isTriggeredFromModal = false,
      handleSendEmailOtpSuccess = () => {},
    }) => {
      setIsGetEmailOtpSuccessful(false);
      setEmailOtpError(null);
      if (!isTriggeredFromModal) {
        setIsSendEmailOtpLoading(true);
      }
      sendOtpRegisteredUser({
        variables: { channel: 'email' },
      })
        .then(({ errors }) => {
          if (errors) {
            const { messageCode } = errors[0]?.extensions?.exception?.response ?? {};
            const error = ERROR_MESSAGES[messageCode];
            setIsGetEmailOtpSuccessful(false);
            setEmailOtpError(error);
          } else {
            setIsGetEmailOtpSuccessful(true);
            handleSendEmailOtpSuccess();
          }
        })
        .catch((error) => {
          // console.error(error);
          if (
            error?.graphQLErrors[0]?.extensions?.exception?.response.messageCode ===
            'TOO_MANY_OTP_REQUESTS'
          ) {
            setIsGetEmailOtpSuccessful(false);
            setEmailOtpError(ERROR_MESSAGES.TOO_MANY_OTP_REQUESTS);
          } else {
            setIsGetEmailOtpSuccessful(false);
            setEmailOtpError(ERROR_MESSAGES.OTP_SEND_ERROR);
          }
        })
        .finally(() => {
          setIsSendEmailOtpLoading(false);
          if (!isTriggeredFromModal) {
            setIsGetEmailOtpSuccessful(false);
          }
        });
    };

    const newState = {};
    // Email OTP
    newState.isSendEmailOtpLoading = isSendEmailOtpLoading;
    newState.isVerifyEmailOtpLoading = isVerifyEmailOtpLoading;
    newState.isGetEmailOtpSuccessful = isGetEmailOtpSuccessful;
    newState.emailOtpError = emailOtpError;
    newState.setEmailOtpError = setEmailOtpError;
    newState.setIsGetEmailOtpSuccessful = setIsGetEmailOtpSuccessful;
    newState.setIsVerifyEmailOtpLoading = setIsVerifyEmailOtpLoading;
    newState.handleSendOtpRegisteredUser = handleSendOtpRegisteredUser;
    newState.handleVerifyOtpRegisteredUser = handleVerifyOtpRegisteredUser;

    return newState;
  }, [
    isSendEmailOtpLoading,
    isVerifyEmailOtpLoading,
    isGetEmailOtpSuccessful,
    emailOtpError,
    setEmailOtpError,
    sendOtpRegisteredUser,
    setIsGetEmailOtpSuccessful,
    setIsVerifyEmailOtpLoading,
    verifyOtpRegisteredUser,
  ]);

  return <OTPContext.Provider value={state}>{children}</OTPContext.Provider>;
};

export const OTPConsumer = OTPContext.Consumer;
export default OTPContext;
