// @flow
import React, { useEffect, useRef } from 'react';

import { Box, HStack, Spacer, VStack, useDisclosure } from '@chakra-ui/react';

import {
  sideNavMenuContentStyles,
  sideNavMenuHeaderStyles,
  sideNavMenuItemStyles,
  sideNavMenuWrapperStyles,
} from '@shared/components/BaselaneNavMenu/style';
import BaselaneNavItem from '@shared/components/BaselaneNavMenu/components/BaselaneNavItem';
import { useAuth } from '@contexts/AuthContext';
import { useNavMenu } from '@contexts/NavMenuContext';
import { navigationItems } from '@shared/components/BaselaneNavMenu/nav-menu.config';
import type { NavItem } from '@shared/components/BaselaneNavMenu/nav-item.type';
import { IconClose } from '@icons';
import BaselaneIconLogo from '../../../../../../assets/BaselaneIconLogo';
import BaselaneFullLogo from '../../../../../../assets/BaselaneFullLogo';
import { useProductTour } from '../../../../product-tour/contexts/ProductTourContext';

const BaselaneNavMenu = ({ isMobile = false, isManualOpen = false, setIsOpen = () => {} }: any) => {
  const { isOpen: isMenuOpenedByTour, onClose: onTourMenuClose } = useNavMenu();
  const { tourInternals } = useProductTour();
  const { tenant } = useAuth();
  const navElement = useRef(null);
  const { isOpen = false, onOpen, onClose } = useDisclosure();
  let timer;

  const openMenu = () => {
    timer = setTimeout(() => onOpen(), 100);
  };

  const closeMenu = () => {
    clearTimeout(timer);
    onClose();
    setIsOpen(false);
  };

  useEffect(() => {
    const { current } = navElement;
    if (current !== null) {
      current.addEventListener('mouseenter', openMenu);
      current.addEventListener('mouseleave', closeMenu);
      return () => {
        current.removeEventListener('mouseenter', openMenu);
        current.removeEventListener('mouseleave', closeMenu);
      };
    }
    return () => {};
  }, []);

  useEffect(() => {
    // if product-tour closes, close menu
    if (!tourInternals.start) {
      setIsOpen(false);
      onTourMenuClose();
      onClose();
    }
  }, [tourInternals.start]);

  useEffect(() => {
    // if product-tour opens, open menu
    if (isMenuOpenedByTour && isMobile) {
      setIsOpen(true);
      onOpen();
    }
  }, [isMenuOpenedByTour]);

  const isMenuOpen = isMobile ? isManualOpen : isOpen;

  return (
    <VStack {...sideNavMenuWrapperStyles(isMobile, isMenuOpen)} ref={navElement}>
      <HStack>
        <Box {...sideNavMenuHeaderStyles}>
          {isMenuOpen ? <BaselaneFullLogo color="#012550" /> : <BaselaneIconLogo color="#012550" />}
        </Box>
        <Spacer />
        {isMobile && (
          <Box
            position="absolute"
            right="12px"
            top="10px"
            padding="12px"
            onClick={() => setIsOpen(false)}
            cursor="pointer"
          >
            <IconClose width="14" height="14" />
          </Box>
        )}
      </HStack>
      {tenant && (
        <VStack {...sideNavMenuContentStyles}>
          {navigationItems?.map((navItem: NavItem) => (
            <BaselaneNavItem
              key={navItem.link + navItem.title}
              navItem={navItem}
              isShowTitle
              styles={{ ...sideNavMenuItemStyles }}
              onClick={() => setIsOpen(false)}
            />
          ))}
        </VStack>
      )}
    </VStack>
  );
};

export default BaselaneNavMenu;
