/* eslint-disable prettier/prettier,import/first */
import { init as initApm } from '@elastic/apm-rum';
import { setContext } from '@apollo/client/link/context';

import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { ErrorModalContextActions } from '../contexts/ErrorModalContext';
import { isHandledErrorCode } from '../utils/errorParser';

import { loggerService } from '../services';
import { authService } from '../auth';
import { ROUTES } from '../routing/constants/route.constants';

// eslint-disable-next-line no-unused-vars
let apm;

if (process.env.REACT_APP_DATADOG_ENABLED !== 'true') {
  apm = initApm({
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: 'tenant-portal-client',

    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: process.env.REACT_APP_APM_SERVER_URL,

    // Set service version (required for sourcemap feature)
    serviceVersion: process.env.REACT_APP_APM_SERVICE_VERSION || 'default',

    environment: process.env.REACT_APP_NODE_ENV,

    // Set tracing through URLs
    distributedTracingOrigins: [
      `GET ${process.env.REACT_APP_ORCHESTRATION_URL}`,
      `POST ${process.env.REACT_APP_ORCHESTRATION_URL}`,
      process.env.REACT_APP_ORCHESTRATION_URL,
      process.env.REACT_APP_ORCHESTRATION_URL.replace('/graphql', ''),
      'https://develop-orchestration.baselane.com',
      'http://localhost:4500/graphql',
      'http://localhost:4500',
    ],
  });
}

const URI = process.env.REACT_APP_ORCHESTRATION_URL;

const httpLink = createHttpLink({
  uri: URI,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
    credentials: 'include',
  };
});

const handleEmailNotFoundError = () => {
  // Tenant tries to sign up with different email or if email is not found
  ErrorModalContextActions.onOpenCriticalError({
    title: 'Your email is incorrect',
    description:
      'If you are signing up with Google, please use the Google email where your Baselane invitation was sent. If you are signing up with email, please ensure you sign up using the link in your invitation.',
  });
};

const handleInviteCodeNotFoundError = () => {
  // Tenant uses invalid invite code and it is not found
  ErrorModalContextActions.onOpenCriticalError({
    title: 'Invalid Invite Code',
    description: 'Please contact your Landlord to resend invitation.',
  });
};

const apolloClient = new ApolloClient({
  // link: errorLink.concat(authLink.concat(httpLink)),
  link: from([
    onError(({ graphQLErrors, networkError }) => {
      const noSessionCookieSignature = {
        result: {
          messageCode: 'UNAUTHORIZED_ACCESS',
        },
        statusCode: 401,
      };

      const noSession =
        networkError?.result?.messageCode === noSessionCookieSignature.result.messageCode &&
        networkError?.statusCode === noSessionCookieSignature.statusCode;

      if (noSession) {
        // redirect to login
        window.location.href = `${ROUTES.LOGIN}`;
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(async (error) => {
          apm?.captureError(error);

          const { extensions } = error;
          if (error.extensions.code === 'UNAUTHENTICATED') {
            await authService.logout(apolloClient);
          } else if (
            extensions?.response?.body?.message?.includes(
              'cannot be changed as it has already been processed'
            )
          ) {
            loggerService.error(error.extensions.code);
          } else if (
            error?.extensions?.exception?.response.messageCode === 'INVITE_CODE_EMAIL_MISMATCH' ||
            error?.extensions?.exception?.response.messageCode ===
              'TENANT_PROFILE_EMAIL_NOT_FOUND' ||
            error?.extensions?.exception?.response.messageCode === 'EMAIL_BELONGS_TO_LANDLORD'
          ) {
            handleEmailNotFoundError();
          } else if (
            error?.extensions?.exception?.response.messageCode === 'INVITE_CODE_NOT_FOUND'
          ) {
            handleInviteCodeNotFoundError();
          } else if (
            error?.extensions?.exception?.response.messageCode === 'INSUFFICIENT_BALANCE' ||
            error?.extensions?.exception?.response.messageCode === 'TENANT_PAYMENT_METHOD_NOT_FOUND'
          ) {
            // handled in pay now module
          } else if (
            error?.extensions?.exception?.response.messageCode === 'OTP_SEND_ERROR' ||
            error?.extensions?.exception?.response.messageCode === 'OTP_VERIFY_ERROR' ||
            error?.extensions?.exception?.response.messageCode === 'TOO_MANY_OTP_REQUESTS' ||
            error?.extensions?.exception?.response.messageCode === 'NON_US_NUMBER' ||
            error?.extensions?.exception?.response.messageCode === 'VOIP_NUMBER' ||
            error?.extensions?.exception?.response.messageCode === 'DUPLICATE_PHONE_NUMBER'
          ) {
            // handled by OTPContext
          } else if (
            !isHandledErrorCode(
              error.extensions?.response?.body?.baselaneErrorCode ??
                error?.extensions?.exception?.response.messageCode
            )
          ) {
            loggerService.error(error.extensions.code);
            ErrorModalContextActions.onOpenGenericError();
          }
        });
      }
    }),
    authLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
  credentials: 'include',
});

export default apolloClient;
