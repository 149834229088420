// @flow
import React from 'react';
import type { Node } from 'react';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';

import IconStatusCompleted from '@icons/IconStatusCompleted';
import IconStatusFailed from '@icons/IconStatusFailed';

import BaselaneInfoBlock from '../BaselaneInfoBlock';

// Types
type DialogMessageType = 'error' | 'completed';

// Helpers
/**
 * Return dialog icon by type
 * @return {JSX.Element}
 */
function getIcon(type: DialogMessageType) {
  switch (type) {
    case 'error':
      return <IconStatusFailed width="32px" height="32px" />;
    case 'completed':
      return <IconStatusCompleted width="32px" height="32px" />;
    default:
      return null;
  }
}

// Props
type BaselaneDialogMessageProps = {
  onClose: Function,
  isOpen: boolean,
  text: string,
  children?: Node,
  additionalText?: string,
  type?: DialogMessageType,
  customStyles?: Object,
};

// Component
function BaselaneDialogMessage({
  isOpen,
  onClose,
  children,
  type,
  text,
  additionalText,
  customStyles,
}: BaselaneDialogMessageProps): any {
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent p="40px 16px" margin="120px 16px auto 16px" {...customStyles}>
        <ModalBody p="0">
          <BaselaneInfoBlock icon={getIcon(type)} additionalText={additionalText} textAlign="left">
            {text}
          </BaselaneInfoBlock>
        </ModalBody>
        <ModalFooter p="0" marginTop="40px">
          <VStack spacing="16px" w="100%">
            {children}
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

BaselaneDialogMessage.defaultProps = {
  children: null,
  type: null,
  additionalText: '',
  customStyles: {},
};

export default BaselaneDialogMessage;
