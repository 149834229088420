import React from 'react';
import { Text } from '@chakra-ui/react';

function AchFeeText({ text }: { text: String }) {
  return (
    <Text
      className="hover-white"
      {...{
        fontSize: 'xs',
        lineHeight: '20px',
        fontWeight: 'normal',
        color: 'brand.neutral.600',
      }}
    >
      {text}
    </Text>
  );
}

export default AchFeeText;
