// @flow
import React from 'react';

import type { IconProps } from '@icons/types';

const IconKey = ({ width = 28, color = 'currentColor' }: IconProps) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox={`0 0 ${width} ${width}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 7.33343C18.7072 7.33343 19.3855 7.61439 19.8856 8.11448C20.3857 8.61458 20.6667 9.29286 20.6667 10.0001L18 7.33343ZM26 10.0001C26.0004 11.2497 25.708 12.4819 25.1464 13.5982C24.5847 14.7144 23.7694 15.6835 22.7658 16.4279C21.7622 17.1723 20.5981 17.6713 19.3669 17.8848C18.1358 18.0983 16.8717 18.0204 15.676 17.6574L12.6667 20.6668H10V23.3334H7.33333V26.0001H3.33333C2.97971 26.0001 2.64057 25.8596 2.39052 25.6096C2.14048 25.3595 2 25.0204 2 24.6668V21.2188C2.00008 20.8652 2.1406 20.5261 2.39067 20.2761L10.3427 12.3241C10.0099 11.2241 9.91809 10.0654 10.0734 8.9267C10.2287 7.78803 10.6275 6.69619 11.2427 5.7255C11.8578 4.7548 12.6749 3.92803 13.6383 3.30146C14.6016 2.67489 15.6887 2.26323 16.8254 2.09451C17.9622 1.92578 19.122 2.00395 20.2258 2.32368C21.3296 2.64342 22.3516 3.19722 23.2222 3.94739C24.0928 4.69756 24.7915 5.62649 25.2709 6.67095C25.7502 7.71542 25.9989 8.8509 26 10.0001V10.0001Z"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconKey;
