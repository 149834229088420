// @flow
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, HStack, Spacer } from '@chakra-ui/react';

import { ONBOARDING } from '@routing/constants/route.constants';
import { topNavMenuWrapperStyles } from '@shared/components/BaselaneNavMenu/style';
import { topNavBarStyles } from '@layouts/Desktop/style';
import { appContainerStyles, mainStyles, mobileSideNavBarStyles } from '@layouts/Mobile/style';
import BaselaneNavMenu from '@shared/components/BaselaneNavMenu';
import { IconBurgerMenu } from '@icons';
import Profiles from '../Profiles';

const MobileAuthenticatedLayout = ({ children }: { children: React.Node }) => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box {...appContainerStyles}>
      {
        /* requires tenant */
        pathname !== ONBOARDING && (
          <Box {...topNavBarStyles}>
            <HStack {...topNavMenuWrapperStyles} h="64px">
              <Box
                position="relative"
                top="12px"
                left="12px"
                paddingLeft="12px"
                paddingTop="12px"
                cursor="pointer"
                onClick={() => setIsOpen(!isOpen)}
              >
                <IconBurgerMenu />
              </Box>
              <Spacer />
              <Box p="12px 32px 0 0">
                <Profiles />
              </Box>
            </HStack>
          </Box>
        )
      }
      {pathname !== ONBOARDING && (
        <Box {...mobileSideNavBarStyles}>
          <BaselaneNavMenu isMobile isManualOpen={isOpen} setIsOpen={setIsOpen} />
        </Box>
      )}
      <Box {...mainStyles(true)}>{children}</Box>
    </Box>
  );
};

export default MobileAuthenticatedLayout;
