export const criticalErrorModalContent = {
  maxWidth: '672px',
  borderRadius: '14px',
  margin: '6px',
};

export const criticalErrorModalHeader = (isDesktop: boolean) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  padding: isDesktop ? '56px 32px 0 32px' : '32px 16px 0 16px',
});

export const criticalErrorCloseButton = (isDesktop: boolean) => ({
  margin: isDesktop ? '33px' : '0',
  color: 'brand.neutral.600',
});

export const criticalErrorModalTitle = (isDesktop: boolean) => ({
  textStyle: 'headline-xl',
  marginLeft: '8px',
});

export const criticalErrorModalBody = (isDesktop: boolean) => ({
  padding: isDesktop ? '32px 32px 0 32px' : '16px 16px 0 16px',
  textStyle: 'sm',
});

export const criticalErrorModalFooter = (isDesktop: boolean) => ({
  padding: isDesktop ? '32px' : '16px',
});
