// @flow
import React from 'react';
import loadable from '@loadable/component';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AuthProvider } from '@core/contexts/AuthContext';
import ExpiredInvitation from '@pages/ExpiredInvitation';
import {
  LOGIN,
  SIGN_UP_WIZARD,
  CHANGE_PASSWORD,
  ALREADY_SIGNED_UP,
  ROOT,
  DASHBOARD,
  ONBOARDING,
  PAYMENT_METHODS,
  SETTINGS,
  EXPIRED_INVITATION,
} from '../constants/route.constants';
import Authenticated from '../components/Authenticated';
import Anonymous from '../components/Anonymous';

// Lazy loaded components
const Dashboard = loadable(() => import('@pages/Dashboard'));
const Login = loadable(() => import('@pages/Login'));
const ChangePassword = loadable(() => import('@pages/ChangePassword'));
const Settings = loadable(() => import('@pages/Settings'));
const PaymentMethods = loadable(() => import('@pages/PaymentMethods'));
const SignUpWizard = loadable(() => import('@pages/SignUpWizard'));
const Onboarding = loadable(() => import('@pages/Onboarding'));
const AlreadySignedUp = loadable(() => import('@pages/AlreadySignedUp'));

const MainRoutes = () => {
  return (
    <Switch>
      {/* Public Routes */}
      <Route path={LOGIN} exact>
        <Anonymous>
          <Login />
        </Anonymous>
      </Route>
      <Route path={SIGN_UP_WIZARD} exact>
        <Anonymous>
          <SignUpWizard />
        </Anonymous>
      </Route>
      <Route path={CHANGE_PASSWORD} exact>
        <Anonymous>
          <ChangePassword />
        </Anonymous>
      </Route>
      <Route path={ALREADY_SIGNED_UP} exact>
        <Anonymous>
          <AlreadySignedUp />
        </Anonymous>
      </Route>
      <Route path={EXPIRED_INVITATION} exact>
        <Anonymous>
          <ExpiredInvitation />
        </Anonymous>
      </Route>
      {/* Authenticated Routes */}
      <AuthProvider>
        <Authenticated>
          <Route path={ROOT} exact>
            <Redirect to={DASHBOARD} />
          </Route>
          <Route path={ONBOARDING} exact>
            <Onboarding />
          </Route>
          <Route path={PAYMENT_METHODS} exact>
            <PaymentMethods />
          </Route>
          <Route path={SETTINGS}>
            <Settings />
          </Route>
          <Route path={DASHBOARD}>
            <Dashboard />
          </Route>
        </Authenticated>
      </AuthProvider>
    </Switch>
  );
};

export default MainRoutes;
