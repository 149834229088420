import { useLease } from '@contexts/LeaseContext';

export const calculateWhoIsPaying = (invoice) => {
  const { selectedLease } = useLease();
  if (invoice) {
    if (invoice?.invoiceCharge && invoice?.invoiceCharge?.status !== 'FAILED') {
      if (
        invoice?.invoiceCharge?.metadata?.applicationFeeWaivedChargedReason === 'EXTERNAL_ACCOUNT'
      ) {
        return invoice?.invoiceCharge?.invoiceProcessingFeePaidBy;
      }
      if (
        invoice?.invoiceCharge?.metadata?.applicationFeeWaivedChargedReason === 'BASELANE_ACCOUNT'
      ) {
        return 'LANDLORD';
      }
    }
    if (invoice?.destinationBankAccount && !invoice?.destinationBankAccount?.isExternal) {
      return 'LANDLORD';
    }
    if (!selectedLease?.rentAndFeesBankAccount?.isExternal) {
      return 'LANDLORD';
    }
    return selectedLease?.invoiceProcessingFeePaidBy;
  }
  if (!selectedLease?.rentAndFeesBankAccount?.isExternal) {
    return 'LANDLORD';
  }

  return selectedLease?.invoiceProcessingFeePaidBy;
};

// Do not display any ach fee in the UI
const isLegacyInvoice = (invoice) => {
  return invoice?.invoiceCharge?.metadata?.applicationFeeWaivedChargedReason === 'LEGACY_CHARGE';
};
const isPaidByCreditOrDebitCard = (invoice) => {
  return (
    invoice?.invoiceCharge?.metadata?.applicationFeeWaivedChargedReason === 'CREDIT_CARD' ||
    invoice?.invoiceCharge?.metadata?.applicationFeeWaivedChargedReason === 'DEBIT_CARD'
  );
};
const isInvoiceChargeOnPromotion = (invoice) => {
  return invoice?.invoiceCharge?.metadata?.applicationFeeWaivedChargedReason === 'ACH_PROMOTION';
};
export const hideAchFee = (invoice) => {
  return (
    isLegacyInvoice(invoice) ||
    isPaidByCreditOrDebitCard(invoice) ||
    isInvoiceChargeOnPromotion(invoice)
  );
};
// End of logic that displays no ach fee
