// @flow
import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { includes } from 'lodash';
import ServiceBell from '@servicebell/widget';
import LogRocket from 'logrocket';
import { useAuth } from '@contexts/AuthContext';
import DesktopAuthenticatedLayout from '@layouts/Desktop/DesktopAuthenticatedLayout';
import MobileAuthenticatedLayout from '@layouts/Mobile/MobileAuthenticatedLayout';
import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';
import StatsigWrapper from '@core/StatsigWrapper';
import ZendeskScript from '@core/ZendeskScript';
import BaselaneErrorModal from '@core/apollo/components/BaselaneErrorModal';
import { LeaseProvider } from '@core/contexts/LeaseContext';
import { LOGIN, ONBOARDING } from '../constants/route.constants';
import LeaseSummaryDrawer from '../../../product-tour/components/LeaseSummaryDrawer';
/**
 * Initialization and layout for pages that require authentication.
 */
type AuthenticationGuardProps = {
  children: React.Node,
};

const Authenticated = ({ children }: AuthenticationGuardProps) => {
  const location = useLocation();
  const isDesktopScreen = useIsLargerThanSmallScreen();

  const { pathname } = location;
  const { tenant } = useAuth();
  const { phoneNumberVerified } = tenant || {};

  const onboardingRoutes = Object.freeze([ONBOARDING]);

  // Logrocket Setup
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      LogRocket.init('oj1o2q/baselane');
    }
  }, []);

  useEffect(() => {
    if (tenant) {
      const { email, firstName, lastName, id: userId } = tenant || {};
      const displayName = `${firstName || ''} ${lastName || ''}`;

      ServiceBell('init', process.env.REACT_APP_SERVICEBELL_API_KEY);
      window.addEventListener('sb:initialized', () => {
        ServiceBell('identify', userId, {
          displayName,
          email,
          user_type: 'tenant',
        });
      });

      LogRocket.identify(userId, {
        name: displayName,
        email,
        role: 'TENANT',
      });
    }
  }, [tenant]);

  // auth guard
  if (!tenant) {
    return <Redirect to={LOGIN} />;
  }

  if (!includes(onboardingRoutes, pathname) && tenant && !phoneNumberVerified) {
    return <Redirect to={ONBOARDING} />;
  }

  return (
    <StatsigWrapper tenant={tenant}>
      <ZendeskScript>
        {isDesktopScreen ? (
          <DesktopAuthenticatedLayout>
            <BaselaneErrorModal />
            <LeaseSummaryDrawer />
            <LeaseProvider>{children}</LeaseProvider>
          </DesktopAuthenticatedLayout>
        ) : (
          <MobileAuthenticatedLayout>
            <LeaseProvider>{children}</LeaseProvider>
          </MobileAuthenticatedLayout>
        )}
      </ZendeskScript>
    </StatsigWrapper>
  );
};

export default Authenticated;
