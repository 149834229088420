import React from 'react';

import type { IconProps } from '@icons/types';

function IconProductTour({ color, width, height }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C8.41421 2 8.75 2.33579 8.75 2.75V3.25C8.75 3.66421 8.41421 4 8 4C7.58579 4 7.25 3.66421 7.25 3.25V2.75C7.25 2.33579 7.58579 2 8 2ZM16 10C16 10.4142 15.6642 10.75 15.25 10.75H14.75C14.3358 10.75 14 10.4142 14 10C14 9.58579 14.3358 9.25 14.75 9.25H15.25C15.6642 9.25 16 9.58579 16 10ZM13.6568 5.40387C13.9497 5.11097 13.9497 4.6361 13.6568 4.3432C13.3639 4.05031 12.889 4.05031 12.5961 4.3432L12.2426 4.69676C11.9497 4.98965 11.9497 5.46453 12.2426 5.75742C12.5355 6.05031 13.0103 6.05031 13.3032 5.75742L13.6568 5.40387ZM2.3432 4.3432C2.6361 4.05031 3.11097 4.05031 3.40387 4.3432L3.75742 4.69676C4.05031 4.98965 4.05031 5.46453 3.75742 5.75742C3.46453 6.05031 2.98965 6.05031 2.69676 5.75742L2.3432 5.40387C2.05031 5.11097 2.05031 4.6361 2.3432 4.3432ZM1.25 10.75C1.66421 10.75 2 10.4142 2 10C2 9.58579 1.66421 9.25 1.25 9.25H0.75C0.335786 9.25 0 9.58579 0 10C0 10.4142 0.335786 10.75 0.75 10.75H1.25ZM3.75738 14.2426C4.05027 14.5355 4.05027 15.0104 3.75738 15.3033L3.40383 15.6568C3.11093 15.9497 2.63606 15.9497 2.34317 15.6568C2.05027 15.3639 2.05027 14.8891 2.34317 14.5962L2.69672 14.2426C2.98961 13.9497 3.46449 13.9497 3.75738 14.2426ZM6.5 14.75V16.25C6.5 16.3881 6.61193 16.5 6.75 16.5H9.25C9.38807 16.5 9.5 16.3881 9.5 16.25V14.75H6.5ZM9.87235 13.25C9.87305 13.2496 9.87375 13.2492 9.87446 13.2488C10.9973 12.5993 11.75 11.3871 11.75 10C11.75 7.92893 10.0711 6.25 8 6.25C5.92893 6.25 4.25 7.92893 4.25 10C4.25 11.3871 5.00271 12.5993 6.12554 13.2488C6.12625 13.2492 6.12695 13.2496 6.12765 13.25H9.87235ZM12.2426 14.2426C12.5355 13.9497 13.0104 13.9497 13.3033 14.2426L13.6568 14.5962C13.9497 14.8891 13.9497 15.3639 13.6568 15.6568C13.3639 15.9497 12.8891 15.9497 12.5962 15.6568L12.2426 15.3033C11.9497 15.0104 11.9497 14.5355 12.2426 14.2426ZM8 4.75C5.1005 4.75 2.75 7.1005 2.75 10C2.75 11.785 3.64113 13.3611 5 14.3089V16.25C5 17.2165 5.7835 18 6.75 18H9.25C10.2165 18 11 17.2165 11 16.25V14.3089C12.3589 13.3611 13.25 11.785 13.25 10C13.25 7.1005 10.8995 4.75 8 4.75Z"
        fill={color}
      />
    </svg>
  );
}

IconProductTour.defaultProps = {
  color: '#192C3E',
  width: '16px',
  height: '20px',
};

export default IconProductTour;
