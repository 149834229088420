// @flow
import React from 'react';

import IconRocket from '@icons/IconRocket';
import IconCoin from '@icons/IconCoin';
import IconKey from '@icons/IconKey';
import IconPaymentMethods from '@icons/IconPaymentMethods';
import IconRocketWithBackground from '@icons/IconRocketWithBackground';

import TourTooltipHeader from './components/TourTooltip/TourTooltipHeader';
import useLeaseDetailsStepHandler from './step-handlers/useLeaseDetailsStepHandler';
import usePaymentMethodsHandler from './step-handlers/usePaymentMethodsHandler';
import type { TourStep } from './types/tour-step.type';

// Tour's step selector
export const tourStepAttrSelector = 'data-tour-step';

// Step values
export const stepInvoices = 'invoices';
export const stepCompletedInvoices = 'completed-invoices';
export const stepLeaseDetails = 'lease-details';
export const stepPaymentMethods = 'payment-methods';

// Steps config
export const tourSteps: TourStep[] = Object.freeze([
  {
    target: `body`,
    title: (
      <TourTooltipHeader
        title="Welcome to Baselane"
        icon={<IconRocketWithBackground width={64} backgroundColor="#025DE4" />}
      />
    ),
    content: 'Let us show you around and give you quick tour.',
    disableBeacon: true,
    placement: 'center',
  },
  {
    target: `[${tourStepAttrSelector}='${stepInvoices}']`,
    title: <TourTooltipHeader title="Stay on top of your rent payments" icon={<IconCoin />} />,
    content:
      'View all your rent, security deposit, and one-time-fee invoices. We will also send notifications and reminders so you never miss a payment.',
    disableBeacon: true,
  },
  {
    target: `[${tourStepAttrSelector}='${stepCompletedInvoices}']`,
    title: <TourTooltipHeader title="See a record of all your payments" icon={<IconCoin />} />,
    content: 'View a history of all your completed payments, and track paid deposits.',
    disableBeacon: true,
  },
  {
    target: `[${tourStepAttrSelector}='${stepLeaseDetails}']`,
    title: <TourTooltipHeader title="View details of your rent collection" icon={<IconKey />} />,
    content: 'Access your landlord’s contact information, rent collection terms and more.',
    disableBeacon: true,
    getStepHandler: useLeaseDetailsStepHandler,
  },
  {
    target: `[${tourStepAttrSelector}='${stepPaymentMethods}']`,
    title: (
      <TourTooltipHeader
        title="Add and manage payment methods"
        icon={<IconPaymentMethods width={28} height={22} />}
      />
    ),
    content:
      'Pay rent your way — by bank transfer, debit or credit card.  We keep your payments secure with leading payment technologies and 256-bit bank-level SSL encryption.',
    disableBeacon: true,
    spotlightPadding: 5,
  },
  {
    target: `body`,
    title: <TourTooltipHeader title="All set!" icon={<IconRocket />} />,
    content: 'You can return to this tour at any time from the navigation menu.',
    disableBeacon: true,
    placement: 'center',
    getStepHandler: usePaymentMethodsHandler,
  },
]);
