export const alertHeader = (isMobile) => ({
  textStyle: 'headline-xl',
  px: isMobile ? '24px' : '48px',
  pt: isMobile ? '24px' : '48px',
  pb: '8px',
  color: 'brand.neutral.900',
});
export const alertBody = (isMobile) => ({
  p: isMobile ? '24px' : '16px 32px',
  fontSize: 'sm',
  lineHeight: '20px',
  fontWeight: 'normal',
  color: 'brand.neutral.600',
});

export const alertFooter = (isMobile) => ({
  px: isMobile ? '24px' : '24px',
  pb: '24px',
});

export const alertContainer = (isMobile) => ({
  m: isMobile && '120px 16px auto 16px',
});
