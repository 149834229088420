import isNil from '@core/utils/validators/isNil';

function formatCurrency(amount) {
  if (isNil(amount)) {
    throw new Error('amount argument is not a number!');
  }

  const isNegative = amount < 0;

  // noinspection JSCheckFunctionSignatures
  const formattedAmount = Math.abs(amount).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formattedAmountRounded = Math.abs(amount).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return {
    isNegative,
    rounded: isNegative ? `$(${formattedAmount})` : `$${formattedAmount}`,
    inDollars: isNegative ? `$(${formattedAmountRounded})` : `$${formattedAmountRounded}`,
  };
}

export default formatCurrency;
