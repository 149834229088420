// @flow
import React, { createContext, useContext, Node, useMemo } from 'react';

import { useDisclosure, UseDisclosureProps } from '@chakra-ui/react';

// Context
const NavMenuContext = createContext<UseDisclosureProps>();

// Provider
export const NavMenuProvider = ({ children }: { children: Node }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: 'nav-menu',
  });

  const state = useMemo(() => {
    const newState = {};
    newState.isOpen = isOpen;
    newState.onOpen = onOpen;
    newState.onClose = onClose;
    return newState;
  }, [isOpen, onOpen, onClose]);

  return <NavMenuContext.Provider value={state}>{children}</NavMenuContext.Provider>;
};

// Hook to simplify access to NavMenu actions/data from the contexts
export const useNavMenu = () => useContext(NavMenuContext);
