import React from 'react';

import type { IconProps } from '@icons/types';

function IconHelpCenter({ color, width, height }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 5.5C6.66421 5.5 7 5.16421 7 4.75C7 4.33579 6.66421 4 6.25 4H4C2.89543 4 2 4.89543 2 6V14C2 15.1046 2.89543 16 4 16H12C13.1046 16 14 15.1046 14 14V12.75C14 12.3358 13.6642 12 13.25 12C12.8358 12 12.5 12.3358 12.5 12.75V14C12.5 14.2761 12.2761 14.5 12 14.5H4C3.72386 14.5 3.5 14.2761 3.5 14V6C3.5 5.72386 3.72386 5.5 4 5.5H6.25Z"
        fill={color}
      />
      <path
        d="M8.5 4.75C8.5 4.33579 8.83579 4 9.25 4H13.25C13.6642 4 14 4.33579 14 4.75V8.75C14 9.16421 13.6642 9.5 13.25 9.5C12.8358 9.5 12.5 9.16421 12.5 8.75V6.56066L6.53033 12.5303C6.23744 12.8232 5.76256 12.8232 5.46967 12.5303C5.17678 12.2374 5.17678 11.7626 5.46967 11.4697L11.4393 5.5H9.25C8.83579 5.5 8.5 5.16421 8.5 4.75Z"
        fill={color}
      />
    </svg>
  );
}

IconHelpCenter.defaultProps = {
  color: '#192C3E',
  width: '16px',
  height: '20px',
};

export default IconHelpCenter;
