import React from 'react';
import { MenuButton, Text, HStack, Avatar } from '@chakra-ui/react';
import { IconChevronDown, IconChevronUp } from '@icons';
import BaselaneButton from '../BaselaneButton';
import { menuStyles } from './menu.styles';
import DefaultProfile from '../../../../../../assets/avatar.png';

const BaselaneMenuProfileButton = ({
  isOpen,
  profilePhotoUrl,
  label,
}: {
  isOpen: Boolean,
  profilePhotoUrl: String,
  label: String,
}) => {
  const { profileButton, username } = menuStyles ?? {};
  return (
    <MenuButton
      className={`profiles-button ${isOpen ? 'open' : 'closed'}`}
      isActive={isOpen}
      as={BaselaneButton}
      size="lg"
      variant="outline"
      palette="neutral"
      rightIcon={isOpen ? <IconChevronUp /> : <IconChevronDown />}
      styles={profileButton}
      onMouseDown={() => document.querySelector('.profiles-button')?.classList?.add('pressed')} // Override active state to apply pressed styles while the button is active
    >
      <HStack>
        <Avatar size="sm" src={profilePhotoUrl || DefaultProfile} bg="brand.neutral.200" />
        <Text {...username}>{label}</Text>
      </HStack>
    </MenuButton>
  );
};

export default BaselaneMenuProfileButton;
