// @flow
import React, { useImperativeHandle, Component, cloneElement } from 'react';
import type { Node } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerFooter,
  DrawerProps,
  useDisclosure,
  forwardRef,
} from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';

import BaselaneButton from '../BaselaneButton';
import BaselaneDialogMessage from '../BaselaneDialogMessage';
import BaselaneDrawerHeader from './BaselaneDrawerHeader';
import type { DrawerHeaderVariant } from './types';
import {
  drawerContentStyles,
  newDrawerHeaderStyles,
  newDrawerBodyStyles,
  newDrawerFooterStyles,
} from './style';

// Props
type BaselaneDrawerProps = DrawerProps & {
  title: string,
  content: Node,
  modalTextBeforeClose?: string,
  onModalConfirm?: Function,
  headerVariant?: DrawerHeaderVariant,
  toggle?: boolean,
  onToggleChange?: Function,
  closeEvent?: Function,
  desktopSize?: $PropertyType<DrawerProps, 'size'>,
  isShowHeader?: boolean,
  isShowFooter?: boolean,
  disabled?: boolean,
  customFooter?: any,
};

// Custom Drawer Component
function BaselaneDrawer(
  {
    title,
    content,
    children,
    closeEvent = () => {},
    modalTextBeforeClose = '',
    onModalConfirm = () => {},
    desktopSize = 'lg',
    headerVariant = 'primary',
    isShowHeader = true,
    isShowFooter = false,
    disabled = false,
    customFooter = null,
  }: BaselaneDrawerProps,
  ref
): Component {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
  const { isMax576: isMobile, responsiveStyleSize, isMin899: isDesktopScreen } = getBreakPoints();

  // New Design drawer
  const drawerHeaderStyle = newDrawerHeaderStyles[responsiveStyleSize];

  /**
   * Close Drawer
   */
  const close = () => {
    if (modalTextBeforeClose) {
      onOpenModal();
    } else {
      onClose();
      closeEvent();
    }
  };

  useImperativeHandle(ref, () => ({
    close: () => onClose(),
    closeWithModal: () => close(),
    open: () => onOpen(),
  }));

  return (
    <>
      {children &&
        (children.props.onClick
          ? children
          : cloneElement(children, {
              onClick: disabled ? () => {} : onOpen,
            }))}
      <Drawer
        placement={isMobile ? 'bottom' : 'right'}
        onClose={close}
        isOpen={isOpen}
        autoFocus={false}
        trapFocus={false}
        size={isMobile ? 'full' : desktopSize}
      >
        <DrawerOverlay />
        <DrawerContent {...drawerContentStyles(!isMobile)}>
          {isShowHeader && (
            <DrawerHeader {...drawerHeaderStyle} width="100%">
              <BaselaneDrawerHeader
                title={title}
                variant={headerVariant}
                onClose={close}
                {...drawerHeaderStyle}
                width="100%"
              />
            </DrawerHeader>
          )}
          <DrawerBody {...newDrawerBodyStyles[responsiveStyleSize]}>{content}</DrawerBody>
          {isShowFooter && (
            <DrawerFooter
              {...newDrawerFooterStyles(isDesktopScreen)[responsiveStyleSize]}
              background="white"
            >
              {customFooter || (
                <BaselaneButton size="lg" onClick={onClose} variant="newSecondary" width="100%">
                  Cancel
                </BaselaneButton>
              )}
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>

      <BaselaneDialogMessage
        isOpen={isOpenModal}
        type="error"
        text={modalTextBeforeClose}
        onClose={onCloseModal}
      >
        <BaselaneButton size="lg" variant="secondary" onClick={onCloseModal}>
          Return to Setup
        </BaselaneButton>
        <BaselaneButton
          size="lg"
          variant="primary"
          onClick={() => {
            onCloseModal();
            onModalConfirm();
          }}
        >
          Confirm and Cancel
        </BaselaneButton>
      </BaselaneDialogMessage>
    </>
  );
}

export default forwardRef(BaselaneDrawer);
