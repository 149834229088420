// Root constants
export const ROOT = '/';
export const DASHBOARD = '/dashboard';
export const LOGIN = '/login';
export const CHANGE_PASSWORD = '/change-password';
export const SETTINGS = '/settings';
export const PAYMENT_METHODS = `/payment-methods`;
export const SIGN_UP_WIZARD = `/sign-up-wizard`;
export const EXPIRED_INVITATION = `/expired-invitation`;
export const ONBOARDING = '/onboarding';
export const ALREADY_SIGNED_UP = `/already-signed-up`;

// Dashboard constants
export const INVOICES = `${DASHBOARD}/invoices`;
export const COMPLETED_INVOICES = `${DASHBOARD}/completed-invoices`;
export const LEASE_DETAILS = `${DASHBOARD}/lease-details`;

// Settings paths constants
export const PROFILE_PATH = `${SETTINGS}/profile`;
export const NOTIFICATION_PATH = `${SETTINGS}/notification`;
export const PASSWORD_PATH = `${SETTINGS}/password`;

// Bank and Cards constants
export const ADD_PAYMENT_METHOD = `${PAYMENT_METHODS}/add-payment-method`;

// Bundled in an object
export const ROUTES = {
  ADD_PAYMENT_METHOD,
  ALREADY_SIGNED_UP,
  CHANGE_PASSWORD,
  COMPLETED_INVOICES,
  DASHBOARD,
  EXPIRED_INVITATION,
  INVOICES,
  LEASE_DETAILS,
  LOGIN,
  NOTIFICATION_PATH,
  ONBOARDING,
  PASSWORD_PATH,
  PAYMENT_METHODS,
  PROFILE_PATH,
  ROOT,
  SETTINGS,
  SIGN_UP_WIZARD,
};
