import React from 'react';

function IconVerifiedBadge({
  w = '14',
  h = '14',
  color = '#fff',
}: {
  w?: Number,
  h?: Number,
  color?: String,
}) {
  return (
    <svg fill="none" width={w} height={h} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.55543.466361c.2533-.227283.63714-.227283.89045 0C8.88561 1.75818 10.779 2.42608 12.7106 2.3235c.3168-.01682.6015.19188.6809.499.1798.6964.2758 1.42573.2758 2.1774 0 4.03953-2.7624 7.4317-6.5005 8.3936-.10899.0281-.2233.0281-.33229 0C3.09637 12.4316.333987 9.03874.333987 4.9999h.666663l-.666663.00054V4.9999c-.000551-.73455.092141-1.4662.275854-2.1774.079333-.30712.364077-.51582.680829-.499 1.93161.10258 3.82502-.56532 5.26476-1.857139ZM1.78815 3.66643c-.08068.43935-.12119.8855-.12083 1.33293v.00054c0 3.35574 2.25481 6.1871 5.33334 7.0575C10.0792 11.187 12.334 8.35627 12.334 4.9999c0-.45587-.0416-.90134-.1209-1.33347-1.8913-.02407-3.7231-.6688-5.21244-1.83441C5.5113 2.99764 3.67948 3.64237 1.78815 3.66643Zm7.68391 1.52873c.26035.26035.26035.68246 0 .94281L6.80539 8.80464c-.26035.26035-.68246.26035-.94281 0L4.52925 7.4713c-.26035-.26035-.26035-.68246 0-.94281.26035-.26035.68246-.26035.94281 0l.86193.86193 2.19526-2.19526c.26035-.26035.68246-.26035.94281 0Z"
        fill={color}
      />
    </svg>
  );
}

IconVerifiedBadge.defaultProps = {
  w: '14',
  h: '14',
  color: '#fff',
};

export default IconVerifiedBadge;
