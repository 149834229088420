// @flow
import React, { createContext, useContext, Node, useRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { DASHBOARD } from '@routing/constants/route.constants';

// Context Type
type LeaseSummaryDrawerContextType = {
  onOpen: () => {},
  onClose: () => {},
};

// Context
const LeaseSummaryDrawerContext = createContext<LeaseSummaryDrawerContextType>();

// Provider
export const LeaseSummaryDrawerProvider = ({ children }: { children: Node }) => {
  const history = useHistory();
  const leaseSummaryDrawerRef = useRef();

  const handleOpen = () => {
    leaseSummaryDrawerRef.current.open();
  };

  const handleClose = () => {
    history.push(DASHBOARD);
    leaseSummaryDrawerRef.current.close();
  };

  const state = useMemo(() => {
    const newState = {};
    newState.onOpen = handleOpen;
    newState.onClose = handleClose;
    newState.ref = leaseSummaryDrawerRef;
    return newState;
  }, [handleOpen, handleClose, leaseSummaryDrawerRef]);

  return (
    <LeaseSummaryDrawerContext.Provider value={state}>
      {children}
    </LeaseSummaryDrawerContext.Provider>
  );
};

// Hook to simplify manipulating of Lease Summary Drawer
export const useLeaseSummaryDrawer = () => useContext(LeaseSummaryDrawerContext);
