// @flow
import React from 'react';

import { Box, Text, IconButton } from '@chakra-ui/react';

import IconChevronLeft from '@icons/IconChevronLeft';

import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';
import type { DrawerHeaderProps } from '../types';

import {
  drawerSecondaryCloseButtonStyles,
  secondaryDrawerHeaderStyles,
  drawerHeaderTitleStyles,
} from './style';

// Component
const SecondaryDrawerHeader = ({ title = '', onClose = () => {} }: DrawerHeaderProps) => {
  const isDesktopScreen = useIsLargerThanSmallScreen();

  return (
    <Box {...secondaryDrawerHeaderStyles(isDesktopScreen)}>
      <IconButton
        variant="unstyled"
        size="md"
        {...drawerSecondaryCloseButtonStyles(isDesktopScreen)}
        icon={<IconChevronLeft />}
        onClick={onClose}
      />
      <Text {...drawerHeaderTitleStyles(isDesktopScreen)}>{title}</Text>
    </Box>
  );
};

export default SecondaryDrawerHeader;
