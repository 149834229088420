import React from 'react';

type IconChevronLeftProps = {
  w?: Number,
  h?: Number,
  color?: String,
};

function IconChevronLeft({ w = 16, h = 16, color = 'currentColor' }: IconChevronLeftProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4715 2.86189C10.2111 2.60154 9.78903 2.60154 9.52868 2.86189L4.86201 7.52855C4.60166 7.7889 4.60166 8.21101 4.86201 8.47136L9.52868 13.138C9.78903 13.3984 10.2111 13.3984 10.4715 13.138C10.7318 12.8777 10.7318 12.4556 10.4715 12.1952L6.27622 7.99996L10.4715 3.8047C10.7318 3.54435 10.7318 3.12224 10.4715 2.86189Z"
        fill={color}
      />
    </svg>
  );
}

IconChevronLeft.defaultProps = {
  w: 16,
  h: 16,
  color: 'currentColor',
};

export default IconChevronLeft;
