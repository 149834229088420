// @flow
import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import BaselaneInfoRow from '@shared/components/BaselaneInfoRow';
import formatCurrency from '@core/utils/formatCurrency';
import formatTitleCase from '@core/utils/formatTitleCase';
import type { DepositHeld as DepositHeldType } from '@core/apollo/types/lease-payment-details.type';
import { getDepositLabel } from '@core/utils/getDepositLabel';

import { sectionTitleStyles } from '../../styles';

// Props
type DepositsAndOneTimePaymentsProps = {
  depositHeld: DepositHeldType[],
};

const DepositsAndOneTimePayments = ({ depositHeld = [] }: DepositsAndOneTimePaymentsProps) => {
  return (
    <Box>
      <Text {...sectionTitleStyles}>Deposits & One-Time Payments</Text>
      {depositHeld.map(
        ({ name, amount, status, description }: DepositHeldType) =>
          !!amount && (
            <BaselaneInfoRow
              key={`${name}${amount}${status}`}
              label={getDepositLabel(name) || description || formatTitleCase(name)}
              value={amount && formatCurrency(amount).inDollars}
            />
          )
      )}
    </Box>
  );
};

export default DepositsAndOneTimePayments;
