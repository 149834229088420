import React from 'react';

import { CircularProgress, Box } from '@chakra-ui/react';

import { preloaderContainerStyles } from '@shared/components/BaselanePreloader/style';

function Preloader() {
  return (
    <Box {...preloaderContainerStyles}>
      <CircularProgress color="brand.neutral.700" isIndeterminate />
    </Box>
  );
}

export default Preloader;
