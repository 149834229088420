// @flow
import { LOGIN, DASHBOARD, ONBOARDING } from '@routing/constants/route.constants';
import type { AuthConfig } from './types/auth-config.type';

export const authConfig: AuthConfig = {
  firebaseWebKey: process.env.REACT_APP_FIREBASE_WEB_KEY,
  loginRedirectUrl: DASHBOARD,
  logoutRedirectUrl: LOGIN,
  onboardingUrl: ONBOARDING,
};
