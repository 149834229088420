// @flow
import React from 'react';
import { Icon } from '@chakra-ui/react';
import type { IconProps } from '@icons/types';

function IconWarning({ width = 24, height = 24, color = 'currentColor', ...rest }: IconProps) {
  return (
    <Icon
      {...rest}
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.40204 3.4998C10.557 1.50039 13.4429 1.50039 14.5979 3.4998L21.5259 15.4998C22.681 17.4995 21.2378 20 18.928 20H5.07196C2.76222 20 1.319 17.4997 2.47393 15.5C2.47397 15.4999 2.47389 15.5001 2.47393 15.5L9.40204 3.4998ZM11.1339 4.50019L4.20599 16.5C3.82113 17.1662 4.30177 18 5.07196 18H18.928C19.6981 18 20.1789 17.1664 19.794 16.5002L12.866 4.50019C12.481 3.8336 11.5189 3.8336 11.1339 4.50019ZM12 7.99999C12.5522 7.99999 13 8.44771 13 8.99999V11C13 11.5523 12.5522 12 12 12C11.4477 12 11 11.5523 11 11V8.99999C11 8.44771 11.4477 7.99999 12 7.99999ZM11 15C11 14.4477 11.4477 14 12 14H12.01C12.5622 14 13.01 14.4477 13.01 15C13.01 15.5523 12.5622 16 12.01 16H12C11.4477 16 11 15.5523 11 15Z"
        fill={color}
      />
    </Icon>
  );
}

export default IconWarning;
