import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './app/modules/core/translations/en/translation.json';

const resources = {
  en,
};

i18n.use(initReactI18next).init({
  resources,
  ns: ['translation'],
  defaultNS: 'translation',
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: { wait: true },
});

export default i18n;
