// @flow
import type { Fee } from '@core/apollo/types/lease-payment-details.type';
import isEmpty from '@core/utils/validators/isEmpty';

/**
 * Calculate total fee. Fee must have 'amount' field.
 * @param fees {Fee[]}
 * @return {number}
 */
export function calculateTotalFee(fees: Fee[]): number {
  if (isEmpty(fees)) {
    return 0;
  }

  return fees
    .filter((fee: Fee) => Boolean(fee?.amount))
    .reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0);
}
