import React from 'react';
import { Stack } from '@chakra-ui/react';
import { cardButtonStyles } from './styles/baselaneButtonCard.styles';

type BaselaneButtonCardProps = {
  children: any,
  onClick: Function,
  buttonStyles?: Object,
  type?: string,
  id?: string,
};

const BaselaneButtonCard = ({
  children,
  onClick,
  buttonStyles,
  type,
  id,
}: BaselaneButtonCardProps) => {
  const styles = {
    ...cardButtonStyles(type),
    ...buttonStyles,
  };

  return (
    <Stack isInline {...styles} onClick={onClick} id={id}>
      {children}
    </Stack>
  );
};

BaselaneButtonCard.defaultProps = {
  buttonStyles: {},
  type: 'original',
  id: null,
};

export default BaselaneButtonCard;
