// flow
import React from 'react';

import type { IconProps } from '@icons/types';

function IconPaymentMethods({ width = 24, height = 24, color = 'currentColor' }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 10h18H3zm4 5h1-1zm5 0h1-1zm-6 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconPaymentMethods;
