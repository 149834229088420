// @flow
import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import {
  buttonStyles,
  leftButton,
  rightButton,
  activeStyles,
  focusStyles,
  hoverStyles,
  disabledStyles,
  disabledStylesInactive,
} from '@shared/components/BaselaneButtonToggle/style';

function ButtonToggle({
  firstLabel = '',
  activeButton = 0,
  secondLabel = '',
  name = '',
  data,
  id = '',
  isFormik,
  isDisabled,
  onChange = () => {},
  onClick = null,
  onBlur = () => {},
  setFieldValue = () => {},
  setFormVariables = () => {},
}: {
  firstLabel: string,
  secondLabel: string,
  name?: string,
  data?: Object,
  id: string,
  activeButton: number,
  isFormik?: boolean,
  isDisabled?: boolean,
  onClick?: Function,
  onChange?: Function,
  onBlur?: Function,
  setFieldValue?: Function,
  setFormVariables?: Function,
}): any {
  const [activeIndex, setActiveIndex] = useState(activeButton);

  let disabledStylesOne = {};
  let disabledStylesTwo = {};
  if (isDisabled) {
    disabledStylesOne = disabledStyles;
    disabledStylesTwo = disabledStylesInactive;
  }
  const [buttonOneStyles, setButtonOneStyles] = useState(
    activeIndex === 0
      ? { ...buttonStyles, ...activeStyles, ...disabledStylesOne }
      : { ...buttonStyles, ...disabledStylesOne }
  );
  const [buttonTwoStyles, setButtonTwoStyles] = useState(
    activeIndex === 1
      ? { ...buttonStyles, ...activeStyles, ...disabledStylesTwo }
      : { ...buttonStyles, ...disabledStylesTwo }
  );

  useEffect(() => {
    if (activeIndex === 0) {
      setButtonOneStyles({ ...buttonStyles, ...activeStyles, ...disabledStylesOne });
      setButtonTwoStyles({ ...buttonStyles, ...disabledStylesTwo });
    } else {
      setButtonTwoStyles({ ...buttonStyles, ...activeStyles, ...disabledStylesOne });
      setButtonOneStyles({ ...buttonStyles, ...disabledStylesTwo });
    }
  }, [activeIndex]);

  return (
    <Box>
      <Box
        {...buttonOneStyles}
        {...leftButton}
        value="0"
        as="button"
        _hover={isDisabled ? { borderColor: 'none' } : hoverStyles}
        _focus={focusStyles}
        onClick={() => {
          if (onClick !== null) {
            onClick(0);
          }
          setActiveIndex(0);
        }}
        onChange={(e) => {
          onChange(e);
          if (isFormik) {
            setFieldValue(name, 0);
          }
        }}
        onBlur={(e) => {
          onBlur(e);
          if (isFormik) {
            const frequency = Number(e.target.value) === 0 ? 'FIXED' : 'MONTHLY';
            if (data.id) {
              setFormVariables({
                payments: [{ id, name: 'FEE', frequency }],
              });
            } else {
              setFormVariables({
                payments: [{ name: 'FEE', frequency }],
              });
            }
          }
        }}
      >
        {firstLabel}
      </Box>
      <Box
        {...buttonTwoStyles}
        {...rightButton}
        value="1"
        as="button"
        _hover={isDisabled ? { borderColor: 'none' } : hoverStyles}
        _focus={focusStyles}
        onClick={() => {
          if (onClick !== null) {
            onClick(1);
          }
          setActiveIndex(1);
        }}
        onChange={(e) => {
          onChange(e);
          if (isFormik) {
            setFieldValue(name, 1);
          }
        }}
        onBlur={(e) => {
          onBlur(e);
          if (isFormik) {
            const frequency = e.target.value === 0 ? 'FIXED' : 'MONTHLY';
            if (data.id) {
              setFormVariables({
                payments: [{ id, name: 'FEE', frequency }],
              });
            } else {
              setFormVariables({
                payments: [{ name: 'FEE', frequency }],
              });
            }
          }
        }}
      >
        {secondLabel}
      </Box>
    </Box>
  );
}

ButtonToggle.defaultProps = {
  isFormik: false,
  isDisabled: false,
  name: '',
  data: null,
  setFieldValue: () => {},
  setFormVariables: () => {},
  onBlur: () => {},
  onChange: () => {},
  onClick: null,
};

export default ButtonToggle;
