// @flow
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import isNil from '@core/utils/validators/isNil';

import { SIGN_UP_WIZARD } from '@routing/constants/route.constants';

import { GET_TENANT } from '@core/apollo/requests/tenant.graphql';
import { useTenantUrlData } from '@core/hooks/useTenantUrlData';

// Context
const AuthContext = createContext();

// Provider
export const AuthProvider = ({ children }: { children: mixed }): AuthContext => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { loading, data, refetch: refetchTenant } = useQuery(GET_TENANT);

  const { inviteCode, urlId } = useTenantUrlData();

  useEffect(() => {
    const id = urlId;

    if (!inviteCode) {
      return;
    }

    const formatId = id === null ? '' : `&id=${id}`;

    history.push({
      pathname: SIGN_UP_WIZARD,
      search: `inviteCode=${inviteCode}${formatId}`,
    });
  }, []);

  useEffect(() => {
    window.analytics.page();
  }, [pathname]);

  const state = useMemo(() => {
    const tenant = isNil(data?.tenant?.id) ? null : data.tenant;
    const newState = { tenant, refetchTenant };
    return newState;
  }, [data, refetchTenant]);

  return <AuthContext.Provider value={state}>{!loading && children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
