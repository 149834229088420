import React from 'react';
import { isMobile } from 'react-device-detect';
import { Grid, GridItem, Stack } from '@chakra-ui/react';
import { IconChevronLeft } from '@icons';
import BaselaneButtonIcon from '@shared/components/BaselaneButtonIcon';

import FinishLaterButton from './FinishLaterButton';
import { containerStyles, leftElementsContainerStyles } from './styles/headerNavWrapper.styles';
import BaselaneFullLogo from '../../../assets/BaselaneFullLogo';
import customTheme from '../../styles/theme/theme';

type HeaderNavWrapperProps = {
  children: any,
  showBackButton?: boolean,
  hideBackButton?: boolean,
  onClickBack?: Function,
  handleFinishEvent?: Function,
  hideFinishLater?: Function,
  leftHeaderContent?: any,
};

// Note: keep styles consistent with /tenantportal/src/app/layouts/HeaderNavWrapper
const HeaderNavWrapper = ({
  children,
  showBackButton,
  hideBackButton,
  onClickBack,
  handleFinishEvent,
  hideFinishLater,
  leftHeaderContent,
}: HeaderNavWrapperProps) => {
  const handleBackClick = () => {
    onClickBack();
  };

  return (
    <Stack {...containerStyles} id="headerNavWrapper">
      <Grid {...leftElementsContainerStyles} id="grid">
        {(isMobile || showBackButton) && (
          <GridItem placeSelf="center start">
            {!hideBackButton && (
              <BaselaneButtonIcon
                variant="secondary"
                icon={<IconChevronLeft />}
                onClick={handleBackClick}
                styles={{ border: 'none' }}
              />
            )}
          </GridItem>
        )}
        <GridItem flex="1" alignSelf="center" justifySelf={isMobile ? 'center' : 'start'}>
          <Stack isInline align="center" color="brand.neutral.600" spacing="7px">
            {leftHeaderContent}
          </Stack>
        </GridItem>
        {!hideFinishLater && (
          <GridItem justifySelf="end">
            <FinishLaterButton {...{ handleFinishEvent }} />
          </GridItem>
        )}
      </Grid>
      {children}
    </Stack>
  );
};

HeaderNavWrapper.defaultProps = {
  showBackButton: false,
  hideBackButton: false,
  onClickBack: () => {},
  handleFinishEvent: null,
  hideFinishLater: false,
  leftHeaderContent: <BaselaneFullLogo color={customTheme.colors.brand.darkBlue['800A']} />,
};

export default HeaderNavWrapper;
