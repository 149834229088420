export const errorModalContentStyles = (isDesktop: boolean) => ({
  maxWidth: '636px',
  padding: isDesktop ? '80px 100px' : '40px 16px',
  margin: '120px 16px auto 16px',
  textAlign: 'center',
});

export const errorModalButtonGroupStyles = (isDesktop: boolean) => ({
  direction: isDesktop ? 'row' : 'column',
  spacing: isDesktop ? '28px' : '16px',
  width: isDesktop ? 'auto' : '100%',
});

export const accentTextStyles = (isDesktop: boolean) => ({
  marginBottom: '16px',
  textStyle: 'headline-2xl',
});
