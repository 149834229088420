import React from 'react';

import type { IconProps } from '@icons/types';

function IconCreditCard({ color = '#001843', width = '16px', height = '16px' }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.00005 9.75004C3.00005 9.33582 3.33583 9.00004 3.75005 9.00004H7.25005C7.66426 9.00004 8.00005 9.33582 8.00005 9.75004C8.00005 10.1643 7.66426 10.5 7.25005 10.5H3.75005C3.33583 10.5 3.00005 10.1643 3.00005 9.75004Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 14H14C15.1046 14 16 13.1046 16 12V4C16 2.89975 15.1116 2.007 14.0129 2.00004L14 2H2C0.895431 2 0 2.89543 0 4V12C0 13.1046 0.895431 14 2 14ZM14.5 5.00004V4C14.5 3.72386 14.2761 3.5 14 3.5H2C1.72386 3.5 1.5 3.72386 1.5 4V5.00004H14.5ZM14.5 6.50004H1.5V12C1.5 12.2761 1.72386 12.5 2 12.5H14C14.2761 12.5 14.5 12.2761 14.5 12V6.50004Z"
        fill={color}
      />
    </svg>
  );
}

export default IconCreditCard;
