// @flow
import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import type { Address, TenantPropertyUnit } from '@core/apollo/types/tenant-property-unit.type';
import BaselaneInfoRow from '@shared/components/BaselaneInfoRow';
import formatDate from '@core/utils/formatDate';

import { sectionTitleStyles } from '../../styles';

// Props
type PropertyDetailsProps = {
  startDate: string | Date,
  endDate: string | Date,
  propertyDetails: TenantPropertyUnit,
  leaseType: string,
};

// Component
const PropertyDetails = ({
  startDate,
  endDate,
  propertyDetails,
  leaseType,
}: PropertyDetailsProps) => {
  const { address, unit, city, state, zipCode } = (propertyDetails?.address: Address) ?? {};
  const isMonthlyLease = () => {
    const result = leaseType === 'MONTHLY' ? 'Month-to-month' : '';
    return result;
  };

  return (
    <Box>
      <Text {...sectionTitleStyles}>Property Details</Text>
      <BaselaneInfoRow
        label="Lease Terms"
        value={`${startDate ? formatDate(startDate) : ''} - ${
          endDate ? formatDate(endDate) : isMonthlyLease()
        }`}
      />
      <BaselaneInfoRow
        label="Address"
        value={
          <Flex direction="column">
            <Text>
              {address}
              {unit ? `, ${unit}` : ''}
            </Text>
            <Text>
              {city},&nbsp;{state}&nbsp;{zipCode}
            </Text>
          </Flex>
        }
      />
    </Box>
  );
};

export default PropertyDetails;
