// @flow
import React from 'react';

import type { Direction, IconWithDirectionProps } from './types';

// Rotation mapping [direction to rotation degree]
const rotationMapping: { [directionKey: Direction]: number } = {
  up: 180,
  down: 0, // default position
  right: 90,
  left: -90,
};

// Component
const IconCaret = ({ direction = 'down', color = 'currentColor' }: IconWithDirectionProps) => {
  return (
    <svg
      style={{ transform: `rotate(${rotationMapping[direction]}deg)` }}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.25c0 .42.33.75.75.75h10.5a.743.743 0 00.555-1.245l-5.25-6A.747.747 0 006 .5a.747.747 0 00-.555.255l-5.25 6A.73.73 0 000 7.25z"
        fill={color}
      />
    </svg>
  );
};

export default IconCaret;
