import React from 'react';

import type { IconProps } from '@icons/types';

function IconBank({ width, height }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.08" cx="20" cy="20" r="20" fill="#0968BF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8037 12.8874C11.5851 12.106 12.6449 11.667 13.75 11.667H26.25C27.355 11.667 28.4149 12.106 29.1963 12.8874C29.9777 13.6688 30.4166 14.7286 30.4166 15.8337V24.167C30.4166 25.2721 29.9777 26.3319 29.1963 27.1133C28.4149 27.8947 27.355 28.3337 26.25 28.3337H13.75C12.6449 28.3337 11.5851 27.8947 10.8037 27.1133C10.0223 26.3319 9.58331 25.2721 9.58331 24.167V15.8337C9.58331 14.7286 10.0223 13.6688 10.8037 12.8874ZM11.6666 18.9587V24.167C11.6666 24.7195 11.8861 25.2494 12.2768 25.6401C12.6675 26.0308 13.1974 26.2503 13.75 26.2503H26.25C26.8025 26.2503 27.3324 26.0308 27.7231 25.6401C28.1138 25.2494 28.3333 24.7195 28.3333 24.167V18.9587H11.6666ZM28.3333 16.8753H11.6666V15.8337C11.6666 15.2811 11.8861 14.7512 12.2768 14.3605C12.6675 13.9698 13.1974 13.7503 13.75 13.7503H26.25C26.8025 13.7503 27.3324 13.9698 27.7231 14.3605C28.1138 14.7512 28.3333 15.2811 28.3333 15.8337V16.8753ZM13.75 23.1253C13.75 22.55 14.2164 22.0837 14.7916 22.0837H15.8333C16.4086 22.0837 16.875 22.55 16.875 23.1253C16.875 23.7006 16.4086 24.167 15.8333 24.167H14.7916C14.2164 24.167 13.75 23.7006 13.75 23.1253ZM18.9583 23.1253C18.9583 22.55 19.4247 22.0837 20 22.0837H21.0416C21.6169 22.0837 22.0833 22.55 22.0833 23.1253C22.0833 23.7006 21.6169 24.167 21.0416 24.167H20C19.4247 24.167 18.9583 23.7006 18.9583 23.1253Z"
        fill="#257ED0"
      />
    </svg>
  );
}

IconBank.defaultProps = {
  width: '24px',
  height: '24px',
};

export default IconBank;
