// @flow
import React, { createContext, useContext, Node, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { StoreHelpers } from 'react-joyride';

import { DASHBOARD } from '@routing/constants/route.constants';

import type { TourHelpers } from '../../types/tour-helpers.type';

// Context
const ProductTourContext = createContext();

// Provider
export const ProductTourProvider = ({ children }: { children: Node }) => {
  const [start, setStart] = useState<boolean>(false);
  const [tourHelpers, setTourHelpers] = useState<TourHelpers>({});
  const history = useHistory();

  const startTour = (): void => {
    setStart(true);
    history.push(DASHBOARD);
  };

  const endTour = (): void => {
    setStart(false);
  };

  const handleSetTourHelpers = (storeHelpers: StoreHelpers) => {
    setTourHelpers({
      ...storeHelpers,
      startTour,
      endTour,
    });
  };

  // These should only be accessed by ReactJoyride instance
  const tourInternals = {
    start,
    handleSetTourHelpers,
  };

  const state = useMemo(() => {
    const newState = {};
    newState.tourHelpers = tourHelpers;
    newState.tourInternals = tourInternals;
    return newState;
  }, [tourHelpers, tourInternals]);

  return <ProductTourContext.Provider value={state}>{children}</ProductTourContext.Provider>;
};

// Hook to simplify access to Product Tour data from the contexts
export const useProductTour = (): { tourHelpers: TourHelpers, tourInternals: Object } =>
  useContext(ProductTourContext);
