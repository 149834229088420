import React from 'react';

import { isMobile } from 'react-device-detect';
import { VStack, Text, Progress } from '@chakra-ui/react';

import './styles/progressBar.scss';

type BaselaneSimpleProgressBarProps = {
  totalSteps: number,
  step: number,
};

const BaselaneSimpleProgressBar = ({ totalSteps, step }: BaselaneSimpleProgressBarProps) => {
  return (
    <VStack {...{ className: 'baselane-simple-progress-bar', mb: '8px' }}>
      {isMobile && (
        <Progress
          {...{
            w: '100%',
            h: '8px',
            borderRadius: '4px',
            value: step,
            max: totalSteps,
            min: 0,
            size: 'lg',
          }}
        />
      )}
      <Text
        {...{
          textStyle: 'xs',
          color: isMobile ? 'brand.blue.400' : 'brand.neutral.600',
          textAlign: 'left',
          alignSelf: 'flex-start',
        }}
      >
        Step {step} of {totalSteps}
      </Text>
    </VStack>
  );
};

export default BaselaneSimpleProgressBar;
