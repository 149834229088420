import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import IconClose from '@icons/IconClose';
import { alertHeader, alertBody, alertFooter, alertContainer } from './styles';
import BaselaneButtonIcon from '../BaselaneButtonIcon';

function Alert({
  isOpen,
  onClose,
  header,
  body,
  cancelRef,
  footer,
  finalFocusRef,
  closeOnOverlayClick = true,
  isCentered = true,
  returnFocusOnClose = false,
  size = 'lg',
  headerStyles = {},
  bodyStyles = {},
  containerStyles = {},
  footerStyles = {},
  closeButtonStyles = {},
  alertCloseButton = {},
  isMobile = false,
  showCloseButton = false,
}: {
  isOpen: Function,
  onClose: Function,
  closeOnOverlayClick: boolean,
  isCentered: boolean,
  header: any,
  body: any,
  cancelRef: any,
  footer: any,
  finalFocusRef: any,
  returnFocusOnClose: any,
  size: string,
  containerStyles?: Object,
  headerStyles: Object,
  bodyStyles: Object,
  footerStyles: Object,
  isMobile: boolean,
  showCloseButton?: boolean,
  closeButtonStyles?: Object,
  alertCloseButton?: Object,
}) {
  const alertHeaderStyles = {
    ...alertHeader(isMobile),
    ...headerStyles,
  };
  const alertBodyStyles = {
    ...alertBody(isMobile),
    ...bodyStyles,
  };
  const alertFooterStyles = {
    ...alertFooter(isMobile),
    ...footerStyles,
  };

  const alertDialogContainerStyles = {
    ...alertContainer(isMobile),
    ...containerStyles,
  };
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
      isCentered={isCentered}
      size={size}
      finalFocusRef={finalFocusRef}
      returnFocusOnClose={returnFocusOnClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent {...alertDialogContainerStyles}>
          {showCloseButton && (
            <AlertDialogCloseButton
              className="icon-button"
              as={BaselaneButtonIcon}
              icon={<IconClose color="#3A4B5B" />} // neutral.600
              type="ghost"
              styles={{ ...alertCloseButton, ...closeButtonStyles }}
            />
          )}
          <AlertDialogHeader {...alertHeaderStyles}>{header}</AlertDialogHeader>

          <AlertDialogBody {...alertBodyStyles}>{body}</AlertDialogBody>

          <AlertDialogFooter {...alertFooterStyles}>{footer}</AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

Alert.defaultProps = {
  closeButtonStyles: {},
  alertCloseButton: {},
  showCloseButton: false,
  containerStyles: {},
};

export default Alert;
