// @flow
import React, { Component } from 'react';
import type { Node } from 'react';

// Props Interface
interface Props {
  children?: Node;
}

// State Interface
interface State {
  hasError: boolean;
  error: ?Error;
}

// Component
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error: Error): void {
    this.setState({ hasError: true, error });
    // This could log to sentry or another error reporting tool
  }

  render(): Node {
    const { error, hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <>
          <span>Something went wrong</span>
          <div>{error}</div>
        </>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
