import React from 'react';

type IconChevronRightProps = {
  w?: Number,
  h?: Number,
  color?: String,
};

function IconChevronRight({ w = 9, h = 15, color = '#687DA3' }: IconChevronRightProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.529247 0.861766C0.789596 0.601417 1.21171 0.601417 1.47206 0.861766L6.13872 5.52843C6.39907 5.78878 6.39907 6.21089 6.13872 6.47124L1.47206 11.1379C1.21171 11.3983 0.789596 11.3983 0.529247 11.1379C0.268897 10.8776 0.268897 10.4554 0.529247 10.1951L4.72451 5.99984L0.529247 1.80458C0.268897 1.54423 0.268897 1.12212 0.529247 0.861766Z"
        fill={color}
      />
    </svg>
  );
}

IconChevronRight.defaultProps = {
  w: 9,
  h: 15,
  color: '#3A4B5B',
};

export default IconChevronRight;
