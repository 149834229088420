import React from 'react';

import type { IconProps } from '@icons/types';

function IconChevronUp({ color = '#091A2D', width = '16px', height = '16px' }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5303 10.5304C12.2374 10.8233 11.7626 10.8233 11.4697 10.5304L8 7.0607L4.53033 10.5304C4.23744 10.8233 3.76256 10.8233 3.46967 10.5304C3.17678 10.2375 3.17678 9.7626 3.46967 9.46971L7.42515 5.51423C7.74263 5.19675 8.25737 5.19675 8.57485 5.51423L12.5303 9.46971C12.8232 9.76261 12.8232 10.2375 12.5303 10.5304Z"
        fill={color}
      />
    </svg>
  );
}

export default IconChevronUp;
