// @flow
import React from 'react';
import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import type { BaselaneInfoTableItemListType } from '@shared/types/baselaneInfoTable.type';

type BaselaneInfoTableProps = {
  itemList: BaselaneInfoTableItemListType[],
};

function BaselaneInfoTable({ itemList }: BaselaneInfoTableProps): any {
  return (
    <Box borderRadius="4px" border="1px solid" borderColor="brand.darkBlue.200">
      <Grid>
        {itemList.map((item) => (
          <GridItem
            key={`${item.label}${item.value}`}
            p="16px 24px"
            _notLast={{ borderBottomWidth: '1px', borderBottomColor: 'brand.darkBlue.200' }}
          >
            <Text lineHeight="20px" fontWeight="normal" color="brand.neutral.600" mb="4px">
              {item.label}
            </Text>
            <Text lineHeight="24px">{item.value}</Text>
            {item.extraValue && item.extraValue}
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
}

export default BaselaneInfoTable;
