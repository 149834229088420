// @flow
import React from 'react';
import type { Node } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';

import {
  infoRowWrapperStyles,
  infoRowLabelStyles,
  infoRowValueStyles,
} from '@shared/components/BaselaneInfoRow/style';

// Props Interface
type InfoRowProps = BoxProps & {
  label: string | Node,
  value: Node,
  hide?: boolean,
  rowLabelStyles?: Object,
};

// Component
const BaselaneInfoRow = ({
  label,
  value,
  hide,
  rowLabelStyles,
  subtrackFee,
  ...rest
}: InfoRowProps) => {
  const isDesktopScreen = useIsLargerThanSmallScreen();

  return hide ? null : (
    <Box {...infoRowWrapperStyles(isDesktopScreen)} {...rest}>
      <Box {...{ ...infoRowLabelStyles, ...rowLabelStyles }}>{label}</Box>
      <Box
        textAlign="end"
        {...infoRowValueStyles(isDesktopScreen)}
        textDecoration={subtrackFee ? 'line-through' : 'none'}
      >
        {value}
      </Box>
    </Box>
  );
};

BaselaneInfoRow.defaultProps = {
  rowLabelStyles: {},
  hide: false,
};

export default BaselaneInfoRow;
