import { body, headlines } from '@theme/text.style';

const baseStyle = {
  fontWeight: 'medium',
  borderRadius: '4px',
  alignSelf: 'center',
  _disabled: {
    opacity: 1,
    cursor: 'default',
  },
  _active: {
    boxShadow: 'none',
  },
  _focus: {
    boxShadow: 'none',
  },
};

const sizes = {
  lg: {
    h: '40px',
    ...body.sm,
  },
  md: {
    h: '32px',
    ...body.xs,
  },
};

const LABEL_THEMES = {
  default: {
    ...headlines['headline-sm'],
    h: '40px',
  },
  blue: {
    w: '100%',
    h: '40px',
    bgColor: 'brand.blue.700',
    color: 'brand.neutral.white',
    ...headlines['headline-sm'],
  },
  green: {
    ...headlines['headline-sm'],
    h: '40px',
    bgColor: 'green.100',
    color: 'green.800AA',
  },
  red: {
    ...headlines['headline-sm'],
    h: '40px',
    bgColor: 'red.200',
    color: 'red.800AA',
  },
  lightBlue: {
    ...headlines['headline-sm'],
    h: '40px',
    bgColor: 'brand.blue.100',
    color: 'brand.blue.700',
  },
  outline: {
    ...headlines['headline-sm'],
    h: '40px',
    color: 'brand.neutral.600',
    bgColor: 'brand.neutral.white',
    variant: 'outline',
  },
};

export const Label = {
  baseStyle,
  sizes,
  variants: LABEL_THEMES,
};
