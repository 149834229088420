// @flow
import React, { ChangeEvent, ReactNode, useCallback } from 'react';

import MaskedInput from 'react-text-mask/dist/reactTextMask';
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Text,
  Textarea,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { Field, FieldInputProps, FormikProps } from 'formik';

import { formLabelStyles, inputRightStyles } from '@shared/components/BaselaneInput/style';
import { body } from '@theme/text.style';

// Props
export type BaselaneInputProps = InputProps & {
  label?: string,
  onChange?: Function,
  rightElement?: ReactNode,
  rightElementWidth?: string,
  mask?: any,
  isTextarea?: boolean,
  resize?: 'none' | 'vertical' | 'horizontal',
  formHelperText?: string,
  textareaHeight?: string,
  isDisabled?: boolean,
  customformLabelStyles: Object,
  customformInputStyles: Object,
  errorLeftElement?: any,
  inputRef?: any,
  onFocus?: Function,
  onBlur?: Function,
};

// Component
const BaselaneInput = ({
  name,
  mask,
  label,
  placeholder,
  onChange,
  onKeyDown,
  readOnly,
  rightElement,
  rightElementWidth,
  isTextarea,
  type,
  resize,
  formHelperText,
  maxLength,
  textareaHeight,
  isDisabled,
  customformLabelStyles,
  customformInputStyles,
  errorLeftElement,
  inputRef,
  onFocus,
  onBlur,
  ...styles
}: BaselaneInputProps): ReactNode => {
  /**
   * Return Type of Form Control
   * @return {ReactNode}
   */
  function inputType(): ReactNode {
    if (isTextarea) {
      return Textarea;
    }

    if (mask) {
      return MaskedInput;
    }

    return Input;
  }

  /*
   * Custom handler for onBlur event.
   * Needed for trimming input values since formik doesn't apply trim method from yup library
   * https://github.com/formium/formik/issues/473
   */
  const handleOnBlur = useCallback(
    (event: ChangeEvent, field: FieldInputProps, form: FormikProps) => {
      form.setFieldValue(event.target.name, event.target.value?.trim());
      field?.onBlur(event);
    },
    []
  );
  return (
    <Field name={name} {...styles}>
      {({ field, form }) => {
        const { errors, touched } = form;
        const isTouched = touched?.[name];
        const errorMessage = errors?.[name];

        const isInvalid = isTouched && errorMessage;
        const isSignInLabel =
          (label === 'Email' && placeholder === 'Enter Email') ||
          (label === 'Password' && placeholder === 'Enter Password');

        return (
          <FormControl isInvalid={isInvalid} isRequired={label === 'Email' || label === 'Password'}>
            <FormLabel
              {...(isSignInLabel
                ? { ...formLabelStyles, fontWeight: 'normal' }
                : { ...formLabelStyles })}
              {...customformLabelStyles}
            >
              {label}
            </FormLabel>
            {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
            <InputGroup>
              <Input
                ref={inputRef}
                {...field}
                {...body.sm}
                {...customformInputStyles}
                size="lg"
                id={name}
                type={type}
                mask={mask}
                as={inputType()}
                resize={resize}
                readOnly={readOnly}
                height={textareaHeight}
                color="brand.neutral.700"
                value={field?.value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onBlur={(event) => {
                  handleOnBlur(event, field, form);
                  onBlur?.(event); // Custom onBlur callback.
                }}
                placeholder={placeholder}
                autoComplete="off"
                isDisabled={isDisabled}
                maxLength={maxLength}
                onFocus={onFocus}
              />
              {rightElement && (
                <InputRightElement {...inputRightStyles(rightElementWidth)}>
                  {rightElement}
                </InputRightElement>
              )}
            </InputGroup>
            {errorLeftElement ? (
              <FormErrorMessage>
                {errorLeftElement}
                <Text ml="8px">{errorMessage}</Text>
              </FormErrorMessage>
            ) : (
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
};

BaselaneInput.defaultProps = {
  label: '',
  placeholder: '',
  handleChange: () => {},
  readOnly: false,
  rightElement: null,
  rightElementWidth: '48px',
  mask: null,
  isTextarea: false,
  resize: 'none',
  type: 'text',
  isDisabled: false,
  errorLeftElement: null,
};

export default BaselaneInput;
