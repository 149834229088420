// @flow
import React from 'react';
import { HStack, Text, Box } from '@chakra-ui/react';
import icons from '../icons';
import {
  getBadgeIconStyles,
  getFontStyles,
  getBackgroundStyles,
  getIconContainerStyles,
} from './styles/badge.style';

type Props = {
  children?: any,
  type?: string,
  color?: string,
  isCustom?: boolean,
  isMobile?: boolean,
  iconName?: string,
  text?: string,
  backgroundStyles?: Object,
  iconClassName?: string,
  className?: string,
  fontStyles?: Object,
  iconContainerStyles?: Object,
};
function BaselaneBadge({
  children,
  iconName,
  type,
  color,
  isCustom,
  isMobile,
  text,
  fontStyles,
  backgroundStyles,
  iconClassName,
  className,
  iconContainerStyles,
}: Props): any {
  const badgeBackgroundStyles = {
    ...getBackgroundStyles(iconName, type, color, isMobile),
    ...backgroundStyles,
  };
  const badgeTextFontStyles = { ...getFontStyles(iconName, type, color, isMobile), ...fontStyles };

  const iconClass = iconClassName || 'icon-md';

  const badgeIconContainerStyles = {
    ...getIconContainerStyles(iconName, type, color, isMobile),
    ...iconContainerStyles,
  };

  const name = iconName.toLowerCase();
  const Icon = icons[name];

  if (isCustom && children !== null) {
    return (
      <HStack {...badgeBackgroundStyles} className={className}>
        {children}
      </HStack>
    );
  }

  return (
    <HStack {...badgeBackgroundStyles} className={className}>
      {iconName !== '' ? (
        <Box {...badgeIconContainerStyles} className={iconClass}>
          <Icon {...getBadgeIconStyles(iconClass)} />
        </Box>
      ) : null}
      <Text {...badgeTextFontStyles}>{text !== '' ? text : children}</Text>
    </HStack>
  );
}

BaselaneBadge.defaultProps = {
  children: null,
  type: '',
  color: 'blue',
  isMobile: false,
  isCustom: false,
  iconName: '',
  className: '',
  text: '',
  iconClassName: '',
  fontStyles: Object,
  backgroundStyles: Object,
  iconContainerStyles: Object,
};

export default BaselaneBadge;
