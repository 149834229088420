import { headlines } from '@theme/text.style';

const styles = {
  global: {
    'html, body': {
      fontFamily: '"Ease Variable", Helvetica, Arial',
      color: 'brand.neutral.700',
      ...headlines['headline-sm'],
      height: '100%',
    },
    button: {
      color: 'white',
      ...headlines['headline-sm'],
    },
    svg: {
      display: 'inline-block',
    },
  },
};

export default styles;
