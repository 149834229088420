// @flow
import React, { Component } from 'react';
import { Box, Stack } from '@chakra-ui/react';

import getBreakPoints from '@core/utils/getBreakPoints';
import BaselaneButton from '@shared/components/BaselaneButton';
import {
  drawerFooterContainerStyles,
  drawerFooterStyles,
} from '@shared/components/BaselaneDrawer/style';

import InformationProtected from '../../../../../InformationProtected';

// Props
type StripeFooterProps = {
  close: Function,
  loading: Boolean,
  isCardNumberComplete: Boolean,
  isCVCComplete: Boolean,
  isExpirationDateComplete: Boolean,
  isFormValid: Boolean,
  handleFormikSubmit: Function,
};

// Component
function StripeFooter({
  close,
  loading,
  isCardNumberComplete,
  isCVCComplete,
  isExpirationDateComplete,
  isFormValid,
  handleFormikSubmit,
}: StripeFooterProps): Component {
  const { responsiveStyleSize, isMin899: isDesktopScreen } = getBreakPoints();

  return (
    <Box
      {...drawerFooterStyles(isDesktopScreen)[responsiveStyleSize]}
      p={isDesktopScreen && '20px 32px'}
      zIndex="2"
    >
      <Stack
        isInline={isDesktopScreen}
        spacing="16px"
        w="100%"
        flexDirection={isDesktopScreen ? 'row' : 'column-reverse'}
        {...drawerFooterContainerStyles(isDesktopScreen)}
      >
        {isDesktopScreen ? (
          <BaselaneButton size="lg" onClick={close} variant="newSecondary">
            Cancel
          </BaselaneButton>
        ) : null}
        <BaselaneButton
          size="lg"
          disabled={
            loading ||
            !isCardNumberComplete ||
            !isCVCComplete ||
            !isExpirationDateComplete ||
            !isFormValid
          }
          onClick={(e) => handleFormikSubmit(e)}
          variant="newPrimary"
        >
          Add Card
        </BaselaneButton>
        {isDesktopScreen && <InformationProtected />}
      </Stack>
    </Box>
  );
}

StripeFooter.defaultProps = {};

export default StripeFooter;
