import React from 'react';
import { Text, Stack, Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import formatDate from '@core/utils/formatDate';
import IconStar from '@icons/IconStar';
import { getAchData } from '../helpers/achData';

function BaselaneAchBadge({
  paymentMethod,
  invoice,
}: {
  paymentMethod: TenantPaymentMethod,
  invoice: Object,
}) {
  const { promoExpirationDate, isPromoActive, whoIsPaying } = getAchData();

  if (isPromoActive)
    return (
      <Stack
        isInline
        {...{
          paddingY: '2px',
          paddingX: '8px',
          bg: 'yellow.100',
          border: '1px solid #E6E9F0',
          borderRadius: '3px',
          color: 'yellow.500',
          alignItems: 'center',
        }}
      >
        <IconStar />
        <Text
          {...{
            fontSize: 'xs',
            lineHeight: '20px',
            fontWeight: 'medium',
            color: 'yellow.900',
          }}
        >
          Free until {formatDate(promoExpirationDate)}
        </Text>
      </Stack>
    );
  if (whoIsPaying(invoice) === 'LANDLORD' && !paymentMethod)
    return (
      <Box
        {...{
          paddingY: '2px',
          paddingX: '8px',
          bg: 'brand.blue.100',
          borderRadius: '2px',
        }}
      >
        <Text
          {...{
            fontSize: isMobile ? 'xxs' : 'xs',
            lineHeight: '20px',
            fontWeight: 'medium',
            color: 'brand.blue.700',
            textTransform: 'uppercase',
          }}
        >
          Covered by your landlord
        </Text>
      </Box>
    );
  if (whoIsPaying(invoice) === 'LANDLORD' && paymentMethod)
    return (
      <Text
        {...{
          fontSize: isMobile ? 'xxs' : 'sm',
          lineHeight: '20px',
          fontWeight: 'normal',
          color: 'brand.neutral.700',
        }}
      >
        Covered by your landlord
      </Text>
    );
  return null;
}

export default BaselaneAchBadge;
