// @flow
import React, { Component } from 'react';

import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';

import StripeScreen from '../StripeScreen';

// Props
type AddDebitOrCreditCardStripeWrapperProps = {
  close: Function,
  closeFlow: Function,
  noCache?: Boolean,
  onAddedBankAccount: Function,
  showAutoPayDrawer: Boolean,
  stripePublicKey: string,
};

// Component
const AddDebitOrCreditCardStripeWrapper = ({
  close,
  closeFlow,
  noCache,
  stripePublicKey,
  onAddedBankAccount,
  showAutoPayDrawer,
}: AddDebitOrCreditCardStripeWrapperProps): Component => {
  return (
    <Elements stripe={loadStripe(stripePublicKey)} mt="0">
      <StripeScreen
        close={close}
        closeFlow={closeFlow}
        noCache={noCache}
        onAddedBankAccount={onAddedBankAccount}
        showAutoPayDrawer={showAutoPayDrawer}
      />
    </Elements>
  );
};

AddDebitOrCreditCardStripeWrapper.defaultProps = {
  noCache: false,
};

export default AddDebitOrCreditCardStripeWrapper;
