// @flow
import React, { Component, useState } from 'react';
import type { Node } from 'react';
import { Text, Stack } from '@chakra-ui/react';

import { getTenantPlaidLinkToken } from '@core/apollo/services/plaid.apollo';
import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';
import IconStatusFailed from '@icons/IconStatusFailed';
import BaselanePreloader from '@shared/components/BaselanePreloader';
import { bankAddedStorage } from '@core/services';

import InformationProtected from '../InformationProtected';
import AddDebitOrCreditCard from './AddDebitOrCreditCard';
import AddBankAccount from './AddBankAccount';
import {
  addPaymentMethodContainerStyles,
  addPaymentMethodTitleStyles,
  addPaymentMethodSubtitleStyles,
  errorAddPaymentMethodTextStyle,
} from './addNewPaymentMethod.styles';

// Props
type AddNewPaymentMethodProps = {
  close: Function,
  title?: string | Node,
};

// Component
function AddNewPaymentMethod({ close, title }: AddNewPaymentMethodProps): Component {
  const { data: token, loading } = getTenantPlaidLinkToken();

  const isDesktopScreen = useIsLargerThanSmallScreen();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {isLoading && <BaselanePreloader />}

      <Stack h="100%" {...(isDesktopScreen ? { p: '48px 32px' } : { p: '36px 24px' })}>
        <Stack w="100%" h="100%" justifyContent="space-between">
          <Stack spacing={isDesktopScreen ? '24px' : '16px'} {...addPaymentMethodContainerStyles}>
            <Stack spacing={isDesktopScreen ? '16px' : '4px'}>
              <Text {...addPaymentMethodTitleStyles(isDesktopScreen)}>{title}</Text>
              <Text {...addPaymentMethodSubtitleStyles(isDesktopScreen)}>
                Add a bank account or debit/credit card. You can add or modify accounts at any time.
              </Text>

              {!loading && (error || !token || !token?.tenantPlaidLinkToken) && (
                <Text {...errorAddPaymentMethodTextStyle}>
                  <IconStatusFailed width="27px" height="27px" />
                  &nbsp;
                  {error}
                </Text>
              )}
            </Stack>

            <Stack w="100%" spacing="8px">
              {token?.tenantPlaidLinkToken && (
                <AddBankAccount
                  closeFlow={() => {
                    bankAddedStorage.delete();
                    close();
                  }}
                  setError={setError}
                  setIsLoading={setIsLoading}
                  tenantPlaidLinkToken={token?.tenantPlaidLinkToken}
                />
              )}
              <AddDebitOrCreditCard closeFlow={close} />
            </Stack>
          </Stack>
          {!isDesktopScreen && <InformationProtected />}
        </Stack>
      </Stack>
    </>
  );
}

AddNewPaymentMethod.defaultProps = {
  title: 'Select Payment Method Type',
};

export default AddNewPaymentMethod;
