import { datadogLogs } from '@datadog/browser-logs';

export function initializeDatadogLogs() {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    forwardErrorsToLogs: true,
    env: process.env.REACT_APP_NODE_ENV,
    service: 'tenantportal',
  });
}
