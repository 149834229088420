/* eslint-disable react/prop-types */
import React from 'react';
import {
  ModalHeader,
  ModalCloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Text,
} from '@chakra-ui/react';
import BaselaneButton from '@shared/components/BaselaneButton';
import IconWarningWithBackground from '@icons/IconWarningWithBackground';
import colors from '@theme/colors';

import * as styles from './styles';

const CriticalErrorModal = ({
  title,
  description,
  isOpen,
  onClose,
  criticalActionParams,
  isDesktopScreen,
}) => {
  const criticalActionButton = criticalActionParams ? (
    <BaselaneButton size="lg" colorScheme="danger" onClick={criticalActionParams.callback}>
      {criticalActionParams.label}
    </BaselaneButton>
  ) : null;

  const closeButton = isDesktopScreen ? (
    <BaselaneButton size="lg" variant="outline" colorScheme="messenger" onClick={onClose}>
      Close
    </BaselaneButton>
  ) : (
    <BaselaneButton size="lg" width="100%" colorScheme="messenger" onClick={onClose}>
      Close
    </BaselaneButton>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent {...styles.criticalErrorModalContent} width={672}>
        <ModalHeader {...styles.criticalErrorModalHeader(isDesktopScreen)}>
          <IconWarningWithBackground
            width={32}
            color={colors.red['800AA']}
            background={colors.red['200']}
          />
          <Text variant="accent" {...styles.criticalErrorModalTitle(isDesktopScreen)}>
            {title}
          </Text>
        </ModalHeader>
        <ModalCloseButton {...styles.criticalErrorCloseButton(isDesktopScreen)} />
        <ModalBody {...styles.criticalErrorModalBody(isDesktopScreen)}>
          <Text variant="secondary" color="brand.neutral.600">
            {description}
          </Text>
        </ModalBody>
        <ModalFooter {...styles.criticalErrorModalFooter(isDesktopScreen)}>
          {closeButton}
          {criticalActionButton}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CriticalErrorModal;
