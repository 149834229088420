import React from 'react';

import type { IconProps } from '@icons/types';

function IconLightning({ width, height }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8908.372335c.26538.094731.44254.346082.44254.627865v4h3.99996c.2576 0 .4922.1484.6025.38118.1103.23278.0766.50828-.0865.70764L5.84931 13.4224c-.17843.218-.47471.3004-.74009.2057-.26538-.0948-.44255-.3461-.44255-.6279v-4H.666674c-.257593 0-.492137-.1484-.6024465-.38118-.1103092-.23278-.0766428-.50828.0864745-.70765L6.1507.57804c.17844-.218087.47472-.300436.7401-.205705ZM2.0735 7.66687h3.25984c.36819 0 .66667.29847.66667.66666v2.79907l3.9265-4.79907H6.66667c-.36819 0-.66666-.29847-.66666-.66666V2.8678L2.0735 7.66687Z"
        fill="#fff"
      />
    </svg>
  );
}

IconLightning.defaultProps = {
  width: '12',
  height: '14',
};

export default IconLightning;
