// @flow
import process from 'process';
import firebase from 'firebase/app';

import { loggerService } from '@core/services';
import { ErrorModalContextActions } from '@contexts/ErrorModalContext';
import type { AccountCreationType } from '@core/auth';
import { firebaseAuthService, googleProvider } from './firebase';

// Helper
/**
 * Infers the environment from the orchestration url
 * @returns {string}
 */
const getEnv = () => {
  const orchURL = process?.env?.REACT_APP_ORCHESTRATION_URL.split('https://').join('');
  let env = 'develop';
  if (orchURL === 'orchestration.baselane.com/graphql') {
    env = 'production';
  } else if (orchURL === 'uat-orchestration.baselane.com/graphql') {
    env = 'uat';
  } else if (orchURL === 'uat-orchestration-refactor.baselane.com/graphql') {
    env = 'uat';
  }

  return env;
};

/**
 * Track user event
 * @param user
 * @param event
 */
const trackUser = (user: firebase.User, event: String) => {
  if (user) {
    const { uid, displayName, email } = user;
    window.analytics.track(event, {
      landlord_uuid: uid,
      landlord_name: displayName,
      landlord_email: email,
      user_type: 'tenant',
      event_source: 'tenant_portal',
      env: getEnv(),
    });
  }
};

/**
 * Identify user
 */
async function identifyUser(user: firebase.User, isUserInDB: boolean = true): null {
  const { uid, displayName, email } = user;
  if (isUserInDB) {
    window.analytics.identify(uid, {
      name: displayName,
      email,
      user_type: 'tenant',
      event_source: 'tenant_portal',
      env: getEnv(),
    });

    trackUser(user, 'tenant_logged_in');
  }
}

export const trackLogin = (user: firebase.User) => {
  trackUser(user, 'tenant_logged_in');
};

export const trackAccountCreated = (user: firebase.User) => {
  // TODO: Add utm, browser & operating system fields:
  // https://docs.google.com/spreadsheets/d/1tIkAzRuHhi1YvhA9T7fDYp05JHfjFT7Y1DVSlu9aL3Q/edit#gid=2082175281&range=J40:J41
  trackUser(user, 'account_created');
};

// converts an id token into a persistent session cookie, via API call
const generateSessionCookie = async (
  userCredentials: firebase.auth.UserCredential,
  isUserInDB: boolean = true
) => {
  const token = await userCredentials?.user.getIdToken();
  identifyUser(userCredentials.user, isUserInDB);
  const REACT_APP_ORCHESTRATION_ROOT =
    process?.env?.REACT_APP_ORCHESTRATION_URL?.split('/graphql').join('');
  const url = `${REACT_APP_ORCHESTRATION_ROOT}/api/sessionLogin`;
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      idToken: token,
    }),
  });
};

// Adapter for Firebase Auth service
export const authService = {
  loginWithGoogle: async (
    isUserInDB: boolean = true
  ): Promise<firebase.auth.UserCredential | Error> => {
    try {
      const userCredentials = await firebaseAuthService.signInWithPopup(googleProvider);
      await generateSessionCookie(userCredentials, isUserInDB);
      return userCredentials;
    } catch (error) {
      if (error.code !== 'auth/popup-closed-by-user') {
        ErrorModalContextActions.onOpenSpecificError({
          description: error.message,
        });
      }
      throw error;
    }
  },

  loginWithEmailAndPassword: async (
    email: string,
    password: string,
    isUserInDB: boolean = true
  ): Promise<firebase.auth.UserCredential | Error> => {
    await firebaseAuthService.setPersistence(firebase.auth.Auth.Persistence.NONE);
    const userCredentials = await firebaseAuthService.signInWithEmailAndPassword(email, password);
    await generateSessionCookie(userCredentials, isUserInDB);
    return userCredentials;
  },

  createAccountWithEmailAndPassword: async ({
    email,
    password,
    isUserInDB,
  }: AccountCreationType): Promise<firebase.auth.UserCredential | Error> => {
    try {
      const userCredentials = await firebaseAuthService.createUserWithEmailAndPassword(
        email,
        password
      );
      await generateSessionCookie(userCredentials, isUserInDB);
      return userCredentials;
    } catch (error) {
      // do not want to show popup if user already created account with the same email
      if (error.code !== 'auth/email-already-in-use') {
        ErrorModalContextActions.onOpenSpecificError({
          description: error.message,
        });
      }
      loggerService.error(error);
      throw error;
    }
  },

  logout: async (client): Promise<void> => {
    window.analytics.track('tenant_logged_out', {
      user_type: 'tenant',
      event_source: 'tenant_portal',
      env: getEnv(),
    });

    const [orchestrationUrl] = process.env.REACT_APP_ORCHESTRATION_URL?.split('/graphql') || [];
    const requestUrl = `${orchestrationUrl}/api/logout`;

    await fetch(requestUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    await firebaseAuthService.signOut();
    await client?.resetStore()?.catch(loggerService.error);
  },

  resetPassword: async (email: string): Promise<void> => {
    await firebaseAuthService.sendPasswordResetEmail(email);
  },

  updatePassword: async (currentPassword: string, password: string): Promise<void> => {
    const { email } = firebaseAuthService.currentUser;
    await this.loginWithEmailAndPassword(email, currentPassword, false);
    await firebaseAuthService.currentUser.updatePassword(password);
  },

  changePassword: async (actionCode: string, newPassword: string): Promise<void> => {
    await firebaseAuthService.verifyPasswordResetCode(actionCode);
    await firebaseAuthService.confirmPasswordReset(actionCode, newPassword);
  },

  identifyUserInDB: (user: Object) => {
    identifyUser(user);
  },
};
