// @flow
import React, { createContext, useContext, useState, useMemo } from 'react';
import { useDisclosure } from '@chakra-ui/react';

// Types
type ErrorModalDetails = {
  title?: string,
  description?: string,
};

type CriticalActionParams = {
  label: string,
  callback: Function,
};

type CriticalErrorModalDetails = {
  title?: string,
  description?: string,
  criticalActionParams?: CriticalActionParams,
};

// Constants
const initialErrorModalDetails: ErrorModalDetails = Object.freeze({
  title: 'Error',
  description: 'Please try again or contact support',
});

// Context
const ErrorModalContext = createContext();

// Public Actions
export const ErrorModalContextActions: {
  onOpenSpecificError: (details: ErrorModalDetails) => void,
  onOpenCriticalError: (details: CriticalErrorModalDetails) => void,
  onOpenGenericError: () => void,
  onIgnoreErrorModal: () => void,
} = {};

// Provider
export const ErrorModalProvider = ({ children }: { children: mixed }): ErrorModalContext => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorModalDetails, setErrorModalDetails] = useState<
    ErrorModalDetails | (CriticalErrorModalDetails & { isCritical: boolean }) | null
  >();

  const onOpenSpecificError = (details: ErrorModalDetails = {}): void => {
    setErrorModalDetails({
      ...initialErrorModalDetails,
      ...details,
    });
    onOpen();
  };

  const onOpenCriticalError = (details: CriticalErrorModalDetails = {}): void => {
    setErrorModalDetails({
      ...initialErrorModalDetails,
      ...details,
      isCritical: true,
    });
    onOpen();
  };

  const onOpenGenericError = (): void => {
    setErrorModalDetails(null);
    onOpen();
  };

  const onIgnoreErrorModal = (): void => {
    setErrorModalDetails(null);
    onClose();
  };

  ErrorModalContextActions.onOpenSpecificError = onOpenSpecificError;
  ErrorModalContextActions.onOpenCriticalError = onOpenCriticalError;
  ErrorModalContextActions.onOpenGenericError = onOpenGenericError;
  ErrorModalContextActions.onIgnoreErrorModal = onIgnoreErrorModal;

  const state = useMemo(() => {
    const newState = {};
    newState.errorModalDetails = errorModalDetails;
    newState.isOpen = isOpen;
    newState.onOpenSpecificError = onOpenSpecificError;
    newState.onOpenCriticalError = onOpenCriticalError;
    newState.onOpenGenericError = onOpenGenericError;
    newState.onClose = onClose;
    return newState;
  }, [
    errorModalDetails,
    isOpen,
    onOpenSpecificError,
    onOpenCriticalError,
    onOpenGenericError,
    onClose,
  ]);

  return <ErrorModalContext.Provider value={state}>{children}</ErrorModalContext.Provider>;
};

// Hook to simplify access to Error Modal from the context
export const useErrorModal = () => useContext(ErrorModalContext);
