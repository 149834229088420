import React from 'react';

import type { IconProps } from '@icons/types';

function IconStatusCompleted({ width, height }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#D9F5CA" />
      <path
        d="M12 7C10.6739 7 9.40215 7.52678 8.46447 8.46447C7.52678 9.40215 7 10.6739 7 12C7 12.6566 7.12933 13.3068 7.3806 13.9134C7.63188 14.52 8.00017 15.0712 8.46447 15.5355C8.92876 15.9998 9.47996 16.3681 10.0866 16.6194C10.6932 16.8707 11.3434 17 12 17C12.6566 17 13.3068 16.8707 13.9134 16.6194C14.52 16.3681 15.0712 15.9998 15.5355 15.5355C15.9998 15.0712 16.3681 14.52 16.6194 13.9134C16.8707 13.3068 17 12.6566 17 12C17 10.6739 16.4732 9.40215 15.5355 8.46447C14.5979 7.52678 13.3261 7 12 7ZM7.58058 7.58058C8.75268 6.40848 10.3424 5.75 12 5.75C13.6576 5.75 15.2473 6.40848 16.4194 7.58058C17.5915 8.75268 18.25 10.3424 18.25 12C18.25 12.8208 18.0883 13.6335 17.7742 14.3918C17.4602 15.1501 16.9998 15.8391 16.4194 16.4194C15.8391 16.9998 15.1501 17.4602 14.3918 17.7742C13.6335 18.0883 12.8208 18.25 12 18.25C11.1792 18.25 10.3665 18.0883 9.60823 17.7742C8.84994 17.4602 8.16095 16.9998 7.58058 16.4194C7.00022 15.8391 6.53984 15.1501 6.22575 14.3918C5.91166 13.6335 5.75 12.8208 5.75 12C5.75 10.3424 6.40848 8.75269 7.58058 7.58058ZM14.3169 10.3081C14.561 10.5521 14.561 10.9479 14.3169 11.1919L11.8169 13.6919C11.5729 13.936 11.1771 13.936 10.9331 13.6919L9.68306 12.4419C9.43898 12.1979 9.43898 11.8021 9.68306 11.5581C9.92714 11.314 10.3229 11.314 10.5669 11.5581L11.375 12.3661L13.4331 10.3081C13.6771 10.064 14.0729 10.064 14.3169 10.3081Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#398311"
      />
    </svg>
  );
}

IconStatusCompleted.defaultProps = {
  width: '24px',
  height: '24px',
};

export default IconStatusCompleted;
