const control = {
  borderColor: 'brand.darkBlue.300',
  borderWidth: '1px',
  borderRadius: '4px',
  minHeight: '16px',
  width: '16px',

  _focus: {
    boxShadow: 'none',
    outline: 'none',
  },
};

const label = {
  color: 'brand.neutral.700',
  lineHeight: '20px',
  fontWeight: 'normal',
};

const baseStyle = {
  control,
  label,
};

export const Checkbox = {
  baseStyle,
};
