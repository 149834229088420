import React, { useEffect, useState } from 'react';
import { publicIpv4 } from 'public-ip';
import { StatsigProvider } from 'statsig-react';

type StatsigWrapperProps = {
  tenant?: Object,
  children: React.Node,
};

const StatsigWrapper = ({ tenant, children }: StatsigWrapperProps) => {
  const statsigClientKey = process.env.REACT_APP_STATSIG_CLIENT_KEY;
  const [ipAddress, setIpAddress] = useState(null);

  const getIpAddress = async () => {
    const newIpAddress = await publicIpv4();
    setIpAddress(newIpAddress);
  };

  const statsigUserObject = {
    userAgent: navigator.userAgent,
    ip: ipAddress,
    role: 'tenant',
  };

  if (tenant?.id) {
    statsigUserObject.userID = tenant.id;
  }

  const getStatsigEnvironment = () => {
    switch (process.env.NODE_ENV) {
      case 'production':
        return 'production';
      case 'pre-prod':
        return 'Pre-Prod';
      case 'hotfix':
        return 'Pre-Prod';
      case 'uat':
        return 'UAT';
      default:
        return 'development';
    }
  };

  useEffect(() => {
    getIpAddress();
  }, []);

  return (
    <StatsigProvider
      sdkKey={statsigClientKey}
      waitForInitialization
      user={statsigUserObject}
      options={{
        environment: { tier: getStatsigEnvironment() },
      }}
    >
      {children}
    </StatsigProvider>
  );
};

StatsigWrapper.defaultProps = {
  tenant: null,
};

export default StatsigWrapper;
