import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Box,
  Text,
  MenuDivider,
} from '@chakra-ui/react';
import { IconArrowDropDown as Icon16ChevronDown, IconArrowDropUp as Icon16ChevronUp } from '@icons';
import { BaselaneButtonIcon } from '@shared/components';
import BaselaneMenuProfileButton from './BaselaneMenuProfileButton';
import BaselaneButton from '../BaselaneButton';
import { menuStyles } from './menu.styles';

type BaselaneMenuProps = {
  label: String,
  onClick: Function,
  listItems: Array<any>,
  customButton: String,
  buttonVariant: String,
  buttonPalette: String,
  buttonSize: String,
  buttonIcon: ReactComponent,
  placement: String,
  isProfile: Boolean,
  profilePhotoUrl: String,
  styles: Object,
};

function BaselaneMenu({
  label,
  onClick,
  listItems,
  customButton,
  buttonVariant = 'filled',
  buttonPalette = 'primary',
  buttonSize = 'md',
  buttonIcon = null,
  placement = 'bottom-start',
  isProfile = false,
  profilePhotoUrl = '',
  styles = {},
}: BaselaneMenuProps) {
  const { divider, list, item: itemStyle } = menuStyles ?? {};
  const isButtonIcon = buttonIcon !== null;
  const typeOfCustomButton =
    customButton === 'BaselaneButtonIcon' ? BaselaneButtonIcon : BaselaneButton;
  return (
    <Menu placement={placement} autoSelect={false} gutter={6}>
      {({ isOpen }) => (
        <>
          {isProfile ? (
            <BaselaneMenuProfileButton
              isOpen={isOpen}
              label={label}
              profilePhotoUrl={profilePhotoUrl}
            />
          ) : (
            <MenuButton
              isActive={isOpen}
              as={typeOfCustomButton}
              variant={buttonVariant}
              palette={buttonPalette}
              size={buttonSize}
              rightIcon={!isButtonIcon && (isOpen ? <Icon16ChevronUp /> : <Icon16ChevronDown />)}
              icon={buttonIcon}
              onClick={onClick}
              {...styles?.button}
            >
              {!isButtonIcon && label}
            </MenuButton>
          )}
          <MenuList {...{ ...list, ...styles?.list }}>
            {listItems?.map((item, index) => (
              <Box key={item?.id || index}>
                <MenuItem
                  {...itemStyle}
                  as={item?.as}
                  href={item?.href}
                  target={item?.target}
                  onClick={item?.onClick}
                >
                  <Stack direction="isHorizontal" gap="12px" alignItems="center">
                    {item?.icon && (
                      <Box w="16px" paddingRight="12px" padding="4px 12px 0 0">
                        {item?.icon}
                      </Box>
                    )}
                    <Box>
                      <Text textStyle="sm">{item.name}</Text>
                      {item?.description && (
                        <Text textStyle="xs" color="brand.neutral.600">
                          {item?.description}
                        </Text>
                      )}
                    </Box>
                  </Stack>
                </MenuItem>
                {item?.hasDivider && <MenuDivider {...divider} />}
              </Box>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
}

export default BaselaneMenu;
