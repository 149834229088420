// @flow
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, VStack, Text, Box, Flex } from '@chakra-ui/react';
import type { TenantLease } from '@core/apollo/types/tenant-lease.type';
import { useLease } from '@contexts/LeaseContext';
import type { LeasePaymentDetails } from '@core/apollo/types/lease-payment-details.type';
import BaselaneButton from '@shared/components/BaselaneButton';
import AddNewPaymentMethod from '@pages/PaymentMethods/components/AddNewPaymentMethod';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';
import { DASHBOARD } from '@routing/constants/route.constants';

import { drawerFooterStyles } from '@shared/components/BaselaneDrawer/style';
import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';

import { landlordEmailStyles, summaryDescriptionStyles } from './styles';

import MonthlyRentAndFees from './components/MonthlyRentAndFees';
import PropertyDetails from './components/PropertyDetails';
import DepositsAndOneTimePayments from './components/DepositsAndOneTimePayments';
import { useLeaseSummaryDrawer } from '../../contexts/LeaseSummaryDrawerContext';

// Component
const LeaseSummary = () => {
  const history = useHistory();
  const { onClose: onCloseLeaseSummary } = useLeaseSummaryDrawer();
  const { selectedLease } = useLease();
  const paymentMethodSetupRef = useRef();
  const isDesktopScreen = useIsLargerThanSmallScreen();

  const { startDate, endDate, leaseType, property, leasePaymentDetails, landlord } =
    (selectedLease ?? {}: TenantLease);
  const { rentAndFees, depositsHeld } = (leasePaymentDetails ?? {}: LeasePaymentDetails);
  const hasDepositsHeld = depositsHeld?.filter((item) => !!item.amount).length > 0 ?? false;

  const closePaymentSetup = () => {
    onCloseLeaseSummary();
    history.push(DASHBOARD);
  };

  return (
    <>
      <Container>
        <VStack spacing="40px" align="stretch" flex="1 0 auto">
          <Box {...summaryDescriptionStyles}>
            Review your property and payment details below. If you have any questions, reach out to{' '}
            <Text {...landlordEmailStyles}>
              <a href={`mailto:${landlord?.email}`}>{landlord?.email}</a>
            </Text>
          </Box>

          <VStack spacing="24px" align="stretch" flex="1 0 auto">
            <PropertyDetails
              startDate={startDate}
              leaseType={leaseType}
              endDate={endDate}
              propertyDetails={property}
            />
            <MonthlyRentAndFees
              rentAndFees={rentAndFees}
              lateFee={rentAndFees?.lateFee}
              dueDays={rentAndFees?.dueDays}
            />
            {hasDepositsHeld && <DepositsAndOneTimePayments depositHeld={depositsHeld} />}
          </VStack>
        </VStack>
      </Container>
      <Box {...drawerFooterStyles(isDesktopScreen)}>
        <Flex>
          <BaselaneDrawer
            ref={paymentMethodSetupRef}
            title="Payment Method Setup"
            closeEvent={closePaymentSetup}
            content={
              <AddNewPaymentMethod
                title="Please select your preferred payment method"
                close={() => paymentMethodSetupRef.current.close()}
              />
            }
          >
            <BaselaneButton variant="primary" size="lg" alignSelf="flex-start">
              Continue to Add Payment Method
            </BaselaneButton>
          </BaselaneDrawer>
        </Flex>
      </Box>
    </>
  );
};

export default LeaseSummary;
