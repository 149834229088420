import React from 'react';

type IconLockClosedProps = {
  w?: Number,
  h?: Number,
  color?: string,
};

function IconLockClosed({ w, h, color }: IconLockClosedProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.05372 1.88738C5.83512 1.10598 6.89493 0.666992 8 0.666992C9.10507 0.666992 10.1649 1.10598 10.9463 1.88738C11.7277 2.66878 12.1667 3.72859 12.1667 4.83366V7.33366H13C13.663 7.33366 14.2989 7.59705 14.7678 8.06589C15.2366 8.53473 15.5 9.17062 15.5 9.83366V14.8337C15.5 15.4967 15.2366 16.1326 14.7678 16.6014C14.2989 17.0703 13.663 17.3337 13 17.3337H3C2.33696 17.3337 1.70107 17.0703 1.23223 16.6014C0.763392 16.1326 0.5 15.4967 0.5 14.8337V9.83366C0.5 9.17062 0.763392 8.53473 1.23223 8.06589C1.70107 7.59705 2.33696 7.33366 3 7.33366H3.83333V4.83366C3.83333 3.72859 4.27232 2.66878 5.05372 1.88738ZM5.5 7.33366H10.5V4.83366C10.5 4.17062 10.2366 3.53473 9.76777 3.06589C9.29893 2.59705 8.66304 2.33366 8 2.33366C7.33696 2.33366 6.70107 2.59705 6.23223 3.06589C5.76339 3.53473 5.5 4.17062 5.5 4.83366V7.33366ZM3 9.00033C2.77899 9.00033 2.56702 9.08812 2.41074 9.2444C2.25446 9.40068 2.16667 9.61265 2.16667 9.83366V14.8337C2.16667 15.0547 2.25446 15.2666 2.41074 15.4229C2.56702 15.5792 2.77899 15.667 3 15.667H13C13.221 15.667 13.433 15.5792 13.5893 15.4229C13.7455 15.2666 13.8333 15.0547 13.8333 14.8337V9.83366C13.8333 9.61265 13.7455 9.40068 13.5893 9.2444C13.433 9.08812 13.221 9.00033 13 9.00033H3ZM8 10.667C8.46024 10.667 8.83333 11.0401 8.83333 11.5003V13.167C8.83333 13.6272 8.46024 14.0003 8 14.0003C7.53976 14.0003 7.16667 13.6272 7.16667 13.167V11.5003C7.16667 11.0401 7.53976 10.667 8 10.667Z"
        fill={color}
      />
    </svg>
  );
}

IconLockClosed.defaultProps = {
  w: 16,
  h: 18,
  color: 'currentColor',
};

export default IconLockClosed;
