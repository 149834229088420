// @flow
import React, { Component, useRef } from 'react';
import { Stack, Text } from '@chakra-ui/react';

import { useAuth } from '@core/contexts/AuthContext';
import BaselaneButtonCard from '@shared/components/BaselaneButtonCard';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';

import { getStripePublicKey } from '@core/apollo/services/stripe.apollo';
import { IconAddCard } from '@icons';
import { getFee } from '@core/utils/calculateConvenienceFee';
import AddDebitOrCreditCardStripeWrapper from './AddDebitOrCreditCardStripeWrapper';

// Props
type AddDebitOrCreditCardProps = {
  closeFlow: Function,
  noCache?: Boolean,
  onCardClick?: Function,
  onAddedBankAccount: Function,
  showAutoPayDrawer: Boolean,
};

// Component
function AddDebitOrCreditCard({
  closeFlow,
  noCache,
  onCardClick,
  onAddedBankAccount,
  showAutoPayDrawer,
}: AddDebitOrCreditCardProps): Component {
  const { tenant } = useAuth();
  const { paymentMethod } = tenant;

  const { data: stripe, loading } = getStripePublicKey();
  const StripeScreenDrawer = useRef();

  const closeDebitOrCreditCardInfoScreen = (): void => {
    StripeScreenDrawer.current.close();
  };

  const stripePublicKey = stripe?.stripePublicKey.publicKey;

  let contentElement = (
    <AddDebitOrCreditCardStripeWrapper
      close={closeDebitOrCreditCardInfoScreen}
      closeFlow={closeFlow}
      noCache={noCache}
      onAddedBankAccount={onAddedBankAccount}
      showAutoPayDrawer={showAutoPayDrawer}
      stripePublicKey={stripePublicKey}
    />
  );

  if (loading) {
    contentElement = <Text>Loading...</Text>;
  }

  if (!stripe) {
    contentElement = <Text>Stripe is not available at the moment.</Text>;
  }

  const convenienceFee = getFee(paymentMethod);

  return (
    <BaselaneDrawer
      ref={StripeScreenDrawer}
      title="Add New Payment Method"
      headerVariant="secondary"
      desktopSize="xl"
      content={contentElement}
    >
      <BaselaneButtonCard
        type="clean"
        id="payment-method-card"
        onClick={() => {
          StripeScreenDrawer?.current?.open();
          onCardClick();
        }}
      >
        <Stack isInline spacing="21px" alignItems="center">
          <IconAddCard />
          <Stack spacing={0}>
            <Text {...{ textTransform: 'uppercase', textStyle: 'headline-xs' }}>
              Debit Or Credit Card
            </Text>
            <Text
              className="hover-white"
              {...{
                textStyle: 'xs',
                color: 'brand.neutral.600',
              }}
            >
              {convenienceFee}% convenience fee added
            </Text>
          </Stack>
        </Stack>
      </BaselaneButtonCard>
    </BaselaneDrawer>
  );
}

AddDebitOrCreditCard.defaultProps = {
  noCache: false,
  onCardClick: () => {},
};

export default AddDebitOrCreditCard;
