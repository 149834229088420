import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { IconBankAccountProgress, IconBankAccountSuccess } from '@icons';
import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';

type PaymentAddedProps = {
  status: string,
};

const PaymentAdded = ({ status }: PaymentAddedProps) => {
  const isDesktopScreen = useIsLargerThanSmallScreen();

  const content = {
    CONNECTED: {
      icon: <IconBankAccountSuccess />,
      headerText: 'Payment method has been added',
      contentText: 'You can start scheduling payments at any time',
    },
    PENDING_AUTOMATIC_VERIFICATION: {
      icon: <IconBankAccountProgress />,
      headerText: 'Account verification in progress',
      contentText:
        'Automatic verification of your bank account is in progress and will complete in 1-2 business days. Check the verification status on your Payment Methods page.',
    },
    PENDING_MANUAL_VERIFICATION: {
      icon: <IconBankAccountProgress />,
      headerText: 'Account verification in progress',
      contentText:
        'You will receive a $0.01 deposit with a short verification code in the description in 1-2 business days. Once received, go to your Payment Methods page to enter the code to verify your account.',
    },
  };

  return (
    <Stack {...(isDesktopScreen ? { p: '52px 36px' } : { p: '36px 24px' })}>
      <Stack spacing="12px">
        {content[status]?.icon}
        <Stack spacing="8px">
          <Text
            {...{
              fontSize: isDesktopScreen ? 'xl' : 'lg',
              lineHeight: isDesktopScreen ? '32px' : '26px',
            }}
          >
            {content[status]?.headerText}
          </Text>
          <Text
            {...{
              textStyle: 'xs',
              color: 'brand.neutral.600',
            }}
          >
            {content[status]?.contentText}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PaymentAdded;
