export const titleContainerStyles = (isVerticalInfoBlock) => {
  const horizontalTitleContainerStyles = { alignItems: 'flex-start' };
  const verticalTitleContainerStyles = { flexDirection: 'column', alignItems: 'center' };
  return isVerticalInfoBlock ? verticalTitleContainerStyles : horizontalTitleContainerStyles;
};

export const iconContainerStyles = (isVerticalInfoBlock) => {
  const horizontalIconContainerStyles = { w: '32px', h: '32px' };
  const verticalIconContainerStyles = { w: '32px', h: '32px', my: '24px' };
  return isVerticalInfoBlock ? verticalIconContainerStyles : horizontalIconContainerStyles;
};

export const infoBlockVStackStyles = {
  spacing: '16px',
  padding: '0 8px',
};

export const mainTextStyles = {
  textStyle: 'headline-lg',
};

export const additionalTextStyles = {
  textStyle: 'sm',
};
