export const formLabelStyles = {
  textStyle: 'sm',
  pt: '0px',
  marginBottom: '4px',
  color: 'brand.neutral.700',
};

export const formInputStyles = {
  h: '48px',
  p: '12px 16px',
  color: 'brand.neutral.700',
  borderColor: 'brand.darkBlue.300',
  borderRadius: '4px',
  textStyle: 'sm',
  _invalid: {
    borderColor: 'red.800AA',
    boxShadow: 'none',
    _focus: {
      borderColor: 'red.800AA',
      color: 'brand.neutral.700',
      boxShadow: 'none',
    },
  },
  _focus: {
    borderColor: 'brand.darkBlue.250',
    color: 'brand.neutral.600',
    boxShadow: 'none',
  },
  _hover: { borderColor: 'brand.darkBlue.250', color: 'brand.neutral.600' },
  _disabled: { borderColor: 'brand.darkBlue.200', color: 'brand.neutral.500' },
  _placeholder: { color: 'brand.neutral.500' },
};

export const formErrorStyles = {
  textStyle: 'xs',
  ml: '0',
  color: 'red.800AA',
  fontWeight: 'normal',
  mt: '8px',
};
