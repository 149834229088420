import { gql } from '@apollo/client';

export const GET_TENANT_LEASES = gql`
  query {
    tenantLeases {
      id
      invoiceProcessingFeePaidBy
      rentAndFeesBankAccount {
        isExternal
      }
      invoice {
        id
        destinationBankAccountId
        destinationBankAccount {
          isExternal
        }
        state
        dueDate
        originalDueDate
        amount
        leaseId
        description
        name
        items {
          paymentName
          amount
          description
        }
        tenantPaymentMethod {
          id
          paymentType
          name
          number
          nickName
          status
        }
        isLate
        daysLate
        lateFeeAmount
        invoiceCharge {
          failedReason
          status
          paymentDate
          payoutStatus
          paymentTriggerType
          invoiceProcessingFeePaidBy
          metadata {
            applicationFeeWaivedChargedReason
          }
        }
        invoiceStateChangeDate
        invoiceMarkedPaid
      }
      leaseType
      startDate
      archived
      endDate
      cancellationNotice
      rentState
      latefee
      totalOverdueAmount
      tenantPaymentMethodId
      tenantAutoPayEnrolled
      tenantAutoPaymentMethod {
        id
        paymentType
        name
        number
        nickName
      }
      tenantPaymentDays
      property {
        id
        isMultiUnitProperty
        name
        address
        rentStatus
      }
      landlord {
        firstName
        lastName
        email
        phoneNumber
        achFeePromotion {
          id
          promotionId
          startDate
          endDate
          isExecuted
          userId
          promotion {
            id
            startDate
            endDate
            type
          }
        }
      }
      leasePaymentDetails {
        rentAndFees {
          lateFee {
            name
            description
            amount
            dueDays
          }
          rent {
            name
            description
            amount
            dueDays
          }
          fees {
            name
            description
            amount
            frequency
          }
          total
          dueDays
        }
        depositsHeld {
          name
          description
          amount
          status
        }
      }
    }
  }
`;
