const navItemsSpacingPx = '0px';

export const topNavMenuWrapperStyles = {
  width: '100%',
  align: 'stretch',
  background: 'brand.neutral.white',
  borderBottom: '1pt solid',
  borderBottomColor: 'brand.darkBlue.150',
};

export const topNavMenuHeaderStyles = (authenticated) => ({
  height: authenticated ? '64px' : '56px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  paddingLeft: authenticated ? '112px' : '32px',
  color: 'brand.darkBlue.900',
  textStyle: 'headline-xl',
});

export const sideNavMenuWrapperStyles = (isMobile, isMenuOpen) => ({
  height: '100%',
  align: 'stretch',
  background: 'brand.neutral.50',
  spacing: 0,
  paddingBottom: '24px',
  borderRight: '1pt solid',
  borderRightColor: 'brand.neutral.200',
  zIndex: '1200',
  position: 'absolute',
  display: isMobile && !isMenuOpen ? 'none' : 'inherit',
  width: isMobile ? '312px' : '260px',
  maxWidth: !isMenuOpen ? '80px' : '312px',
});

export const sideNavMenuHeaderStyles = {
  height: '80px',
  position: 'relative',
  paddingLeft: '24px',
  paddingTop: '16px',
};

export const sideNavMenuContentStyles = {
  margin: '12px 0',
  spacing: navItemsSpacingPx,
  flex: '1 0 auto',
  color: 'inherit',
};

export const sideNavMenuItemStyles = {
  justifyContent: 'center',
  width: '100%',
  color: 'inherit',
};
