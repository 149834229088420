// @flow
import React from 'react';
import { ButtonProps } from '@chakra-ui/react';

import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';

import SlidableButton from './SlidableButton';
import BasicButton from './BasicButton';

// Props
export type BaselaneButtonProps = ButtonProps & {
  isSlideToClick?: boolean,
};

// Component
const BaselaneButton = ({ isSlideToClick = false, ...rest }: BaselaneButtonProps) => {
  const isDesktopScreen = useIsLargerThanSmallScreen();

  if (isDesktopScreen) {
    return <BasicButton {...rest} />;
  }

  return isSlideToClick ? <SlidableButton {...rest} /> : <BasicButton {...rest} />;
};

export default BaselaneButton;
