// @flow
import React from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import { isUndefined } from 'lodash';
import { tooltipStyles } from './styles/tooltip.style';

type BaselaneTooltipProps = {
  children: any,
  label: string,
  noArrow?: boolean,
  placement: string,
  isOpen?: Boolean,
  styles?: Object,
};

function BaselaneTooltip({
  children,
  label,
  noArrow,
  placement,
  isOpen,
  styles,
}: BaselaneTooltipProps): any {
  const tooltipStyle = { ...tooltipStyles, ...styles };
  const tooltipPlacement = placement || 'bottom';

  const computedProperties = {};

  if (!isUndefined(isOpen)) {
    // Adding `isOpen` property conditionally.
    // When added, it puts tooltip in controlled mode.
    // (Controlled mode means it will open/close manually, not automatically!)
    computedProperties.isOpen = isOpen;
  }

  return (
    <Tooltip
      label={label}
      aria-label={label}
      hasArrow={!noArrow}
      placement={tooltipPlacement}
      {...computedProperties}
      {...tooltipStyle}
    >
      <Box {...{ ml: '-2px !important' }}>{children}</Box>
    </Tooltip>
  );
}

BaselaneTooltip.defaultProps = {
  noArrow: false,
  isOpen: undefined,
  styles: ({}: { ... }),
};

export default BaselaneTooltip;
