// @flow
import { OperationVariables, useMutation, useQuery } from '@apollo/client';
import { QueryResult, MutationResult, MutationTuple } from '@apollo/client/react/types/types';
import {
  ADD_TENANT_PAYMENT_METHOD,
  GET_TENANT,
  UPDATE_TENANT_PAYMENT_METHOD,
  DELETE_TENANT_PAYMENT_METHOD,
  UPDATE_TENANT,
  CREATE_TENANT,
} from '@core/apollo/requests/tenant.graphql';
import type { TenantPaymentMethodType, GetTenantType } from '@core/apollo/types/tenant.type';
import { GET_TENANT_LEASES } from '@core/apollo/requests/tenant-lease.graphql';

/**
 * Return Tenant Data
 * @return {QueryResult<GetTenantType, OperationVariables>}
 */
export function getTenant(): QueryResult<GetTenantType, OperationVariables> {
  return useQuery(GET_TENANT);
}

/**
 * Return Tenant Data
 * @return {QueryResult<GetTenantType, OperationVariables>}
 */
export function createTenant(): QueryResult<GetTenantType, OperationVariables> {
  return useMutation(CREATE_TENANT, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: GET_TENANT,
        data: { tenant: data.createTenant },
      });
    },
  });
}

/**
 * @deprecated since 27/01/2022
 */
export function updateTenant(): MutationTuple<TenantPaymentMethodType> {
  return useMutation(UPDATE_TENANT, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: GET_TENANT,
        data: { tenant: { ...data.updateTenant } },
      });
    },
  });
}

/**
 * Add New Payment Method
 * @return {QueryResult<TenantPaymentMethodType, OperationVariables>}
 */
export function addTenantPaymentMethod(): MutationResult<
  TenantPaymentMethodType,
  OperationVariables
> {
  return useMutation(ADD_TENANT_PAYMENT_METHOD, {
    update: (cache, { data }) => {
      const { tenant } = cache.readQuery({ query: GET_TENANT });
      cache.writeQuery({
        query: GET_TENANT,
        data: {
          tenant: {
            ...tenant,
            paymentMethod: [data.addTenantPaymentMethod, ...tenant.paymentMethod],
          },
        },
      });
    },
  });
}

/**
 * Add New Payment Method without updating Cache
 * Note: This is a workaround to prevent an unmount/remounting
 * of components in the onboarding flow payment method step
 * Ultimately it would be better to use the normal addTenantPaymentMethod()
 * if the UI bug can be resolved in another way.
 *
 * @return {QueryResult<TenantPaymentMethodType, OperationVariables>}
 */
export function addTenantPaymentMethodNoCache(): MutationResult<
  TenantPaymentMethodType,
  OperationVariables
> {
  return useMutation(ADD_TENANT_PAYMENT_METHOD);
}

/**
 * Update Payment Method
 * @return {QueryResult<TenantPaymentMethodType, OperationVariables>}
 */
export function updateTenantPaymentMethod(): MutationResult<
  TenantPaymentMethodType,
  OperationVariables
> {
  return useMutation(UPDATE_TENANT_PAYMENT_METHOD, {
    update: (cache, { data }) => {
      const { tenant } = cache.readQuery({ query: GET_TENANT });
      cache.writeQuery({
        query: GET_TENANT,
        data: {
          tenant: {
            ...tenant,
            paymentMethod: tenant.paymentMethod.map((item) =>
              +item.id === +data.updateTenantPaymentMethod.id
                ? data.updateTenantPaymentMethod
                : item
            ),
          },
        },
      });
    },
  });
}

/**
 * Update Payment Method
 * @return {QueryResult<TenantPaymentMethodType, OperationVariables>}
 */
export function updateTenantPaymentMethodNoCache(): MutationResult<
  TenantPaymentMethodType,
  OperationVariables
> {
  return useMutation(UPDATE_TENANT_PAYMENT_METHOD);
}

export function updatePaymentCacheInfo(cache, selectedLeaseId, data) {
  const { tenantLeases } = cache.readQuery({ query: GET_TENANT_LEASES });
  const updateTenantLeases = tenantLeases.map((tl) => {
    const isAutoPayPaymentMethodDeleted = tl.invoice.filter(
      (tli) => tli?.tenantPaymentMethod?.id === data?.deleteTenantPaymentMethod
    );

    if (tl.id === selectedLeaseId && isAutoPayPaymentMethodDeleted.length > 0) {
      return { ...tl, tenantAutoPayEnrolled: false };
    }

    return tl;
  });

  cache.writeQuery({
    query: GET_TENANT_LEASES,
    data: {
      tenantLeases: updateTenantLeases,
    },
  });
}

/**
 * Delete Payment Method
 * @return {QueryResult<string, OperationVariables>}
 */
export function deleteTenantPaymentMethod(): MutationResult<string, OperationVariables> {
  return useMutation(DELETE_TENANT_PAYMENT_METHOD);
}
