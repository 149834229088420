export const addPaymentMethodStripeStyles = {
  margin: 'auto 0',
  borderRadius: '4px',
  borderColor: '#CDD4E0',
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: '16px 8px',
  width: '100%',
};

export const addPaymentMethodStripeSubtitleStyles = {
  textStyle: 'headline-sm',
  pb: '0px',
  pt: '0px',
  color: 'brand.neutral.700',
  mt: '24px!important',
};

export const formLabelStyles = {
  fontSize: 'xs',
  lineHeight: '16px',
  fontWeight: 'normal',
  pt: '0px',
  marginBottom: '4px',
  color: 'brand.neutral.700',
};

export const formInputStyles = {
  h: '48px',
  p: '12px 16px',
  color: 'brand.neutral.700',
  borderColor: 'brand.darkBlue.300',
  borderRadius: '4px',
  fontSize: 'sm',
  lineHeight: '16px',
  fontWeight: 'normal',
  _invalid: {
    borderColor: 'red.800AA',
    boxShadow: 'none',
    _focus: {
      borderColor: 'red.800AA',
      color: 'brand.neutral.700',
      boxShadow: 'none',
    },
  },
  _focus: {
    borderColor: 'brand.darkBlue.250',
    color: 'brand.neutral.600',
    boxShadow: 'none',
  },
  _hover: { borderColor: 'brand.darkBlue.250', color: 'brand.neutral.600' },
  _disabled: { borderColor: 'brand.darkBlue.200', color: 'brand.neutral.500' },
  _placeholder: { color: 'brand.neutral.500' },
};

export const formItemStyles = {
  mb: '24px',
};

export const formContainerStyles = { mt: '22px!important' };

export const formErrorStyles = {
  textStyle: 'xs',
  ml: '0',
  color: 'red.800AA',
  mt: '8px',
};

export const xsFormLabelStyles = {
  // override default form label styles
  // TODO: should fix once we bring over landlord override styles for forms
  fontSize: 'xs',
  lineHeight: '16px',
  fontWeight: 100,

  color: 'brand.neutral.700',
  _invalid: { color: 'red.800AA' },
};

export const resetMarginTop = { mt: '0!important' };
