/* eslint-disable prefer-destructuring */
// 1. Import the extendTheme util - it will merge with the default theme
import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

import colors from './colors';
import breakpoints from './breakpoints';
import typography from './typography';
import textStyles from './text.style';
import styles from './styles';
import shadows from './shadows';

import {
  Button,
  Container,
  Form,
  FormLabel,
  FormError,
  Input,
  Label,
  Text,
  Switch,
  Progress,
  Checkbox,
  Table,
  Tooltip,
} from './components';

// 2. Extend the theme to include custom colors, fonts, etc.
const components = {
  Button,
  Label,
  Container,
  Text,
  Form,
  Input,
  FormLabel,
  FormError,
  Switch,
  Progress,
  Checkbox,
  Tooltip,
  Table,
};

const customTheme = extendTheme({
  styles,
  colors,
  components,
  ...typography,
  shadows,
  textStyles,
  breakpoints: createBreakpoints(breakpoints),
});

export default customTheme;
