// @flow
import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import type { Fee, LateFee, RentAndFeesType } from '@core/apollo/types/lease-payment-details.type';
import { calculateTotalFee } from '@core/utils/calculateTotalFee';
import BaselaneInfoRow from '@shared/components/BaselaneInfoRow';
import formatCurrency from '@core/utils/formatCurrency';
import formatTitleCase from '@core/utils/formatTitleCase';
import isEmpty from '@core/utils/validators/isEmpty';
import BaselaneDivider from '@shared/components/BaselaneDivider';
import formatDueDaysText from '@core/utils/formatDueDaysText';
import formatMonthlyRentAndFeeText from '@core/utils/formatMonthlyRentAndFeeText';

import { sectionTitleStyles } from '../../styles';

// Props
type MonthlyRentAndFeesProps = {
  rentAndFees: RentAndFeesType,
  lateFee: LateFee,
  dueDays: number,
};

// Component
const MonthlyRentAndFees = ({ rentAndFees, lateFee, dueDays }: MonthlyRentAndFeesProps) => {
  const { rent = [], fees = [] } = (rentAndFees ?? {}: RentAndFeesType);

  const totalFeeAmount = calculateTotalFee([rent, ...fees]);

  return (
    <Box>
      <Text {...sectionTitleStyles}>Monthly Rent & Fees</Text>
      <BaselaneInfoRow
        label="Monthly Rent"
        value={rent?.amount && formatCurrency(rent.amount).inDollars}
      />
      {!isEmpty(fees) &&
        fees.map((fee: Fee) => {
          const { name: feeName, amount: feeAmount, description: feeDescription } = fee;
          return (
            fee && (
              <BaselaneInfoRow
                key={`${feeName}${feeAmount}${feeDescription}`}
                label={feeDescription || formatTitleCase(feeName) || ''}
                value={feeAmount && formatCurrency(feeAmount).inDollars}
              />
            )
          );
        })}
      <BaselaneDivider m="6px 0" />
      <BaselaneInfoRow label="Total" value={formatCurrency(totalFeeAmount).inDollars} />
      <BaselaneInfoRow
        label="Due Date for Charges"
        value={dueDays && formatMonthlyRentAndFeeText(dueDays)}
      />
      {lateFee?.amount && lateFee?.dueDays && (
        <BaselaneInfoRow
          label="Late Fees"
          value={`${formatCurrency(lateFee.amount).inDollars} when rent is more
than ${formatDueDaysText(lateFee.dueDays)} late`}
        />
      )}
    </Box>
  );
};

export default MonthlyRentAndFees;
