// @flow
import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { useMediaQuery } from '@core/utils/useMediaQuery';
import BaselaneButton from '../BaselaneButton';
import { modalContentStyles, modalHeaderStyles, modalBodyStyles } from './styles/modal.style';

type BaselaneModalProps = {
  defaultOpen: boolean,
  btnText: string,
  btnType?: string,
  btnSize?: string,
  btnStyles?: Object,
  btnClick?: Function,
  onCloseBtnClick?: Function,
  modalContentStyles?: Object,
  modalBodyStyles?: Object,
  closeOnOverlayClick: boolean,
  showCloseButton: boolean,
  showHeader: boolean,
  showFooter: boolean,
  modalTitle: string,
  children?: Object,
  shouldBeClosed?: boolean,
  shouldBeOpened?: boolean,
  hasNoOverlay?: boolean,
  isCentered?: boolean,
  size?: string,
};

function BaselaneModal({
  defaultOpen,
  btnText,
  btnType,
  btnSize,
  btnStyles,
  btnClick,
  onCloseBtnClick,
  modalContentStyles: mContentStyles,
  modalBodyStyles: mBodyStyles,
  closeOnOverlayClick,
  showCloseButton,
  showHeader,
  showFooter,
  modalTitle,
  children,
  shouldBeClosed,
  shouldBeOpened,
  hasNoOverlay,
  isCentered,
  size,
}: BaselaneModalProps): any {
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: defaultOpen || shouldBeClosed,
  });

  const [isLandscape] = useMediaQuery(
    '(max-width: 824px) and (max-height: 541px) and (orientation: landscape)'
  );

  const buttonClick = () => {
    if (btnClick !== null) {
      onOpen();
      btnClick();
    } else {
      onOpen();
    }
  };

  useEffect(() => {
    if (shouldBeClosed) {
      onClose();
    }
  }, [shouldBeClosed]);

  useEffect(() => {
    if (shouldBeOpened) {
      onOpen();
    }
  }, [shouldBeOpened]);
  const handleClose = () => {
    onCloseBtnClick();
    onClose();
  };
  return (
    <>
      {!defaultOpen && (
        <BaselaneButton variant={btnType} size={btnSize} styles={btnStyles} onClick={buttonClick}>
          {btnText || 'Open'}
        </BaselaneButton>
      )}

      <Modal
        {...{ isOpen, isCentered }}
        onClose={handleClose}
        closeOnOverlayClick={closeOnOverlayClick}
        size={size}
      >
        {!hasNoOverlay && <ModalOverlay />}

        <ModalContent {...{ ...modalContentStyles(isLandscape), ...mContentStyles }}>
          {showHeader && <ModalHeader {...modalHeaderStyles}>{modalTitle || ''}</ModalHeader>}
          {showCloseButton && <ModalCloseButton color="brand.blue.700" />}

          <ModalBody {...modalBodyStyles} {...mBodyStyles}>
            {children || null}
          </ModalBody>

          {showFooter && (
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleClose}>
                Close
              </Button>
              <Button variant="ghost">Secondary Action</Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

BaselaneModal.defaultProps = {
  children: null,
  shouldBeClosed: false,
  shouldBeOpened: false,
  hasNoOverlay: false,
  btnSize: 'md',
  btnType: 'primary',
  btnStyles: ({}: { ... }),
  modalContentStyles: ({}: { ... }),
  modalBodyStyles: ({}: { ... }),
  btnClick: null,
  isCentered: true,
  size: 'xl',
  onCloseBtnClick: () => {},
};

export default BaselaneModal;
