// @flow
import React from 'react';

import { DrawerCloseButton, Box, Text } from '@chakra-ui/react';

import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';
import type { DrawerHeaderProps } from '../types';

import {
  drawerPrimaryCloseButtonStyles,
  primaryDrawerHeaderStyles,
  drawerHeaderTitleStyles,
} from './style';

// Component
const PrimaryDrawerHeader = ({ title = '' }: DrawerHeaderProps) => {
  const isDesktopScreen = useIsLargerThanSmallScreen();

  return (
    <Box {...primaryDrawerHeaderStyles(isDesktopScreen)}>
      <Text {...drawerHeaderTitleStyles(isDesktopScreen)}>{title}</Text>
      <DrawerCloseButton size="md" {...drawerPrimaryCloseButtonStyles(isDesktopScreen)} />
    </Box>
  );
};

export default PrimaryDrawerHeader;
