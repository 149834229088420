/* eslint-disable import/no-named-as-default-member */
// copied over from landlord but maybe tenant portal has different eslint rules
import React, { useState } from 'react';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';

import { formatPhoneNumber } from '@core/utils/formatPhoneNumber';
import { IconCheck, IconExclamationCircle } from '@icons';
import customTheme from '@theme/theme';

import BaselaneAlert from '../BaselaneAlert';
import BaselaneButton from '../BaselaneButton';
import SingleInputComponent from './SingleInputComponent';
import { errorMessages } from './helpers/constant';
import { titleStyles } from '../styles/popup.styles';
import { footerContentSectionStyles, popupFooterContainerStyles } from './styles/twofactor.styles';

type TwoFactorVerificationPopUpProps = {
  OTPErrorCode: any,
  setOTPErrorCode: Function,
  setHasOTPCodeResent: Function,
  isOTPPopupOpen: boolean,
  isGetOTPSuccessful: boolean,
  onOTPPopupClose: Function,
  getOTP: Function,
  handleVerifyOnClick: Function,
  phoneNumber: string,
  bankId?: String,
  isVerifyLoading: boolean,
};

const TwoFactorVerificationPopUp = ({
  OTPErrorCode,
  setOTPErrorCode,
  setHasOTPCodeResent,
  isOTPPopupOpen,
  isGetOTPSuccessful,
  onOTPPopupClose,
  getOTP,
  handleVerifyOnClick,
  bankId,
  phoneNumber,
  isVerifyLoading,
}: TwoFactorVerificationPopUpProps) => {
  const [otpCode, setOtpCode] = useState('');
  const formattedPhone =
    formatPhoneNumber(phoneNumber) === null ? phoneNumber : formatPhoneNumber(phoneNumber);

  const errorFormFontStyles = {
    color: 'red.500A',
    textStyle: 'xs',
  };

  const popupContent = (
    <Stack spacing={1}>
      <Box>
        <Text {...titleStyles}>Mobile Phone Verification</Text>
        <Text>
          Enter the code sent to{' '}
          <Box as="span" fontWeight="medium">
            {formattedPhone}
          </Box>
          .
        </Text>
        <SingleInputComponent
          onChangeEvent={(code) => {
            if (OTPErrorCode) setOTPErrorCode(false);
            setOtpCode(code);
          }}
        />
      </Box>
      {OTPErrorCode && (
        <HStack>
          <IconExclamationCircle w="13" h="13" color={customTheme.colors.red['800AA']} />
          <Text {...errorFormFontStyles}>{errorMessages[OTPErrorCode]}</Text>
        </HStack>
      )}
    </Stack>
  );

  const onPopUpClose = () => {
    if (OTPErrorCode) setOTPErrorCode(false);
    onOTPPopupClose();
  };

  const popupFooter = (
    <Stack isInline {...popupFooterContainerStyles}>
      <Stack isInline {...footerContentSectionStyles}>
        <BaselaneButton
          variant="link"
          size="lg"
          onClick={() => {
            // unit otp call
            if (bankId) {
              getOTP({ variables: { bankId: Number(bankId) } })
                .then(({ data }) => {
                  if (data.unitAPIVerification) {
                    setHasOTPCodeResent(true);
                  }
                })
                .catch(() => setOTPErrorCode(200));
            } else {
              // other otp function call
              getOTP();
            }
          }}
        >
          Resend Code
        </BaselaneButton>
        {isGetOTPSuccessful && <IconCheck color={customTheme.colors.green['800AA']} />}
      </Stack>
      <Stack isInline spacing={2} {...footerContentSectionStyles}>
        <BaselaneButton variant="secondary" size="lg" onClick={onPopUpClose}>
          Cancel
        </BaselaneButton>
        <BaselaneButton
          isDisabled={otpCode === undefined || otpCode?.length < 6}
          isLoading={isVerifyLoading}
          variant="primary"
          size="lg"
          onClick={() => handleVerifyOnClick(otpCode)}
        >
          Verify
        </BaselaneButton>
      </Stack>
    </Stack>
  );

  return (
    <BaselaneAlert
      isOpen={isOTPPopupOpen}
      onClose={onPopUpClose}
      isCentered
      body={popupContent}
      footer={popupFooter}
      size="xl"
      showCloseButton
      containerStyles={{
        w: isMobile ? 'calc(100% - 32px)' : '36rem',
        p: isMobile && '0!important',
        m: isMobile && '0!important',
      }}
      bodyStyles={{ p: isMobile && '0 16px 24px!important', m: isMobile && '0!important' }}
      footerStyles={{ p: isMobile && '8px 16px!important', m: isMobile ? '0 0 24px' : '8px 0' }}
    />
  );
};

TwoFactorVerificationPopUp.defaultProps = {
  bankId: null,
};

export default TwoFactorVerificationPopUp;
