import React from 'react';

const IconAddCard = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="29" cy="29" r="8" fill="#8CCFFF" />
      <path d="M27 24V30M30 27H24" stroke="#000619" strokeLinecap="round" />
      <circle cx="27" cy="27" r="8" stroke="#000619" strokeLinecap="round" />
      <path
        d="M35 9.36842V5C35 3.89543 34.1046 3 33 3H5C3.89543 3 3 3.89543 3 5V9.36842M35 9.36842H3M35 9.36842V19.5M3 9.36842V23C3 24.1046 3.89543 25 5 25H17.4"
        stroke="#000619"
        strokeLinecap="round"
      />
      <path
        d="M10.5 15.4013C10.0587 15.1461 9.54643 15 9 15C7.34315 15 6 16.3431 6 18C6 19.6569 7.34315 21 9 21C9.50523 21 9.9813 20.8751 10.399 20.6545"
        stroke="#000619"
      />
      <circle cx="12" cy="18" r="3" stroke="#000619" />
    </svg>
  );
};

export default IconAddCard;
