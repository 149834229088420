export const Container = {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    padding: '24px',
  },
  variants: {
    sm: {
      padding: '16px 16px 24px',
    },
    md: {
      padding: '24px',
    },
    lg: {
      padding: '20px 32px',
    },
  },
};
