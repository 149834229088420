import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation {
    createUser {
      id
      firstName
      lastName
      email
    }
  }
`;

export const GET_USER = gql`
  query {
    user {
      id
      firstName
      lastName
      email
      phoneNumber
    }
  }
`;

export const GET_TENANT = gql`
  query {
    tenant {
      id
      uid
      firstName
      lastName
      lastName
      email
      createdAt
      role
      paymentMethod {
        id
        paymentType
        name
        number
        nickName
      }
    }
  }
`;

export const SEND_OTP_REGISTERED_USER = gql`
  mutation SendOtpRegisteredUser($channel: String!) {
    sendOtpRegisteredUser(channel: $channel) {
      sendCodeAttempts
      status
    }
  }
`;

export const VERIFY_OTP_REGISTERED_USER = gql`
  mutation VerifyOtpRegisteredUser($channel: String!, $code: String!) {
    verifyOtpRegisteredUser(channel: $channel, code: $code)
  }
`;
