import React from 'react';

import type { IconProps } from '@icons/types';

function IconVirtualAssistant({ color, width, height }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 4.5H10.5C11.6046 4.5 12.5 5.39543 12.5 6.5C12.5 7.60457 11.6046 8.5 10.5 8.5H5.5C4.39543 8.5 3.5 7.60457 3.5 6.5C3.5 5.39543 4.39543 4.5 5.5 4.5ZM2 6.5C2 4.567 3.567 3 5.5 3H10.5C12.433 3 14 4.567 14 6.5C14 8.433 12.433 10 10.5 10H5.5C3.567 10 2 8.433 2 6.5ZM4 12.5H12C12.2761 12.5 12.5 12.7239 12.5 13V15.5H3.5V13C3.5 12.7239 3.72386 12.5 4 12.5ZM2 13C2 11.8954 2.89543 11 4 11H12C13.1046 11 14 11.8954 14 13V15.5V17H12.5H3.5H2V15.5V13ZM4.75 6.5C4.75 6.08579 5.08579 5.75 5.5 5.75C5.91421 5.75 6.25 6.08579 6.25 6.5C6.25 6.91421 5.91421 7.25 5.5 7.25C5.08579 7.25 4.75 6.91421 4.75 6.5ZM10.5 5.75C10.0858 5.75 9.75 6.08579 9.75 6.5C9.75 6.91421 10.0858 7.25 10.5 7.25C10.9142 7.25 11.25 6.91421 11.25 6.5C11.25 6.08579 10.9142 5.75 10.5 5.75Z"
        fill={color}
      />
    </svg>
  );
}

IconVirtualAssistant.defaultProps = {
  color: '#192C3E',
  width: '16px',
  height: '20px',
};

export default IconVirtualAssistant;
