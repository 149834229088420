// @flow
import { gql } from '@apollo/client';

export const GET_TENANT_PLAID_LINK_TOKEN = gql`
  query {
    tenantPlaidLinkToken
  }
`;

export const UPDATE_PLAID_LINK_TOKEN = gql`
  query updatePlaidLinkToken($plaidInstitutionId: ID!) {
    updatePlaidLinkToken(plaidInstitutionId: $plaidInstitutionId)
  }
`;

export const PAYMENT_METHOD_PLAID_LINK_TOKEN = gql`
  query paymentMethodPlaidLinkToken($paymentMethodId: ID) {
    paymentMethodPlaidLinkToken(paymentMethodId: $paymentMethodId)
  }
`;
