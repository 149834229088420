export const tooltipContainerStyles = (isDesktop: boolean = false) => ({
  width: isDesktop ? '636px' : '90vw',
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  backgroundColor: 'brand.neutral.white',
  borderRadius: '5px',
});

export const tooltipContentStyles = {
  position: 'relative',
  flex: '1 0 auto',
  align: 'stretch',
  paddingRight: '32px',
};

export const tooltipCloseButtonStyles = {
  position: 'absolute',
  top: 0,
  right: 0,
  color: 'brand.neutral.500',
};

export const tooltipFooterStyles = {
  marginTop: '26px',
  alignSelf: 'flex-end',
};
