// @flow
import { useQuery, OperationVariables } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { GET_TENANT_LEASES } from '@core/apollo/requests/tenant-lease.graphql';
import type { TenantLease } from '@core/apollo/types/tenant-lease.type';

/**
 * Return Tenant Leases Data
 * @return {QueryResult<{tenantLeases: TenantLease[]}, OperationVariables>}
 */
export function getTenantLeases(): QueryResult<
  { tenantLeases: TenantLease[] },
  OperationVariables
> {
  return useQuery(GET_TENANT_LEASES, { notifyOnNetworkStatusChange: true });
}
