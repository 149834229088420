// @flow
import { useMediaQuery } from '@core/utils/useMediaQuery';
import breakpoints from '@theme/breakpoints';

const useIsLargerThanSmallScreen = (): boolean => {
  const [isLargerThanSmallScreen] = useMediaQuery(`(min-width: ${breakpoints.lg})`);

  return isLargerThanSmallScreen;
};

export default useIsLargerThanSmallScreen;
