import React from 'react';
import ReactDOM from 'react-dom';

/* eslint-disable prettier/prettier,import/first */
import { init as initApm } from '@elastic/apm-rum';

// eslint-disable-next-line import/order
import { initializeDatadogLogs } from '@core/datadog/datadogConfig';

// eslint-disable-next-line no-unused-vars
let apm;

if (process.env.REACT_APP_DATADOG_ENABLED !== 'true') {
  // eslint-disable-next-line no-unused-vars
  apm = initApm({
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: 'tenant-portal-client',

    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: process.env.REACT_APP_APM_SERVER_URL,

    // Set service version (required for sourcemap feature)
    serviceVersion: process.env.REACT_APP_APM_SERVICE_VERSION || 'default',

    environment: process.env.REACT_APP_NODE_ENV,

    // Set tracing through URLs
    distributedTracingOrigins: [
      `GET ${process.env.REACT_APP_ORCHESTRATION_URL}`,
      `POST ${process.env.REACT_APP_ORCHESTRATION_URL}`,
      process.env.REACT_APP_ORCHESTRATION_URL,
      process.env.REACT_APP_ORCHESTRATION_URL.replace('/graphql', ''),
      'https://develop-orchestration.baselane.com',
      'http://localhost:4500/graphql',
      'http://localhost:4500',
    ],
  });
} else {
  initializeDatadogLogs();
}

import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider, CSSReset, GlobalStyle } from '@chakra-ui/react';
import apolloClient from '@core/apollo/apolloClient';
import customTheme from '@theme/theme';
import { ErrorModalProvider } from '@core/contexts/ErrorModalContext';
import ErrorBoundary from '@core/ErrorBoundary';
import { NavMenuProvider } from '@core/contexts/NavMenuContext';
import App from './app/App';
import { ProductTourProvider } from './app/modules/product-tour/contexts/ProductTourContext';
import { LeaseSummaryDrawerProvider } from './app/modules/product-tour/contexts/LeaseSummaryDrawerContext';
import fonts from './app/styles/fonts';
import ProductTour from './app/modules/product-tour/components/ProductTour';
import * as serviceWorker from './serviceWorker';
import './app/styles/fonts/fonts.scss';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <ErrorModalProvider>
            <ChakraProvider theme={customTheme}>
              <CSSReset />
              <GlobalStyle styles={fonts} />
              <NavMenuProvider>
                <ProductTourProvider>
                  <LeaseSummaryDrawerProvider>
                    <ProductTour />
                    <App />
                  </LeaseSummaryDrawerProvider>
                </ProductTourProvider>
              </NavMenuProvider>
            </ChakraProvider>
          </ErrorModalProvider>
        </BrowserRouter>
      </ApolloProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
