const drawerHeaderStyles = (isDesktop = false) => ({
  display: 'flex',
  alignItems: 'center',
  height: isDesktop ? '63px' : '55px',
  width: '100%',
});

export const drawerHeaderIconStyles = (isDesktop = false) => ({
  position: 'unset',
  display: 'inline-block',
  color: 'brand.darkBlue.800A',
});

export const primaryDrawerHeaderStyles = (isDesktop = false) => ({
  ...drawerHeaderStyles(isDesktop),
  backgroundColor: isDesktop ? 'brand.neutral.white' : 'transparent',
});

export const secondaryDrawerHeaderStyles = (isDesktop = false) => ({
  ...drawerHeaderStyles(isDesktop),
  backgroundColor: 'brand.neutral.white',
});

export const drawerHeaderTitleStyles = (isDesktop = false) => ({
  fontSize: 'lg',
  lineHeight: '24px',
  fontWeight: 'medium',
  textTransform: 'capitalize',
});

export const drawerPrimaryCloseButtonStyles = (isDesktop = false) => ({
  ...drawerHeaderIconStyles(isDesktop),
  marginLeft: 'auto',
});

export const drawerSecondaryCloseButtonStyles = (isDesktop = false) => ({
  ...drawerHeaderIconStyles(isDesktop),
  paddingRight: '12px',
  paddingLeft: '0',
});
