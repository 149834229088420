// @flow
import React from 'react';

import type { IconProps } from '@icons/types';

function IconProperties({ color, width, height, margin }: IconProps) {
  return (
    <svg
      style={{ margin }}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 21h4m5 0V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16h14zm0 0h2-2zm0 0h-5 5zM5 21H3h2zm0 0h5-5zM9 7h1-1zm0 4h1-1zm5-4h1-1zm0 4h1-1zm-4 10v-5a1 1 0 011-1h2a1 1 0 011 1v5h-4z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IconProperties.defaultProps = {
  color: '#6C7884',
  width: '24px',
  height: '24px',
  margin: '0',
};

export default IconProperties;
