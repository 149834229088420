// @flow
import React from 'react';
import { Flex, Text, ChakraComponent } from '@chakra-ui/react';

function BaselaneLabel({
  children,
  leftIcon: LeftIcon,
  className,
  textStyles,
  ...styles
}: ChakraComponent): any {
  return (
    <Flex className={className} {...styles} alignItems="center" justifyContent="center">
      {LeftIcon}
      <Text {...textStyles} whiteSpace="nowrap">
        {children}
      </Text>
    </Flex>
  );
}

export default BaselaneLabel;
