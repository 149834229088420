// @flow
import React, { forwardRef } from 'react';
import type { ElementRef } from 'react';

import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  InputProps,
  Text,
} from '@chakra-ui/react';

import IconCaret from '@icons/IconCaret';
import IconExclamationCircle from '@icons/IconExclamationCircle';
import { body } from '@theme/text.style';

// Props Interface
type ClickableSelectProps = InputProps & {
  id: string,
  label?: string,
  placeholder?: string,
  value?: string,
  additionalText?: string,
  onClick?: () => void,
  error?: string,
};

/**
 *  Clickable Select Component
 *
 *  Behaves like a button, because it's readonly and clickable
 *  Intended for a case when we need to select values from another screen/drawer/modal/etc
 *
 *  E.x: click on ClickableSelect -> open drawer with options to select
 *  -> select and return value -> set value to ClickableSelect
 */
const BaselaneClickableSelect = (
  {
    id,
    label,
    placeholder,
    additionalText = '',
    value = '',
    onClick = () => {},
    isDisabled,
    error = null,
  }: ClickableSelectProps,
  ref: ElementRef<'input'>
) => {
  return (
    <FormControl id={id} isInvalid={error}>
      <FormLabel>{label}</FormLabel>
      <InputGroup onClick={onClick} alignItems="center">
        <Input
          {...body.sm}
          disabled={isDisabled}
          height={additionalText ? '60px' : '40px'}
          ref={ref}
          color="brand.neutral.700"
          cursor="pointer"
          type="text"
          placeholder={placeholder}
          value={value}
          isReadOnly
          paddingBottom={additionalText && '20px'}
        />
        {additionalText && (
          <Text
            textStyle="xs"
            color="brand.neutral.600"
            position="absolute"
            bottom="8px"
            left="17px"
          >
            {additionalText}
          </Text>
        )}
        <InputRightElement color="brand.neutral.500" height="100%">
          <IconCaret direction="right" />
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>
        <HStack>
          <Box width="16px" height="16px">
            <IconExclamationCircle w={16} h={16} />
          </Box>
          <Box>{error}</Box>
        </HStack>
      </FormErrorMessage>
    </FormControl>
  );
};

export default forwardRef(BaselaneClickableSelect);
