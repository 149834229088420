// @flow
import firebase from 'firebase/app';
import 'firebase/auth';

import { getFirebaseConfig } from './auth.helper';
import { authConfig } from './auth.config';

firebase.initializeApp(getFirebaseConfig(authConfig.firebaseWebKey));

export const firebaseAuthService = firebase.auth();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const googleProvider = new firebase.auth.GoogleAuthProvider().setCustomParameters({
  prompt: 'select_account',
});
