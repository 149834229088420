export const tourHeaderIconStyles = {
  display: 'block',
  color: 'brand.blue.700',
  marginBottom: '8px',
};

export const tourHeaderTitleStyles = {
  lineHeight: '26px',
  marginBottom: '4px',
};
