// @flow
import React, { Node } from 'react';

import { Table, Thead, Tr, Th, Tbody } from '@chakra-ui/react';
import { v4 as generateKey } from 'uuid';

// Props
type BaselaneTableProps = {
  headerLabels: string[],
  children: Node,
};

// Component
const BaselaneTable = ({ headerLabels, children }: BaselaneTableProps): Node => {
  return (
    <Table variant="unstyled">
      <Thead>
        <Tr>
          {headerLabels.map((label) => (
            <Th key={generateKey()}>{label}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>{children}</Tbody>
    </Table>
  );
};

export default BaselaneTable;
