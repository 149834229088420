export const addPaymentMethodContainerStyles = {
  w: '100%',
  alignItems: 'flex-start',
};

export const addPaymentMethodTitleStyles = (isDesktopScreen) => ({
  fontSize: isDesktopScreen ? 'lg' : 'sm',
  lineHeight: isDesktopScreen ? '26px' : '24px',
});

export const addPaymentMethodSubtitleStyles = (isDesktopScreen) => ({
  color: 'brand.neutral.600',
  textStyle: 'sm',
});

export const errorAddPaymentMethodTextStyle = {
  paddingTop: '5px;',
  paddingBottom: '5px',
  align: 'center',
  spacing: '5px;',
};
