// @flow
import React from 'react';
import { Flex } from '@chakra-ui/react';
import { baseStyles } from './card.style';

type Props = {
  children?: Object,
  styles?: Object,
};
function BaselaneCard({ children, styles }: Props): any {
  const containerStyles = {
    ...baseStyles,
    ...styles,
  };

  return <Flex {...containerStyles}>{children}</Flex>;
}

BaselaneCard.defaultProps = {
  children: null,
  styles: null,
};

export default BaselaneCard;
