// NOTE: this file is being imported in theme.js to override Chakra button UI
export const baseStyle = {
  fontWeight: 'normal',
  borderRadius: '4px', // TODO: Apply borderRadius: 6px when applying button styles from updated design system
  p: '10px 24px',
  alignSelf: 'center',
  _disabled: {
    opacity: 1,
    cursor: 'default',
  },
  _focus: {
    boxShadow: 'none',
  },
  _active: {
    boxShadow: 'none',
  },
};

export const primaryButton = {
  bg: 'brand.darkBlue.800A',
  color: 'brand.neutral.white',
  minWidth: 'auto',
  _hover: {
    bg: 'brand.darkBlue.700',
  },
  _focus: {
    outlineColor: 'transparent',
  },
  _focusVisible: {
    outlineColor: 'brand.blue.800A',
  },
  _active: {
    bg: 'brand.darkBlue.900',
  },
  _disabled: {
    bg: '#E7E9EB !important',
    color: '#9AA3AB !important',
    _hover: {
      bg: '#E7E9EB !important',
      color: '#9AA3AB !important',
    },
  },
};

const criticalPrimaryButton = {
  borderRadius: '6px',
  borderWidth: '1px',
  bg: 'red.500A',
  color: 'brand.neutral.white',
  borderColor: 'red.500A',
  minWidth: 'auto',
  _hover: {
    bg: 'red.700',
    borderColor: 'red.700',
  },
  _focus: {
    borderColor: 'red.250',
  },
  _active: {
    bg: 'red.800AA',
    borderColor: 'red.800AA',
  },
  _disabled: {
    bg: 'red.200',
    borderColor: 'red.200',

    _hover: {
      bg: 'red.200',
      borderColor: 'red.200',
    },
  },
};

export const primaryLightButton = {
  bg: 'brand.blue.100',
  color: 'brand.blue.800A',
  borderWidth: '1px',
  borderColor: 'brand.blue.100',
  minWidth: 'auto',
  _hover: {
    borderColor: 'brand.blue.250',
    bg: 'brand.blue.100',
  },
  _disabled: {
    borderColor: '#C8D0DA !important',
    bg: '#C8D0DA !important',
  },
  _focus: {
    boxShadow: '0 0 0 2px #0968BF',
  },
  _active: {
    borderColor: 'brand.blue.800A',
  },
};

const secondaryButton = {
  borderColor: 'brand.neutral.500',
  borderWidth: '1px',
  bg: 'brand.neutral.white',
  color: 'brand.neutral.600',
  minWidth: 'auto',
  _hover: {
    borderColor: 'brand.neutral.600',
    color: 'brand.neutral.600',
  },
  _disabled: {
    borderColor: '#F0F2F6 !important',
    color: '#C8D0DA !important',
  },
  _focus: {
    boxShadow: '0 0 0 2px #C8D0DA',
  },
  _active: {
    color: 'brand.neutral.900',
  },
};

const tertiaryButton = {
  bg: 'red.800AA',
  color: 'brand.neutral.white',
  minWidth: 'auto',

  _hover: {
    bg: 'red.700',
  },
  _disabled: {
    color: '#9AA3AB !important',
    bg: '#E7E9EB !important',

    _hover: {
      color: '#9AA3AB !important',
      bg: '#E7E9EB !important',
    },
  },
  _focus: {
    outlineColor: 'transparent',
  },
  _focusVisible: {
    outlineColor: 'red.800AA',
  },
  _active: {
    bg: 'red.900',
  },
};

const criticalSecondaryButton = {
  borderRadius: '6px',
  borderWidth: '1px',
  bg: 'brand.neutral.white',
  color: 'red.800AA',
  borderColor: 'red.800AA',
  minWidth: 'auto',

  _hover: {
    bg: 'red.200',
    color: 'red.900',
    borderColor: 'red.900',
  },
  _focus: {
    boxShadow: '0 0 0 2px #FCE3E2',
  },
  _active: {
    bg: 'red.300',
    color: 'red.900',
    borderColor: 'red.900',
  },
  _disabled: {
    color: 'brand.neutral.500',
    borderColor: 'brand.neutral.500',

    _hover: {
      color: 'brand.neutral.500',
      borderColor: 'brand.neutral.500',
    },
  },
};

const infoButton = {
  bg: 'yellow.700',
  color: 'brand.neutral.white',
  minWidth: 'auto',

  _hover: {
    bg: 'yellow.700',
    color: 'brand.neutral.white',
  },
  _disabled: {
    color: '#fff !important',
    bg: '#F3C4C5 !important',
  },
  _focus: {
    boxShadow: '0 0 0 2px #C8D0DA',
  },
  _active: {
    color: 'brand.neutral.white',
  },
};

const linkButton = {
  border: 'none',
  bg: 'transparent',
  p: '0',
  paddingInlineStart: '0',
  paddingInlineEnd: '0',

  color: 'brand.blue.800A',
  _hover: {
    color: 'brand.blue.800A',
  },
  _disabled: {
    color: '#E1F4FF !important',
  },
  _focus: {
    color: 'brand.blue.900',
  },
  _active: {
    color: 'brand.blue.900',
  },
};

const ghostButton = {
  border: 'none',
  bg: 'transparent',
  p: '0',
  paddingInlineStart: '0',
  paddingInlineEnd: '0',
  color: 'brand.neutral.600',
  _hover: {
    bg: 'transparent',
    color: 'brand.neutral.700',
  },
  _disabled: {
    color: '#C8D0DA !important',
  },
  _focus: {
    color: 'brand.neutral.700',
  },
  _active: {
    color: 'brand.neutral.900',
  },
};

const minimalButton = {
  borderWidth: '1px',
  borderColor: 'transparent',
  bg: 'transparent',
  p: '0',
  paddingInlineStart: '0',
  paddingInlineEnd: '0',
  color: 'brand.neutral.600',
  minW: 'auto',
  _hover: {
    bg: 'transparent',
    borderColor: 'brand.neutral.700',
    color: 'brand.neutral.700',
  },
  _disabled: {
    color: 'brand.darkBlue.300',
    _hover: {
      bg: 'transparent',
      borderColor: 'transparent',
      color: 'brand.darkBlue.300',
    },
  },
  _focus: {
    bg: 'transparent',
    borderColor: 'transparent',
    color: 'brand.neutral.700',
    boxShadow: '0 0 0 2px #C8D0DA',
  },
  _active: {
    bg: 'transparent',
    borderColor: 'transparent',
    color: 'brand.neutral.900',
  },
};

const minimalSecondaryButton = {
  borderWidth: '1px',
  borderColor: 'transparent',
  bg: 'transparent',
  p: '0',
  paddingInlineStart: '0',
  paddingInlineEnd: '0',
  color: 'brand.blue.800A',
  _hover: {
    bg: 'transparent',
    borderColor: 'transparent',
    color: 'brand.blue.900',
  },
  _disabled: {
    color: 'brand.darkBlue.300',
    _hover: {
      bg: 'transparent',
      borderColor: 'transparent',
      color: 'brand.darkBlue.300',
    },
  },
  _focus: {
    bg: 'transparent',
    borderColor: 'transparent',
    color: 'brand.blue.900',
    boxShadow: '0 0 0 2px #BEE5FF',
  },
  _active: {
    bg: 'transparent',
    borderColor: 'transparent',
    color: 'brand.blue.900',
  },
};

const iconButton = {
  w: '32px',
  h: '32px',
  minWidth: '32px',
  minHeight: '32px',
  maxWidth: '32px',
  maxHeight: '32px',
};

// START: NEW Buttons
// Primary (Primary + PrimaryCritical)
const newPrimaryButton = {
  borderRadius: '6px',
  bg: 'brand.darkBlue.800A',
  color: 'brand.neutral.white',
  minWidth: 'auto',

  _hover: {
    bg: 'brand.darkBlue.700',
  },
  _focusVisible: {
    bg: 'brand.blue.800A',
    boxShadow: '0 0 0 2px #B3D1FF',
  },
  _active: {
    bg: 'brand.darkBlue.900',
    boxShadow: 'none !important',
  },
  _disabled: {
    bg: '#E7E9EB !important',
    color: '#9AA3AB !important',

    _hover: {
      bg: '#E7E9EB !important',
      color: '#9AA3AB !important',
    },
  },
};

const newPrimaryCriticalButton = {
  borderRadius: '6px',
  bg: 'red.800AA',
  color: 'brand.neutral.white',
  minWidth: 'auto',

  _hover: {
    bg: 'red.700',
  },
  _focusVisible: {
    bg: 'red.800AA',
    boxShadow: '0 0 0 2px #F7C5C4',
  },
  _active: {
    bg: 'red.900',
  },
  _disabled: {
    bg: '#E7E9EB !important',
    color: '#9AA3AB !important',

    _hover: {
      bg: '#E7E9EB !important',
      color: '#9AA3AB !important',
    },
  },
};

// Secondary (Secondary + SecondaryCritical)
const newSecondaryButton = {
  borderRadius: '6px',
  borderWidth: '1px',
  borderColor: 'brand.blue.800A',
  bg: 'brand.neutral.white',
  color: 'brand.blue.800A',
  minWidth: 'auto',

  _hover: {
    borderColor: 'brand.blue.800A',
    bg: 'brand.blue.50',
    color: 'brand.blue.800A',
  },
  _focusVisible: {
    boxShadow: '0 0 0 2px #B3D1FF',
  },
  _active: {
    borderColor: 'brand.blue.800A',
    bg: 'brand.blue.100',
    color: 'brand.blue.800A',
    boxShadow: 'none !important',
  },
  _disabled: {
    borderColor: '#6C7884 !important',
    color: '#6C7884 !important',

    _hover: {
      borderColor: '#6C7884 !important',
      color: '#6C7884 !important',
    },
  },
};

const newSecondaryCriticalButton = {
  borderRadius: '6px',
  borderWidth: '1px',
  borderColor: 'red.800AA',
  bg: 'brand.neutral.white',
  color: 'red.800AA',
  minWidth: 'auto',

  _hover: {
    borderColor: 'red.900',
    bg: 'red.200',
    color: 'red.900',
  },
  _focusVisible: {
    boxShadow: '0 0 0 2px #FBD6C9',
  },
  _active: {
    borderColor: 'red.900',
    bg: 'red.250',
    color: 'red.900',
    boxShadow: 'none !important',
  },
  _disabled: {
    borderColor: '#6C7884 !important',
    color: '#6C7884 !important',

    _hover: {
      borderColor: '#6C7884 !important',
      color: '#6C7884 !important',
    },
  },
};

// Tertiary
const newTertiaryButton = {
  borderRadius: '6px',
  borderWidth: '1px',
  borderColor: 'transparent',
  bg: 'brand.blue.100',
  color: 'brand.blue.800A',
  minWidth: 'auto',

  _hover: {
    borderColor: 'brand.blue.250',
    color: 'brand.blue.800A',
  },
  _focusVisible: {
    borderColor: 'brand.blue.800A',
    boxShadow: '0 0 0 2px #B3D1FF',
  },
  _active: {
    borderColor: 'brand.blue.800A',
    boxShadow: 'none !important',
  },
  _disabled: {
    bg: '#C8D0DA !important',
    color: '#FFF !important',

    _hover: {
      bg: '#C8D0DA !important',
      color: '#FFF !important',
      borderColor: 'transparent',
    },
  },
};

// Minimal (Minimal + MinimalCritical)
const newMinimalButton = {
  borderRadius: '4px',
  borderWidth: '1px',
  borderColor: 'transparent',
  bg: 'transparent',
  color: 'brand.blue.800A',
  px: '0 !important',
  minWidth: 'auto',

  _hover: {
    bg: 'brand.blue.50',
  },
  _focusVisible: {
    boxShadow: '0 0 0 2px #B3D1FF',
  },
  _active: {
    bg: 'brand.blue.100',
    boxShadow: 'none !important',
  },
  _disabled: {
    color: '#6C7884 !important',

    _hover: {
      color: '#6C7884 !important',
    },
  },
};

const newMinimalCriticalButton = {
  borderRadius: '4px',
  borderWidth: '1px',
  borderColor: 'transparent',
  bg: 'transparent',
  color: 'red.800AA',
  px: '0 !important',
  minWidth: 'auto',

  _hover: {
    bg: 'red.200',
    color: 'red.900',
  },
  _focusVisible: {
    boxShadow: '0 0 0 2px #FBD6C9',
  },
  _active: {
    bg: 'red.250',
    color: 'red.900',
    boxShadow: 'none !important',
  },
  _disabled: {
    color: '#6C7884 !important',

    _hover: {
      color: '#6C7884 !important',
    },
  },
};
// END: NEW Buttons

export const getButtonSpacings = (size, leftIcon, rightIcon) => {
  let iconSpacing = '8px';
  let paddingInline = '12px';

  switch (size) {
    case 'xl':
      iconSpacing = '12px';
      if (leftIcon) {
        paddingInline = '16px 20px';
      } else if (rightIcon) {
        paddingInline = '20px 16px';
      } else {
        paddingInline = '20px';
      }
      break;

    case 'lg':
      iconSpacing = '8px';
      if (leftIcon) {
        paddingInline = '12px 16px';
      } else if (rightIcon) {
        paddingInline = '16px 12px';
      } else {
        paddingInline = '16px';
      }
      break;

    case 'md':
      iconSpacing = '4px';
      if (leftIcon) {
        paddingInline = '10px 12px';
      } else if (rightIcon) {
        paddingInline = '12px 10px';
      } else {
        paddingInline = '12px';
      }
      break;

    case 'sm':
      iconSpacing = '4px';
      // TODO: Confirm with Jamil
      if (leftIcon) {
        paddingInline = '10px 12px';
      } else if (rightIcon) {
        paddingInline = '12px 10px';
      } else {
        paddingInline = '12px';
      }
      break;

    default:
      return null;
  }

  return { iconSpacing, paddingInline };
};

const sizes = {
  xl: {
    h: '48px',
    fontSize: 'md',
    lineHeight: '24px',
    py: '12px',
  },
  lg: {
    h: '40px',
    fontSize: 'sm',
    lineHeight: '20px',
    p: '10px 16px',
    py: '10px',
  },
  md: {
    h: '32px',
    fontSize: 'xs',
    lineHeight: '20px',
    py: '6px',
  },
  sm: {
    h: '28px',
    fontSize: 'xs',
    lineHeight: '20px',
    py: '6px',
  },
};

export const Button = {
  baseStyle,
  sizes,
  variants: {
    primary: primaryButton,
    criticalPrimary: criticalPrimaryButton,
    primaryLight: primaryLightButton, // TODO: this is to be the tertiary button in the future when we work on design system changes. This is temporary.
    secondary: secondaryButton,
    tertiary: tertiaryButton,
    criticalSecondary: criticalSecondaryButton,
    link: linkButton,
    ghost: ghostButton,
    minimal: minimalButton,
    minimalSecondary: minimalSecondaryButton,
    info: infoButton,
    iconPrimary: {
      ...iconButton,
      ...primaryButton,
    },
    iconSecondary: {
      ...iconButton,
      ...secondaryButton,
    },
    iconCriticalSecondary: {
      ...iconButton,
      ...criticalSecondaryButton,
    },
    // NEW Buttons
    newPrimary: newPrimaryButton,
    newPrimaryCritical: newPrimaryCriticalButton,
    newSecondary: newSecondaryButton,
    newSecondaryCritical: newSecondaryCriticalButton,
    newTertiary: newTertiaryButton,
    newMinimal: newMinimalButton,
    newMinimalCritical: newMinimalCriticalButton,
  },
};
