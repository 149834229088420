import { useEnvironment } from '@chakra-ui/react-env';
import { isBrowser } from '@chakra-ui/utils';
import * as React from 'react';
import { useEffect, useState } from 'react';

const useSafeLayoutEffect = isBrowser ? React.useLayoutEffect : React.useEffect;

// NOTE: This hook is a PATCHED version of the "useMediaQuery" hook
// that ships with @chakra-ui/react. The difference/fix we have is that
// we're storing the "matches" value in a ref for comparison, therefore
// fixing a bug by not using stale value generated at first-run time.
// If this ever fixed, replace it :D

/**
 * React hook that tracks state of a CSS media query
 *
 * @param {string} mediaQuery the media query to match
 */
export function useMediaQuery(mediaQuery) {
  const isSafari =
    navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
  const body = document.querySelector('body');
  const env = useEnvironment();
  const [manualCheckRequested, setManualCheckRequested] = useState(false);

  // In safari the resize event doesn't fire on fullscreen window action
  // So a resize observer is used to track resize event and start the calculations manually
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (isSafari) {
        setManualCheckRequested(true);
      }
    });

    resizeObserver.observe(body);
    return () => resizeObserver.unobserve(body);
  }, []);
  const queries = Array.isArray(mediaQuery) ? mediaQuery : [mediaQuery];
  const matchMediaIsSupported = isBrowser && 'matchMedia' in env.window;

  const [matchesMediaQuery, setMatchesMediaQuery] = React.useState(
    queries.map((queryItem) =>
      matchMediaIsSupported ? !!env.window.matchMedia(queryItem).matches : false
    )
  );
  const matchesRef = React.useRef(matchesMediaQuery);

  const handleResize = (mediaQueryList) => {
    const isEqual = (prev: boolean[], curr: boolean[]) =>
      prev.length === curr.length && prev.every((elem, idx) => elem === curr[idx]);

    const currentMatches = mediaQueryList.map((query) => query.matches);

    if (!isEqual(matchesRef.current, currentMatches)) {
      matchesRef.current = currentMatches;
      setMatchesMediaQuery(currentMatches);
    }
  };

  useSafeLayoutEffect(() => {
    if (!matchMediaIsSupported) return undefined;

    if (manualCheckRequested) {
      setManualCheckRequested(false);
    }

    const mediaQueryList = queries.map((queryItem) => window.matchMedia(queryItem));

    // Safari on this case doesn't initially emit the event, so it fires manually
    if (isSafari) {
      handleResize(mediaQueryList);
    }

    const listenerList = mediaQueryList.map(() => {
      const listener = () => handleResize(mediaQueryList);

      env.window.addEventListener('resize', listener);

      return listener;
    });

    return () => {
      mediaQueryList.forEach((_, index) => {
        env.window.removeEventListener('resize', listenerList[index]);
      });
    };
  }, [mediaQuery, manualCheckRequested]);

  return matchesMediaQuery;
}
