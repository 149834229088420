// @flow
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, VStack } from '@chakra-ui/react';
import {
  SIGN_UP_WIZARD,
  ONBOARDING,
  ALREADY_SIGNED_UP,
  EXPIRED_INVITATION,
  LOGIN,
} from '@routing/constants/route.constants';
import {
  topNavMenuHeaderStyles,
  topNavMenuWrapperStyles,
} from '@shared/components/BaselaneNavMenu/style';
import { desktopMainStyles, topNavBarStyles } from '@layouts/Desktop/style';
import BaselaneFullLogo from '../../../assets/BaselaneFullLogo';

const DesktopAnonymousLayout = ({ children }: { children: React.Node }) => {
  const { pathname } = useLocation();

  return (
    <>
      {pathname !== SIGN_UP_WIZARD &&
        pathname !== ALREADY_SIGNED_UP &&
        pathname !== ONBOARDING &&
        pathname !== EXPIRED_INVITATION && (
          <Box {...topNavBarStyles}>
            <VStack {...topNavMenuWrapperStyles}>
              <Box {...topNavMenuHeaderStyles(false)}>
                <BaselaneFullLogo color="#012550" />
              </Box>
            </VStack>
          </Box>
        )}
      <Box
        {...desktopMainStyles(
          pathname === SIGN_UP_WIZARD ||
            pathname === ALREADY_SIGNED_UP ||
            pathname === EXPIRED_INVITATION ||
            pathname === ONBOARDING ||
            pathname === LOGIN
        )}
        id="desktop"
      >
        {children}
      </Box>
    </>
  );
};

export default DesktopAnonymousLayout;
