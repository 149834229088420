// @flow
import React from 'react';
import ReactJoyride from 'react-joyride';

import { tourSteps } from '../../steps.config';
import TourTooltip from '../TourTooltip';
import { useProductTour } from '../../contexts/ProductTourContext';

// Provider
const ProductTour = () => {
  const { tourInternals } = useProductTour();
  const { start, handleSetTourHelpers } = tourInternals;

  return (
    <ReactJoyride
      continuous
      tooltipComponent={TourTooltip}
      steps={tourSteps}
      run={start}
      getHelpers={handleSetTourHelpers}
      spotlightPadding={0}
      styles={{
        options: {
          zIndex: 1400,
        },
        overlay: {
          backgroundColor: `rgba(7, 31, 79, 0.25)`,
        },
      }}
      floaterProps={{
        styles: {
          arrow: {
            length: 8,
            spread: 16,
          },
        },
      }}
      disableOverlayClose
      disableScrolling
    />
  );
};

export default ProductTour;
