/* eslint-disable no-console */
// @flow
import { datadogLogs } from '@datadog/browser-logs';

export default {
  log: (message: string): void => {
    console.log(message);
    datadogLogs.logger.log(message);
  },
  error: (error: Error): void => {
    console.error(error?.message);
    datadogLogs.logger.error(error?.message);
  },
};
