import { body } from '@theme/text.style';

const variants = {
  unstyled: {
    thead: {
      bg: 'brand.blue.50',
    },

    caption: {
      ...body.xs,
    },

    th: {
      color: 'brand.neutral.600',
      lineHeight: '16px',
      padding: '16px',
      fontWeight: 'normal',
      textTransform: 'none',
    },

    td: {
      color: 'brand.neutral.700',
      height: '64px',
      padding: '0 16px',
      ...body.sm,
      textTransform: 'none',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'brand.darkBlue.100',
    },

    tr: {
      boxShadow: 'none',
      border: '3px',
    },
  },
};

export const Table = {
  variants,
};
