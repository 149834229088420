// @flow
import React from 'react';

import { Button, ButtonProps } from '@chakra-ui/react';
import { isString, lowerCase, replace, startsWith, trim } from 'lodash';
import useIsLargerThanSmallScreen from '../../../hooks/useIsLargerThanSmallScreen';

// Helpers
function trimSlidableTextPart(btnText: string) {
  const slidableTextPart = 'slide to';

  if (!startsWith(lowerCase(btnText), slidableTextPart)) {
    return btnText;
  }

  return replace(trim(btnText), new RegExp(slidableTextPart, 'i'), '');
}

// Basic Button Component
const BasicButton = ({
  isDisabled = false,
  width,
  onClick = () => {},
  className,
  children,
  styles,
  ...rest
}: ButtonProps) => {
  const isDesktopScreen = useIsLargerThanSmallScreen();
  const defaultWidth = isDesktopScreen ? 'auto' : '100%';
  const updatedChildren = isString(children) ? trimSlidableTextPart(children) : children;

  return (
    // NOTE: Refer to theme.js on options for variant and size props
    <Button
      className={className}
      isDisabled={isDisabled}
      onClick={onClick}
      width={width ?? defaultWidth}
      {...styles}
      {...rest}
    >
      {updatedChildren}
    </Button>
  );
};

export default BasicButton;
