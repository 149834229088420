// @flow
import React from 'react';

import { RadioGroup, Stack, Radio, Text, StackDirection } from '@chakra-ui/react';

import type { BaselaneRadioGroupItemListType } from '@shared/types/baselaneRadioGroup.type';

import {
  radioGroupItemStyles,
  radioGroupContainerStyles,
  radioLabelWrapper,
} from '@shared/components/BaselaneRadioGroup/style';

type BaselaneRadioGroupProps = {
  itemList: BaselaneRadioGroupItemListType[],
  onChange: Function,
  spacing?: string,
  defaultValue?: string,
  direction?: StackDirection,
};

function BaselaneRadioGroup({
  itemList,
  onChange,
  defaultValue,
  spacing,
  direction,
}: BaselaneRadioGroupProps): Comment {
  return (
    <RadioGroup onChange={onChange} defaultValue={defaultValue}>
      <Stack {...radioGroupContainerStyles} spacing={spacing} direction={direction}>
        {itemList.map((item) => (
          <Radio
            {...radioGroupItemStyles}
            key={item.value}
            value={item.value}
            isDisabled={item.isDisabled}
          >
            <Text {...radioLabelWrapper}>{item.label}</Text>
          </Radio>
        ))}
      </Stack>
    </RadioGroup>
  );
}

BaselaneRadioGroup.defaultProps = {
  spacing: '8px',
  defaultValue: '',
  direction: 'column',
};

export default BaselaneRadioGroup;
