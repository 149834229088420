/**
 * Based on https://www.youtube.com/watch?v=xIsgcg9Ieak
 * Inserts the Zendesk script to app and resuable API fn
 */

import { useEffect } from 'react';

type ZendeskProps = {
  onLoaded: Function,
  zendeskKey: String,
  defer: Boolean,
  other: any,
};

const canUseDOM = typeof window !== 'undefined' || window.document || window.document.createElement;

const Zendesk = ({ onLoaded, zendeskKey, defer, other }: ZendeskProps) => {
  const onScriptLoaded = () => {
    if (typeof onLoaded === 'function') {
      onLoaded();
    }
  };

  const insertScript = () => {
    const script = document.createElement('script');
    if (defer) {
      script.defer = true;
    } else {
      script.async = true;
    }
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    script.addEventListener('load', onScriptLoaded);
    document.body.appendChild(script);
  };

  useEffect(() => {
    const zeScript = document.getElementById('ze-snippet');
    if (canUseDOM && !window.zE && !zeScript) {
      insertScript();
      window.zESettings = other;
    }
  });

  return null;
};

export const zendeskAPI = (...args) => {
  if (canUseDOM && window.zE) {
    window.zE.apply(null, args);
  } else {
    console.warn('Zendesk is not initialized yet.');
  }
};

export default Zendesk;
