// @flow
import React from 'react';

import { NavLink, useHistory } from 'react-router-dom';
import { Box, HStack, StyleProps } from '@chakra-ui/react';

import { tourStepAttrSelector } from '@tour/steps.config';

import type { NavItem } from '../../nav-item.type';
import { navItemStyles, navLinkStyles } from './style';
import './style.scss';

// Props Interface
type NavItemProps = {
  navItem: NavItem,
  onClick?: () => void,
  isShowTitle?: boolean,
  styles?: StyleProps,
};

// Component
const BaselaneNavItem = ({
  navItem: { title, link, icon, tourStep },
  onClick,
  isShowTitle,
  styles,
}: NavItemProps) => {
  const history = useHistory();
  const handleIsActiveLink = (match) => {
    return match?.url === link;
  };

  const handleNavLinkClick = (event) => {
    event.preventDefault();
    if (link) {
      history.push(link);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <Box {...navItemStyles} {...styles}>
      <NavLink
        to={link ?? '#'}
        onClick={handleNavLinkClick}
        isActive={handleIsActiveLink}
        style={{ ...navLinkStyles }}
        activeClassName="active-nav-link"
        {...{ [tourStepAttrSelector]: tourStep }}
      >
        <HStack align="center" spacing="16px">
          <Box minWidth="16px" marginTop="4px" color="red">
            {icon}
          </Box>
          {isShowTitle && <Box>{title}</Box>}
        </HStack>
      </NavLink>
    </Box>
  );
};

BaselaneNavItem.defaultProps = {
  onClick: () => {},
  isShowTitle: true,
  styles: {},
};

export default BaselaneNavItem;
