// @flow
import React from 'react';
import { FormControl, FormLabel, FormErrorMessage, Select, Stack, Text } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import BaselaneInput from '@shared/components/BaselaneInput';
import BaselaneAddress from '@shared/components/BaselaneAddress';

// import StateInput from './StateInput';
import { zipcodeMask } from '@core/utils/masks';
import { IconExclamationCircle } from '@icons';
import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';
import {
  formErrorStyles,
  formInputStyles,
  formContainerStyles,
  formLabelStyles,
  xsFormLabelStyles,
} from '../../styles';

// Props
type AddressElementProps = {
  errors: String,
  touched: String,
  values: String,
  handleChange: Function,
  handleBlur: Function,
};

// Component
function AddressElement({
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
}: AddressElementProps): Component {
  const isDesktopScreen = useIsLargerThanSmallScreen();

  return (
    <>
      <FormControl isInvalid={errors.address && touched.address}>
        {/* Street Address */}
        <BaselaneInput
          label="Street Address"
          name="address"
          placeholder="e.g. 14 Madison St."
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
          }}
          customformLabelStyles={formLabelStyles}
          customformInputStyles={formInputStyles}
          errorLeftElement={<IconExclamationCircle w="13" h="13" />}
        />
      </FormControl>

      {/* Unit Number */}
      <FormControl
        position="relative"
        isInvalid={errors.unitNumber && touched.unitNumber}
        {...formContainerStyles}
      >
        <BaselaneInput
          label="Apartment, Unit, Suite, or Floor #"
          name="unitNumber"
          placeholder="e.g. Floor 1"
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
          }}
          customformLabelStyles={formLabelStyles}
          customformInputStyles={formInputStyles}
          errorLeftElement={<IconExclamationCircle w="13" h="13" />}
        />
      </FormControl>

      <Stack
        isInline={isDesktopScreen}
        w="100%"
        flexDirection={isDesktopScreen ? 'row' : 'column'}
        {...formContainerStyles}
        mb={!isDesktopScreen && '28px!important'}
      >
        {/* City */}
        <FormControl
          position="relative"
          w={isDesktopScreen ? '50%' : '100%'}
          isInvalid={errors.city && touched.city}
          isRequired
        >
          <BaselaneInput
            label="City"
            name="city"
            placeholder="e.g. Philadelphia"
            onChange={handleChange}
            onBlur={(e) => {
              handleBlur(e);
            }}
            customformLabelStyles={formLabelStyles}
            customformInputStyles={formInputStyles}
            errorLeftElement={<IconExclamationCircle w="13" h="13" />}
          />
        </FormControl>

        <Stack isInline w={isDesktopScreen ? '50%' : '100%'} pb={isDesktopScreen && '80px'}>
          {/* State */}
          <FormControl position="relative" w="50%" isInvalid={errors.state && touched.state}>
            <FormLabel htmlFor="state" {...xsFormLabelStyles}>
              State
            </FormLabel>
            <Select
              {...formInputStyles}
              p="0"
              id="state"
              name="state"
              value={values.state}
              placeholder="Select state"
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={(e) => {
                handleBlur(e);
              }}
            >
              <BaselaneAddress.StateOptions />
            </Select>
            <FormErrorMessage {...formErrorStyles}>
              <IconExclamationCircle w="13" h="13" />
              <Text ml="8px">{errors.state}</Text>
            </FormErrorMessage>
          </FormControl>

          {/* Zip Code */}
          <FormControl
            position="relative"
            w="50%"
            isInvalid={errors.zipcode && touched.zipcode}
            isRequired
          >
            <BaselaneInput
              label="Zip code"
              as={MaskedInput}
              mask={zipcodeMask}
              id="zipcode"
              name="zipcode"
              value={values.zipcode}
              placeholder="e.g. 19123"
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
              }}
              customformLabelStyles={formLabelStyles}
              customformInputStyles={formInputStyles}
              errorLeftElement={<IconExclamationCircle w="13" h="13" />}
            />
          </FormControl>
        </Stack>
      </Stack>
    </>
  );
}

AddressElement.defaultProps = {};

export default AddressElement;
