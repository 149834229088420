// @flow
import { useNavMenu } from '@core/contexts/NavMenuContext';

import { useProductTour } from '../../contexts/ProductTourContext';
import type { StepHandler } from '../step-handler.interface';

// Constant
const NAV_MENU_OPENING_DELAY = 300;

// Hook
const useLeaseDetailsStepHandler = (): StepHandler => {
  const { onOpen: openNavMenu } = useNavMenu();
  const { tourHelpers } = useProductTour();

  function handleNextStep() {
    openNavMenu?.();
    setTimeout(() => {
      tourHelpers?.next();
    }, NAV_MENU_OPENING_DELAY);
  }

  return {
    next: handleNextStep,
  };
};

export default useLeaseDetailsStepHandler;
