// @flow
import React from 'react';

import { DASHBOARD, PAYMENT_METHODS } from '@routing/constants/route.constants';
import IconDashboard from '@icons/IconDashboard';
import IconCreditCard from '@icons/IconCreditCard';
import { stepPaymentMethods } from '@tour/steps.config';

import type { NavItem } from './nav-item.type';

// Nav Menu config
export const navigationItems: (NavItem & ?{ tourStep: string })[] = [
  {
    title: 'Dashboard',
    link: DASHBOARD,
    icon: <IconDashboard />,
  },
  {
    title: 'Payment Methods',
    link: PAYMENT_METHODS,
    icon: <IconCreditCard />,
    tourStep: stepPaymentMethods,
  },
];
