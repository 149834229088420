import React from 'react';
import { useHistory } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';
import BaselaneButton from '@shared/components/BaselaneButton';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';
import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';
import { useLease } from '@contexts/LeaseContext';
import { DASHBOARD } from '@routing/constants/route.constants';
import { bankAddedStorage } from '@core/services';
import PaymentAdded from '../../../PaymentAdded';

const PaymentMethodAdded = ({
  reference,
  paymentMethodId,
  bankAddedStatus,
  closeFlow,
}: {
  reference: any,
  paymentMethodId: String,
  bankAddedStatus: String,
  closeFlow: Function,
}): Component => {
  const isDesktopScreen = useIsLargerThanSmallScreen();
  const history = useHistory();
  const { selectedLease } = useLease();

  return (
    <BaselaneDrawer
      isShowFooter
      ref={reference}
      title="Add New Payment Method"
      headerVariant={isDesktopScreen ? 'secondary' : 'primary'}
      desktopSize="xl"
      customFooter={
        <Stack
          isInline={isDesktopScreen}
          spacing="16px"
          w="100%"
          flexDirection={isDesktopScreen ? 'row' : 'column-reverse'}
        >
          <BaselaneButton
            id="close-payment-added-drawer"
            size={isDesktopScreen ? 'lg' : 'xl'}
            onClick={() => {
              closeFlow();
            }}
            variant="newSecondary"
          >
            Close
          </BaselaneButton>
          {bankAddedStatus === 'CONNECTED' ? (
            <BaselaneButton
              size={isDesktopScreen ? 'lg' : 'xl'}
              onClick={() => {
                reference?.current?.close();
                closeFlow();
                bankAddedStorage.write(paymentMethodId);
                history.push(DASHBOARD);
              }}
              variant="newPrimary"
              mb={isDesktopScreen ? 'initial' : '8px !important'}
            >
              {selectedLease?.tenantAutoPayEnrolled
                ? 'Use Payment Method for Auto Pay'
                : 'Enroll in Auto Pay'}
            </BaselaneButton>
          ) : null}
        </Stack>
      }
      content={<PaymentAdded status={bankAddedStatus} />}
    />
  );
};

export default PaymentMethodAdded;
