// @flow
import React from 'react';
import { getAchData } from './helpers/achData';
import AchFeeBonus from './components/AchFeeBonus';
import AchFeePaidBy from './components/AchFeePaidBy';
import AchFeeText from './components/AchFeeText';

function BaselaneAchFee() {
  const { ACH_FEATURE_FLAG, isPromoActive, feeAmount, whoIsPaying, hasMultipleLeases } =
    getAchData();

  if (ACH_FEATURE_FLAG) {
    if (hasMultipleLeases) {
      return <AchFeeText text={`${feeAmount} processing fee per payment may apply`} />;
    }
    if (isPromoActive) {
      return <AchFeeBonus isPromoActive={isPromoActive} feeAmount={feeAmount} />;
    }
    return <AchFeePaidBy feeAmount={feeAmount} whoIsPaying={whoIsPaying} />;
  }
  if (!ACH_FEATURE_FLAG) return <AchFeeText text="Free, no fees added" />;
}

export default BaselaneAchFee;
