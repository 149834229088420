import React from 'react';

function IconTrash() {
  return (
    <svg width="20" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.72386 0.723532C3.97391 0.473484 4.31304 0.333008 4.66667 0.333008H7.33333C7.68696 0.333008 8.02609 0.473484 8.27614 0.723532C8.52619 0.97358 8.66667 1.31272 8.66667 1.66634V2.99967H11.3333C11.7015 2.99967 12 3.29815 12 3.66634C12 4.03453 11.7015 4.33301 11.3333 4.33301H11.2874L10.7537 11.8083C10.7536 11.8084 10.7537 11.8083 10.7537 11.8083C10.7177 12.3129 10.4919 12.7852 10.1218 13.1299C9.75158 13.4747 9.26451 13.6664 8.75865 13.6663H3.24135C2.73549 13.6664 2.24842 13.4747 1.87823 13.1299C1.50809 12.7852 1.28231 12.313 1.24636 11.8085C1.24636 11.8084 1.24636 11.8085 1.24636 11.8085L0.712573 4.33301H0.666667C0.298477 4.33301 0 4.03453 0 3.66634C0 3.29815 0.298477 2.99967 0.666667 2.99967H3.33333V1.66634C3.33333 1.31272 3.47381 0.97358 3.72386 0.723532ZM4.66667 2.99967H7.33333V1.66634H4.66667V2.99967ZM2.0493 4.33301L2.57631 11.7135C2.58828 11.8817 2.66355 12.0393 2.78695 12.1542C2.91034 12.2691 3.0727 12.333 3.24133 12.333H8.75868C8.9273 12.333 9.08966 12.2691 9.21305 12.1542C9.33645 12.0393 9.41171 11.8819 9.42368 11.7137L9.9507 4.33301H2.0493ZM4.66667 5.66634C5.03486 5.66634 5.33333 5.96482 5.33333 6.33301V10.333C5.33333 10.7012 5.03486 10.9997 4.66667 10.9997C4.29848 10.9997 4 10.7012 4 10.333V6.33301C4 5.96482 4.29848 5.66634 4.66667 5.66634ZM7.33333 5.66634C7.70152 5.66634 8 5.96482 8 6.33301V10.333C8 10.7012 7.70152 10.9997 7.33333 10.9997C6.96514 10.9997 6.66667 10.7012 6.66667 10.333V6.33301C6.66667 5.96482 6.96514 5.66634 7.33333 5.66634Z"
        fill="#257ED0"
      />
    </svg>
  );
}

export default IconTrash;
