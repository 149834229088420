export const tabsStyles = {
  backgroundColor: { base: 'transparent', lg: 'brand.neutral.white' },
  margin: { base: 0, lg: '40px 32px' },
  padding: { base: 0, lg: '0 24px' },
  position: { base: 'static', lg: 'relative' },
};

export const tabListStyles = {
  padding: { base: '24px 16px', lg: 0 },
  border: 0,
  boxShadow: 'inset 0px -1px 0px 0px #E1F4FF', // blue.100
  height: { lg: '50px' },
  alignItems: { base: 'flex-start', lg: 'flex-end' },
};

export const tabNavLinkStyles = {
  marginRight: { base: '16px', lg: 0 },
  marginLeft: { base: '0', lg: '16px' },
  borderRadius: 0,
  height: { base: '32px', lg: '42px' },
  textStyle: 'headline-sm',
  color: 'brand.neutral.500',
  padding: { base: 0, lg: '8px 8px 20px 8px' },
  _selected: {
    color: 'brand.blue.700',
    boxShadow: 'inset 0px -2px 0px 0px #257ED0',
    backgroundColor: 'inherit',
    border: 'none',
    borderColor: 'none',
    marginBottom: 0,
  },
  _hover: {
    color: 'brand.neutral.600',
  },
  _active: {
    outline: 'none',
    boxShadow: 'none',
  },
  _focus: {
    outline: 'none',
    boxShadow: 'none',
  },
};

export const tabPanelStyles = {
  padding: { base: '0 16px 10px', lg: '24px 0' },
};
