import React from 'react';

import type { IconProps } from '@icons/types';

function IconChevronDown({ color = '#091A2D', width = '16px', height = '16px' }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.46967 5.46966C3.76256 5.17676 4.23744 5.17676 4.53033 5.46966L8 8.93933L11.4697 5.46966C11.7626 5.17677 12.2374 5.17677 12.5303 5.46966C12.8232 5.76256 12.8232 6.23743 12.5303 6.53032L8.57485 10.4858C8.25737 10.8033 7.74263 10.8033 7.42515 10.4858L3.46967 6.53032C3.17678 6.23742 3.17678 5.76255 3.46967 5.46966Z"
        fill={color}
      />
    </svg>
  );
}

export default IconChevronDown;
