// @flow
import { OperationVariables, useLazyQuery, useQuery } from '@apollo/client';

import { QueryResult } from '@apollo/client/react/types/types';
import {
  GET_TENANT_PLAID_LINK_TOKEN,
  UPDATE_PLAID_LINK_TOKEN,
  PAYMENT_METHOD_PLAID_LINK_TOKEN,
} from '@core/apollo/requests/plaid.graphql';
import type { TenantPlaidLinkToken } from '@core/apollo/types/plaid.type';

/**
 * Return Plaid Link Token
 * @return {QueryResult<TenantPlaidLinkToken, OperationVariables>}
 */
export function getTenantPlaidLinkToken(): QueryResult<TenantPlaidLinkToken, OperationVariables> {
  return useQuery(GET_TENANT_PLAID_LINK_TOKEN);
}

/**
 * Return Plaid Link Token
 * @return {QueryResult<any, OperationVariables>}
 */
export function updatePlaidLinkToken(): QueryResult<any, OperationVariables> {
  return useQuery(UPDATE_PLAID_LINK_TOKEN);
}
export function paymentMethodPlaidLinkToken(variables): QueryResult<any, OperationVariables> {
  return useQuery(PAYMENT_METHOD_PLAID_LINK_TOKEN, variables);
}
export function getPaymentMethodPlaidLinkTokenLazy(
  variables
): QueryResult<any, OperationVariables> {
  const [getPaymentMethodPlaidLinkToken] = useLazyQuery(PAYMENT_METHOD_PLAID_LINK_TOKEN, variables);
  return getPaymentMethodPlaidLinkToken;
}
