// @flow
import React from 'react';
import { Box, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';
import IconClose from '@icons/IconClose';

import icons from '../icons';
import Card from './BaselaneCard';
import { cardTwoStyles, getIconContainerStyles } from './card.style';

type Props = {
  children?: any,
  borderColor?: string,
  title?: string,
  iconColor?: string,
  iconName?: string,
  text?: string,
  hasCloseButton?: boolean,
  onCloseButtonClick?: Function,
  hasActions?: boolean,
  actions?: any,
  containerStyles?: Object,
  iconContainerStyles?: Object,
  isVertical?: boolean,
  isIconCenterAligned?: boolean,
  iconProps?: Object,
  squeezeIcon?: boolean,
};

function BaselaneCardMessage({
  children,
  borderColor,
  iconColor,
  iconName,
  text,
  hasCloseButton,
  onCloseButtonClick,
  hasActions,
  actions,
  containerStyles: cStyles,
  iconContainerStyles,
  title,
  isVertical,
  isIconCenterAligned,
  iconProps,
  squeezeIcon,
}: Props): any {
  const isDesktopScreen = useIsLargerThanSmallScreen();
  const name = iconName.toLowerCase();
  const Icon = icons[name];

  const borderColorStyle = getIconContainerStyles(borderColor);
  const iconColorStyle = getIconContainerStyles(iconColor);

  const { container, innerContainer, borderContainer, closeButton } = cardTwoStyles;

  const containerStyles = {
    ...container,
    ...cStyles,
  };

  const innerContainerStyles = {
    ...innerContainer,
    pt: isVertical ? '8px' : '10px',
  };

  const badgeIconContainerStyles = {
    color: iconColorStyle,
    ...iconContainerStyles,
    mr: squeezeIcon ? '0' : '16px',
    mb: isIconCenterAligned ? '0' : 'auto',
  };

  const borderContainerStyles = {
    ...borderContainer,
    borderColor: borderColorStyle,
  };

  return (
    <Card styles={containerStyles}>
      <Box {...borderContainerStyles} />
      <HStack {...innerContainerStyles}>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          justifyContent={{ base: 'center', lg: 'space-between' }}
          alignItems="flex-start"
          align="center"
          w="100%"
          my="4px"
        >
          <HStack align="center">
            {iconName !== '' && (
              <Box {...badgeIconContainerStyles}>
                <Icon {...iconProps} />
              </Box>
            )}
            <Flex
              direction={{ base: 'column', lg: 'row' }}
              justifyContent={{ base: 'center', lg: 'space-between' }}
              align="center"
              w="100%"
              m="0 !important"
            >
              <Stack isInline={!isVertical} alignItems={isVertical ? 'flex-start' : 'center'}>
                {title && (
                  <Text
                    textAlign="left"
                    flex="1 0 auto"
                    fontWeight="medium"
                    color="brand.neutral.900"
                  >
                    {title}
                  </Text>
                )}
                <Box
                  textAlign="left"
                  flex="1 1 auto"
                  mt="0 !important"
                  fontWeight="normal"
                  color="brand.neutral.600"
                  ml={title && !isVertical ? '24px !important' : 'initial'}
                >
                  {text || children}
                </Box>
              </Stack>
              {!isDesktopScreen && hasActions && (
                <Box alignSelf="baseline" mt="8px">
                  {actions}
                </Box>
              )}
            </Flex>
          </HStack>
          {isDesktopScreen && hasActions && (
            <Box alignSelf="baseline" mt="6px">
              {actions}
            </Box>
          )}
        </Flex>
        {hasCloseButton && (
          <Box as="button" onClick={onCloseButtonClick} {...closeButton}>
            <IconClose />
          </Box>
        )}
      </HStack>
    </Card>
  );
}

BaselaneCardMessage.defaultProps = {
  children: null,
  borderColor: 'green',
  iconColor: 'green',
  iconName: 'checkcircle',
  text: null,
  title: null,
  hasCloseButton: false,
  onCloseButtonClick: Function,
  hasActions: false,
  actions: null,
  containerStyles: Object,
  iconContainerStyles: Object,
  isVertical: false,
  isIconCenterAligned: false,
  iconProps: {},
  squeezeIcon: false,
};

export default BaselaneCardMessage;
