export const infoRowWrapperStyles = (isDesktop: boolean = false) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${isDesktop ? '10px' : '4px'} 0`,
  textStyle: 'sm',
});

export const infoRowLabelStyles = {
  color: 'brand.neutral.600',
  fontWeight: 'normal',
  marginRight: '30px',
  flex: '1 0 80px',
};

export const infoRowValueStyles = (isDesktop: boolean = false) => ({
  color: 'brand.neutral.900',
  fontWeight: isDesktop ? 'normal' : 'medium',
  wordBreak: 'break-word',
});
