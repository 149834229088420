// @flow
import React from 'react';

import { Divider, DividerProps } from '@chakra-ui/react';

import { getDividerStyle } from '@shared/components/BaselaneDivider/style';

// Props
type BaselaneDividerProps = DividerProps & {
  styles?: Object,
};

// Component
function BaselaneDivider({ styles, orientation, ...rest }: BaselaneDividerProps) {
  const dividerStyle = getDividerStyle(orientation);
  const dividerStyles = { ...dividerStyle, ...styles };

  return <Divider orientation={orientation} {...dividerStyles} {...rest} />;
}

export default BaselaneDivider;
