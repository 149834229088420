export const darkBackgroundColors = {
  red: 'red.800AA',
  green: 'green.800AA',
  blue: 'brand.blue.700',
  yellow: 'yellow.700',
  secondaryred: 'red.500A',
};

export const backgroundColors = {
  green: 'green.100',
  blue: 'brand.blue.100',
  lightblue: 'brand.blue.50',
  yellow: 'yellow.100',
  warningyellow: 'yellow.700',
  red: 'red.200',
  darkred: 'red.800AA',
  gray: 'brand.darkBlue.250',
  secondaryred: 'red.200',
};

export const fontColors = {
  green: 'green.800AA',
  blue: 'brand.blue.700',
  lightblue: 'brand.blue.300',
  warningyellow: 'yellow.700',
  yellow: 'yellow.900',
  red: 'red.800AA',
  secondaryred: 'red.500A',
  darkred: 'brand.neutral.white',
  gray: 'brand.neutral.white',
};
