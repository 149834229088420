// @flow
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, VStack } from '@chakra-ui/react';
import { LOGIN } from '@routing/constants/route.constants';
import {
  topNavMenuHeaderStyles,
  topNavMenuWrapperStyles,
} from '@shared/components/BaselaneNavMenu/style';
import { topNavBarStyles } from '@layouts/Desktop/style';
import { appContainerStyles, mainStyles } from '@layouts/Mobile/style';
import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';
import BaselaneFullLogo from '../../../assets/BaselaneFullLogo';

const MobileAnonymousLayout = ({ children }: { children: React.Node }) => {
  const isDesktopScreen = useIsLargerThanSmallScreen();
  const { pathname } = useLocation();
  return (
    <Box {...appContainerStyles}>
      {pathname === LOGIN && (
        <Box {...topNavBarStyles}>
          <VStack {...topNavMenuWrapperStyles}>
            <Box {...topNavMenuHeaderStyles(false)} paddingLeft={isDesktopScreen ? '32px' : '16px'}>
              <BaselaneFullLogo color="#012550" />
            </Box>
          </VStack>
        </Box>
      )}
      <Box {...mainStyles(false)}>{children}</Box>
    </Box>
  );
};

export default MobileAnonymousLayout;
