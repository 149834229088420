// @flow
export function getDepositLabel(depositName: string): string {
  switch (depositName) {
    case 'LAST_MONTH_RENT': {
      return `Last Month's Rent Pre-Payment`;
    }
    case 'DEPOSIT': {
      return 'Security Deposit';
    }
    case 'PRORATED_RENT': {
      return 'Prorated Rent';
    }
    default:
      return '';
  }
}
