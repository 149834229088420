import { body } from '@theme/text.style';

export const Form = {
  parts: ['helperText'],
  baseStyle: {
    helperText: {
      ...body.xs,
      color: 'brand.neutral.600',
      marginBottom: '8px',
      marginTop: '4px',
      textAlign: 'left',
    },
  },
};

export const FormLabel = {
  baseStyle: {
    ...body['sm-heavy'],
    color: 'brand.neutral.700',
    marginBottom: '4px',
  },
};

export const Input = {
  parts: ['field'],
  baseStyle: {
    field: {
      ...body.sm,
      color: 'brand.neutral.600',
      borderRadius: '4px',
      _placeholder: {
        ...body.sm,
        color: 'brand.neutral.500',
      },
    },
  },
};

export const FormError = {
  parts: ['text'],
  baseStyle: {
    text: {
      color: 'red.800AA',
      ...body.xs,
      mt: '8px',
      textAlign: 'left',
    },
  },
};
