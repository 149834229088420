import React from 'react';

type IconShieldCheckProps = {
  w?: string,
  h?: string,
  color?: string,
};

const IconShieldCheck = ({ w, h, color }: IconShieldCheckProps) => {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.55543 1.46636C7.80873 1.23908 8.19257 1.23908 8.44588 1.46636C9.88561 2.75818 11.779 3.42608 13.7106 3.3235C14.0274 3.30668 14.3121 3.51538 14.3915 3.8225C14.5713 4.5189 14.6673 5.24823 14.6673 5.9999C14.6673 10.0394 11.9049 13.4316 8.1668 14.3935C8.05781 14.4216 7.9435 14.4216 7.83451 14.3935C4.09637 13.4316 1.33399 10.0387 1.33399 5.9999H2.00065L1.33399 6.00044C1.33399 6.00026 1.33399 6.00008 1.33399 5.9999C1.33344 5.26535 1.42613 4.5337 1.60984 3.8225C1.68917 3.51538 1.97392 3.30668 2.29067 3.3235C4.22228 3.42608 6.11569 2.75818 7.55543 1.46636ZM2.78815 4.66643C2.70747 5.10578 2.66696 5.55193 2.66732 5.99936L2.66732 5.9999C2.66732 9.35564 4.92213 12.187 8.00066 13.0574C11.0792 12.187 13.334 9.35627 13.334 5.9999C13.334 5.54403 13.2924 5.09856 13.2131 4.66643C11.3218 4.64236 9.49 3.99763 8.00066 2.83202C6.5113 3.99764 4.67948 4.64237 2.78815 4.66643ZM10.4721 6.19516C10.7324 6.45551 10.7324 6.87762 10.4721 7.13797L7.80539 9.80464C7.54504 10.065 7.12293 10.065 6.86258 9.80464L5.52925 8.4713C5.2689 8.21095 5.2689 7.78884 5.52925 7.52849C5.7896 7.26814 6.21171 7.26814 6.47206 7.52849L7.33399 8.39042L9.52925 6.19516C9.7896 5.93481 10.2117 5.93481 10.4721 6.19516Z"
        fill={color}
      />
    </svg>
  );
};

IconShieldCheck.defaultProps = {
  w: '16',
  h: '16',
  color: 'currentColor',
};

export default IconShieldCheck;
