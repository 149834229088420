import React from 'react';

import type { IconProps } from '@icons/types';

function IconArrowDropUp({ color, width, height }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.25c0 .42.33.75.75.75h10.5a.743.743 0 00.555-1.245l-5.25-6A.747.747 0 006 .5a.747.747 0 00-.555.255l-5.25 6A.73.73 0 000 7.25z"
        fill={color}
      />
    </svg>
  );
}

IconArrowDropUp.defaultProps = {
  color: '#6C7884',
  width: '8px',
  height: '5px',
};

export default IconArrowDropUp;
