// @flow

export const HANDLED_BASELANE_ERROR_CODES = ['BANK_NOT_SUPPORTED', 'TENANT_ALREADY_ACTIVE'];

export function errorHasCode(err, code: string): boolean {
  const errors = err.graphQLErrors || [];

  for (let i = 0; i < errors.length; i += 1) {
    const error = errors[i];
    if (error.extensions && error.extensions.response) {
      if (error.extensions.response.body.baselaneErrorCode === code) {
        return true;
      }
    }
  }

  return false;
}

export function isHandledErrorCode(code: string) {
  return HANDLED_BASELANE_ERROR_CODES.indexOf(code) !== -1;
}
