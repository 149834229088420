import React from 'react';

import type { IconProps } from '@icons/types';

function IconDashboard({ color = '#001843', width = '16px', height = '16px' }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.89543 0.895431 0 2 0H4C5.10457 0 6 0.895431 6 2V5C6 6.10457 5.10457 7 4 7H2C0.89543 7 0 6.10457 0 5V2ZM2 1.5H4C4.27614 1.5 4.5 1.72386 4.5 2V5C4.5 5.27614 4.27614 5.5 4 5.5H2C1.72386 5.5 1.5 5.27614 1.5 5V2C1.5 1.72386 1.72386 1.5 2 1.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11C0 9.89543 0.895431 9 2 9H6C7.10457 9 8 9.89543 8 11V14C8 15.1046 7.10457 16 6 16H2C0.89543 16 0 15.1046 0 14V11ZM2 10.5H6C6.27614 10.5 6.5 10.7239 6.5 11V14C6.5 14.2761 6.27614 14.5 6 14.5H2C1.72386 14.5 1.5 14.2761 1.5 14V11C1.5 10.7239 1.72386 10.5 2 10.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C8.89543 0 8 0.89543 8 2V5C8 6.10457 8.89543 7 10 7H14C15.1046 7 16 6.10457 16 5V2C16 0.895431 15.1046 0 14 0H10ZM14 1.5H10C9.72386 1.5 9.5 1.72386 9.5 2V5C9.5 5.27614 9.72386 5.5 10 5.5H14C14.2761 5.5 14.5 5.27614 14.5 5V2C14.5 1.72386 14.2761 1.5 14 1.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9C10.8954 9 10 9.89543 10 11V14C10 15.1046 10.8954 16 12 16H14C15.1046 16 16 15.1046 16 14V11C16 9.89543 15.1046 9 14 9H12ZM14 10.5H12C11.7239 10.5 11.5 10.7239 11.5 11V14C11.5 14.2761 11.7239 14.5 12 14.5H14C14.2761 14.5 14.5 14.2761 14.5 14V11C14.5 10.7239 14.2761 10.5 14 10.5Z"
        fill={color}
      />
    </svg>
  );
}

export default IconDashboard;
