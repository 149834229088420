import React from 'react';
import type { IconProps } from '@icons/types';

function IconStatusOverdue({ color = '#C93A3A', bgColor = '#C93A3A' }: IconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.08" cx="12" cy="12" r="12" fill={bgColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.268 6.333c.77-1.333 2.694-1.333 3.464 0l4.619 8c.77 1.333-.193 3-1.732 3H7.38c-1.54 0-2.502-1.666-1.732-3l4.619-8zM11.422 7l-4.618 8a.667.667 0 00.577 1h9.238a.667.667 0 00.577-1l-4.619-8a.667.667 0 00-1.155 0zM12 9.333c.368 0 .667.299.667.667v1.333a.667.667 0 01-1.334 0V10c0-.368.299-.667.667-.667zM11.333 14c0-.368.299-.667.667-.667h.007a.667.667 0 010 1.334H12a.667.667 0 01-.667-.667z"
        fill={color}
      />
    </svg>
  );
}

export default IconStatusOverdue;
