// @flow
import React, { useRef, useState } from 'react';

import { Button, Box, ButtonProps } from '@chakra-ui/react';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';

import IconArrowNarrowRight from '@icons/IconArrowNarrowRight';

import {
  absoluteButtonContainerStyles,
  draggableElementStyles,
  IconArrowNarrowRightStyles,
} from './style';

// Helpers
function checkIfDragElementHasReachedParentBoundary(data: DraggableData): boolean {
  if (!data) return false;

  const { lastX, node } = data;
  const { clientWidth: widthOfDraggableElement, parentNode } = node;
  const { clientWidth: parentWidth } = parentNode;

  return widthOfDraggableElement + lastX === parentWidth;
}

// Slidable Button Component
const SlidableButton = ({
  isDisabled = false,
  width = '100%',
  onClick = () => {},
  className,
  children,
  ...rest
}: ButtonProps) => {
  const draggableRef = useRef(null);
  const [isSlideCompleted, setSlideCompleted] = useState(false);

  const handleDrag = (event: DraggableEvent, data: DraggableData) => {
    if (!isDisabled && !isSlideCompleted) {
      if (checkIfDragElementHasReachedParentBoundary(data)) {
        setSlideCompleted(true);
        onClick();
      }
    }
  };

  const handleStopDrag = () => {
    // make button slidable again if slide is completed and drag stopped
    if (isSlideCompleted) {
      setSlideCompleted(false);
    }
  };

  return (
    // NOTE: Refer to theme.js on options for variant and size props
    <Button className={className} isDisabled={isDisabled} width={width} {...rest}>
      {/* Absolute positioned container. Needed to escape button's padding */}
      <Box {...absoluteButtonContainerStyles}>
        <Draggable
          nodeRef={draggableRef}
          axis="x"
          bounds="parent"
          position={{ x: 0, y: 0 }}
          onDrag={handleDrag}
          onStop={handleStopDrag}
          disabled={isSlideCompleted}
        >
          {/* Draggable element */}
          <Box ref={draggableRef} {...draggableElementStyles} />
        </Draggable>
      </Box>
      <>
        <Box {...IconArrowNarrowRightStyles}>
          <IconArrowNarrowRight />
        </Box>
        {children}
      </>
    </Button>
  );
};

export default SlidableButton;
