import React, { useContext, useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';

import IconCheck from '@icons/IconCheck';
import IconExclamationCircle from '@icons/IconExclamationCircle';
import numOfDigitsMask from '@core/utils/numOfDigitsMask';
import OTPContext from '@contexts/OTPContext';
import colors from '@theme/colors';

import BaselaneButton from '../BaselaneButton';
import BaselaneModal from '../BaselaneModal';
import BaselaneCardMessage from '../BaselaneCard/BaselaneCardMessage';
import { formErrorStyles, formInputStyles, formLabelStyles } from '../../styles';
import { verifyEmailModalStyles } from './styles/verifyEmailModal.styles';

type VerifyEmailModalProps = {
  email: String,
  onCloseBtnClick: Function,
  onVerifyEmailOtpSuccess: Function,
  type: String,
};

const VerifyEmailModal = ({
  email,
  onCloseBtnClick,
  onVerifyEmailOtpSuccess,
  type,
}: VerifyEmailModalProps) => {
  const {
    emailOtpError,
    setEmailOtpError,
    isGetEmailOtpSuccessful,
    isSendEmailOtpLoading,
    isVerifyEmailOtpLoading,
    handleSendOtpRegisteredUser,
    handleVerifyOtpRegisteredUser,
  } = useContext(OTPContext);
  const [otpCode, setOtpCode] = useState('');

  const action =
    type === 'autopay' ? 'setting up your first Auto Pay' : 'scheduling your first payment';

  const handleResendEmailOtp = () => {
    handleSendOtpRegisteredUser({ isTriggeredFromModal: true });
  };

  const handleSubmitCode = () => {
    handleVerifyOtpRegisteredUser({
      code: otpCode,
      handleVerifyEmailOtpSuccess: onVerifyEmailOtpSuccess,
    });
  };

  useEffect(() => {
    handleSendOtpRegisteredUser({ isTriggeredFromModal: false });
  }, []);

  const { modal, content, spinner } = verifyEmailModalStyles ?? {};

  return (
    <BaselaneModal
      btnSize="xl"
      isCentered
      defaultOpen
      showCloseButton
      onCloseBtnClick={onCloseBtnClick}
      btnClick={() => {}}
      modalContentStyles={modal.content}
      modalBodyStyles={modal.body}
      closeOnOverlayClick={false}
    >
      {isSendEmailOtpLoading ? (
        <Box {...spinner.container}>
          <Spinner {...spinner.icon} />
        </Box>
      ) : (
        <Stack {...content.container}>
          <Stack {...content.top.container}>
            <Box {...content.top.title}>One-time verification</Box>
            <Box {...content.top.description}>
              To finish {action}, please enter the verification code sent to{' '}
              <Box as="span" {...content.top.email}>
                {email || ''}
              </Box>
              <br />
              <Box mt="8px">The code will expire in 10 minutes.</Box>
            </Box>
            {emailOtpError?.isGenericError && (
              <BaselaneCardMessage
                title="Something went wrong. Please try again."
                iconName="exclamationcircle"
                iconColor="red"
                borderColor="red"
                iconWidth="14px"
                iconHeight="14px"
                iconContainerStyles={{ d: 'flex', justifyContent: 'center', w: '20px' }}
                containerStyles={{ width: '100%' }}
                {...content.top.messagecard}
              />
            )}
            {emailOtpError?.isTooManyAttempts && (
              <BaselaneCardMessage
                title="Too many attempts"
                iconName="exclamationcircle"
                iconColor="warningyellow"
                borderColor="warningyellow"
                text="Enter the last code you received, or try again later."
                iconWidth="24px"
                iconHeight="24px"
                isVertical
                {...content.top.messagecard}
              />
            )}
          </Stack>

          <Stack {...content.bottom.container}>
            <FormControl
              isInvalid={emailOtpError?.showErrorMessage}
              {...content.bottom.form.control}
            >
              <FormLabel htmlFor="code" {...formLabelStyles.xs} textStyle="xs">
                Enter code
              </FormLabel>
              <Input
                {...formInputStyles}
                id="code"
                name="code"
                placeholder="XXXXXX"
                value={otpCode}
                onChange={(e) => {
                  // Hide inline error msg once user starts typing
                  if (emailOtpError?.showErrorMessage && e.target.value !== otpCode) {
                    setEmailOtpError(null);
                  }
                  setOtpCode(e.target.value);
                }}
                as={MaskedInput}
                mask={numOfDigitsMask(6)}
              />
              <FormErrorMessage {...{ ...formErrorStyles, ml: '0 !important', gap: '8px' }}>
                <IconExclamationCircle w="13.33" h="13.33" color={colors.red['800AA']} />
                <Text>{emailOtpError?.message}</Text>
              </FormErrorMessage>
            </FormControl>
            <Flex direction="row">
              <BaselaneButton
                variant="newMinimal"
                onClick={() => {
                  handleResendEmailOtp();
                }}
                isDisabled={emailOtpError?.isTooManyAttempts || emailOtpError?.isGenericError}
                styles={content.bottom.button}
              >
                Resend Code
              </BaselaneButton>
              {isGetEmailOtpSuccessful && (
                <Box marginTop="2px">
                  <IconCheck color={colors.green['800AA']} marginTop="2px" />
                </Box>
              )}
            </Flex>
          </Stack>

          <Box {...content.disclaimer}>Can’t find the email? Please check your spam folder</Box>

          <Flex w="100%" direction="row" justifyContent="flex-end" gap="8px">
            <BaselaneButton
              variant="newSecondary"
              size="lg"
              styles={content.actionbutton}
              onClick={onCloseBtnClick}
            >
              Cancel
            </BaselaneButton>
            <BaselaneButton
              variant="newPrimary"
              size="lg"
              styles={content.actionbutton}
              isLoading={isVerifyEmailOtpLoading}
              onClick={() => {
                handleSubmitCode();
              }}
              isDisabled={otpCode?.length < 6}
            >
              Submit
            </BaselaneButton>
          </Flex>
        </Stack>
      )}
    </BaselaneModal>
  );
};

export default VerifyEmailModal;
