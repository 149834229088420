export const ADDRESS_OPTIONS = {
  appearance: {
    theme: 'stripe',
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#10325B',
      colorDanger: '#C93A3A',
      spacingUnit: '2px',
      borderRadius: '4px',
      iconColor: '#0056AF',
      fontWeight: '500',
      fontFamily: '"Ease Variable", Helvetica, Arial',
      colorBorder: '1px solid #CDD4E0',
      lineHeigh: '30px',
      fontSize: '14px',
    },
    rules: {
      '.Input': {
        borderColor: '#C8D0DA',
        lineHeight: '13px',
        iconColor: '#0056AF',
        color: '#10325B',
        fontWeight: '500',
        fontFamily: '"Ease Variable", Helvetica, Arial',
        fontSize: '14px',
        padding: '16px 14px',
        marginTop: '4px',
      },
      '.Label': {
        fontFamily: '"Ease Variable", Helvetica, Arial',
        colorText: '#10325B',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '20px',
        pb: '8px',
        marginTop: '16px',
        marginBottom: '4px',
      },
    },
  },
};

export const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#0056AF',
      color: '#192C3E',
      fontWeight: '200',
      fontFamily: '"Ease Variable", Helvetica, Arial',
      fontSize: '14px',
      lineHeight: '13px',
      '::placeholder': {
        color: '#3A4B5B',
        fontWeight: '200',
        fontSize: '14px',
      },
    },
    invalid: {
      iconColor: '#C93A3A',
      color: '#C93A3A',
      fontWeight: '200',
    },
  },
};

export const ADDRESS_SHIPPING_OPTIONS = {
  mode: 'shipping',
  autocomplete: {
    mode: 'disabled',
  },
  allowedCountries: ['US'],
  blockPoBox: false,
  fields: {
    address: {
      city: 'always',
      country: 'never',
      line1: 'always',
      line2: 'never',
      postal_code: 'always',
      state: 'always',
    },
    name: 'never',
  },

  defaultValues: {
    address: {
      line1: '',
      city: '',
      state: '',
      postal_code: '',
      country: 'US',
    },
  },
};
