// @flow
import React from 'react';
import { TooltipRenderProps } from 'react-joyride';
import { Box, CloseButton, Text, useDisclosure, HStack } from '@chakra-ui/react';

import { BaselaneButton, BaselaneDialogMessage } from '@shared/components';
import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';

import { useNavMenu } from '../../../core/contexts/NavMenuContext';
import { useProductTour } from '../../contexts/ProductTourContext';
import useStepHandler from '../../step-handlers/useStepHandler';
import {
  tooltipCloseButtonStyles,
  tooltipContainerStyles,
  tooltipContentStyles,
  tooltipFooterStyles,
} from './style';

// Component
const TourTooltip = ({ step, isLastStep, tooltipProps }: TooltipRenderProps) => {
  const isDesktopScreen = useIsLargerThanSmallScreen();
  const { tourHelpers } = useProductTour();
  const { next, reset, endTour } = tourHelpers;
  const customStepHandler = useStepHandler(step);

  const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
  const { onClose: onCloseNavMenu } = useNavMenu();

  const handleNextStep = () => {
    const { next: customNextStepHandler } = customStepHandler ?? {};
    return customNextStepHandler ? customNextStepHandler() : next();
  };

  const closeTourAndContinue = () => {
    endTour();
    reset(true);
    onCloseNavMenu();
  };

  return (
    <>
      <Box {...tooltipContainerStyles(isDesktopScreen)} {...tooltipProps}>
        <Box {...tooltipContentStyles}>
          {!isLastStep && <CloseButton {...tooltipCloseButtonStyles} onClick={onOpenModal} />}
          {step?.title}
          <Text variant="secondary">{step?.content}</Text>
        </Box>
        <Box {...tooltipFooterStyles}>
          <BaselaneButton variant="primary" size="lg" onClick={handleNextStep}>
            {isLastStep ? 'Close' : 'Next'}
          </BaselaneButton>
        </Box>
      </Box>

      <BaselaneDialogMessage
        isOpen={isOpenModal}
        type="error"
        text="Are you sure you want to end the tour?"
        additionalText="You can return to this tour at any time from the navigation menu."
        onClose={onCloseModal}
      >
        <HStack {...{ w: '100%', justify: 'flex-end' }}>
          <BaselaneButton size="lg" variant="secondary" onClick={onCloseModal}>
            Continue Tour
          </BaselaneButton>
          <BaselaneButton size="lg" variant="primary" onClick={closeTourAndContinue}>
            End Tour
          </BaselaneButton>
        </HStack>
      </BaselaneDialogMessage>
    </>
  );
};

export default TourTooltip;
