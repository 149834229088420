const track = {
  bg: 'brand.darkBlue.200',
  _focus: {
    boxShadow: 'none',
  },
  _checked: {
    bg: 'brand.blue.700',
  },
};

export const Switch = {
  baseStyle: { track },
  defaultProps: {
    size: 'md',
    colorScheme: 'blue',
  },
};
