import React from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { IconLockClosed } from '@icons';

import { containerStyles } from '../styles/informationProtected.styles';

const InformationProtected = () => {
  return (
    <HStack {...containerStyles}>
      <IconLockClosed w={12} h={13} />
      <Text>Your information is protected with 256-bit SSL encryption</Text>
    </HStack>
  );
};

export default InformationProtected;
