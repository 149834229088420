import React from 'react';

function IconRightArrow() {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" transform="rotate(-90 15 15)" fill="white" />
      <path
        d="M19.4238 14.8188L14.0374 9.43237C12.7103 8.10528 10.6807 10.1349 12.0077 11.462L15.4035 14.8188L12.0077 18.2146C10.6807 19.5417 12.7103 21.5713 14.0374 20.2052L19.4238 14.8188Z"
        fill="#192C3E"
      />
    </svg>
  );
}

export default IconRightArrow;
