import { isMobile } from 'react-device-detect';
import { backgroundColors, fontColors } from '../../colors';

export const baseStyles = {
  borderRadius: isMobile ? '2px' : '4px',
  p: isMobile ? '2px 6px' : '6px 12px',
  lineHeight: '20px',
  h: isMobile ? '20px' : '32px',
  w: 'auto',
};

export const badgeFontStyles = {
  small: {
    textStyle: 'headline-xs',
    m: 0,
    textTransform: 'uppercase',
  },
  medium: {
    textStyle: 'xs',
    m: 0,
    textTransform: 'capitalize',
  },
  large: {
    m: 0,
    textStyle: 'headline-sm',
    textTransform: 'capitalize',
  },
};

export const getBadgeIconStyles = (iconClassName) => {
  if (iconClassName === 'icon-xsm') {
    return {
      w: '12',
      h: '12',
    };
  }
  if (iconClassName === 'icon-sm') {
    return {
      w: '14',
      h: '14',
    };
  }
  if (iconClassName === 'icon-lg') {
    return {
      w: '20',
      h: '20',
    };
  }
  if (iconClassName === 'icon-xl') {
    return {
      w: '25',
      h: '25',
    };
  }
  // md is default
  return {
    w: '17',
    h: '17',
  };
};

export const getFontStyles = (iconName, type, color) => {
  if (type === 'badge') {
    return {
      ...badgeFontStyles.small,
      width: 'max-content',
      color: fontColors[color],
    };
  }

  if (type === 'master') {
    return {
      ...badgeFontStyles.large,
      fontWeight: isMobile ? 'medium' : 'normal',
      color: fontColors[color],
    };
  }
  if (type === 'inline') {
    return {
      ...badgeFontStyles.medium,
      color: isMobile ? fontColors[color] : 'brand.neutral.700',
    };
  }

  return {
    ...badgeFontStyles.large,
    fontSize: badgeFontStyles.large.fontSize,
    color: 'brand.neutral.700',
  };
};

export const getBackgroundStyles = (iconName, type, color) => {
  if (type === 'badge') {
    return {
      ...baseStyles,
      h: '20px',
      bg: backgroundColors[color],
      p: baseStyles.p,
    };
  }

  if (type === 'master') {
    return {
      ...baseStyles,
      h: isMobile ? '40px' : '32px',
      bg: backgroundColors[color],
      p: isMobile ? '6px 12px 6px 8px' : baseStyles.p,
      justifyContent: isMobile ? 'center' : 'flex-start',
      w: isMobile ? '152px' : 'auto',
    };
  }
  if (type === 'inline') {
    return {
      ...baseStyles,
      bg: 'transparent',
    };
  }

  return {
    ...baseStyles,
    bg: 'transparent',
    p: 0,
  };
};

export const getIconContainerStyles = (iconName, type, color) => {
  if (type === 'inline') {
    return {
      bg: backgroundColors[color],
      borderRadius: '50%',
      p: isMobile ? '4px' : '8px',
      color: fontColors[color],
    };
  }

  return { bg: 'transparent', color: fontColors[color] };
};
