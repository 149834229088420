import { body } from '@theme/text.style';

export const Tooltip = {
  baseStyle: {
    bg: 'brand.neutral.700',
    color: 'brand.neutral.white',
    borderRadius: '8px',
    ...body.sm,
    zIndex: '1300',
    padding: '10px 16px',
  },
};
