import { getTenantLeases } from '@core/apollo/services/tenant-leases.apollo';
import { useLease } from '@contexts/LeaseContext';
import isNil from '@core/utils/validators/isNil';
import { calculateWhoIsPaying, hideAchFee } from './whoIsPaying';

export const getAchData = () => {
  const { selectedLease } = useLease();
  const { data: tenantLeases } = getTenantLeases();
  const hasMultipleLeases = tenantLeases?.tenantLeases.length > 1; // That means a fee may or may not apply in adding payment method
  const isPromoActive = tenantLeases && !isNil(selectedLease?.landlord?.achFeePromotion?.endDate);
  return {
    ACH_FEATURE_FLAG: process.env.REACT_APP_ACH_FEE_ENABLED_FEATURE_FLAG === 'true',
    whoIsPaying: (invoiceId) => calculateWhoIsPaying(invoiceId),
    promoExpirationDate: isPromoActive ? selectedLease?.landlord?.achFeePromotion?.endDate : null,
    feeAmount: '$2.00',
    isPromoActive,
    hasMultipleLeases,
    hideAchFee: (invoiceId) => hideAchFee(invoiceId),
  };
};
