import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import BaselaneAchBadge from '../BaselaneAchBadge';

function AchFeeBonus({ isPromoActive, feeAmount }: { isPromoActive?: Boolean, feeAmount: String }) {
  return (
    <Stack isInline={isPromoActive} {...{ alignItems: isPromoActive ? 'center' : 'flex-start' }}>
      <Text
        {...{
          fontSize: isMobile ? 'xs' : 'sm',
          lineHeight: '20px',
          fontWeight: 'normal',
          color: 'brand.neutral.600',
          textDecoration: 'line-through',
        }}
      >
        {feeAmount} processing fee per payment
      </Text>
      <BaselaneAchBadge />
    </Stack>
  );
}

AchFeeBonus.defaultProps = {
  isPromoActive: false,
};

export default AchFeeBonus;
