import React from 'react';

import type { IconProps } from '@icons/types';

function IconArrowDropDown({ color, width, height }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg">
      <path
        fontSize="5px"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 .75c0-.42-.33-.75-.75-.75H.75a.743.743 0 00-.555 1.245l5.25 6c.135.15.33.255.555.255.225 0 .42-.105.555-.255l5.25-6A.73.73 0 0012 .75z"
        fill={color}
      />
    </svg>
  );
}

IconArrowDropDown.defaultProps = {
  color: '#6C7884',
  width: '8px',
  height: '5px',
};

export default IconArrowDropDown;
