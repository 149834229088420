export const sectionTitleStyles = {
  fontSize: 'md',
  lineHeight: '24px',
  fontWeight: 'medium',
  color: 'brand.neutral.700',
  marginBottom: '8px',
};

export const summaryDescriptionStyles = {
  lineHeight: '24px',
  color: 'brand.neutral.700',
  fontWeight: 'normal',
};

export const landlordEmailStyles = {
  display: 'inline-block',
  color: 'brand.blue.700',
  fontWeight: 'medium',
};
