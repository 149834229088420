// @flow
import React, { Component, useRef, useState } from 'react';
import { Formik } from 'formik';
import { Box, FormControl, FormLabel, Stack, Text } from '@chakra-ui/react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { v4 as uuidv4 } from 'uuid';
import BaselaneInput from '@shared/components/BaselaneInput';
import { loggerService } from '@core/services';
import { IconExclamationCircle } from '@icons';
import { ErrorModalContextActions } from '@core/contexts/ErrorModalContext';
import {
  addTenantPaymentMethod,
  addTenantPaymentMethodNoCache,
} from '@core/apollo/services/tenant.apollo';
import type { AddPaymentMethodInput } from '@core/apollo/types/tenant.type';
import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';
import { CARD_OPTIONS } from './stripe.options';
import InformationProtected from '../../../InformationProtected';
import AddressElement from './components/AddressElement';
import StripeFooter from './components/StripeFooter';
import PaymentMethodAdded from '../PaymentMethodAdded';
import {
  addPaymentMethodTitleStyles,
  addPaymentMethodContainerStyles,
} from '../../addNewPaymentMethod.styles';
import {
  addPaymentMethodStripeStyles,
  addPaymentMethodStripeSubtitleStyles,
  formErrorStyles,
  formContainerStyles,
  formLabelStyles,
  formInputStyles,
  resetMarginTop,
} from './styles';

// Props
type StripeScreenProps = {
  close: Function,
  closeFlow: Function,
  noCache?: Boolean,
  onAddedBankAccount?: Function,
  showAutoPayDrawer?: Boolean,
};

// Component
function StripeScreen({
  close,
  closeFlow,
  noCache,
  onAddedBankAccount,
  showAutoPayDrawer,
}: StripeScreenProps): Component {
  const isDesktopScreen = useIsLargerThanSmallScreen();

  const paymentAddedRef = useRef(null);
  const formikRef = useRef(null);

  const [addPaymentMethod] = noCache ? addTenantPaymentMethodNoCache() : addTenantPaymentMethod();
  const stripe = useStripe();
  const elements = useElements();
  const [newPaymentMethodId, setNewPaymentMethodId] = useState();
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);
  const [isCVCComplete, setIsCVCComplete] = useState(false);
  const [isExpirationDateComplete, setIsExpirationDateComplete] = useState(false);

  const [cardNumberElementError, setCardNumberElementError] = useState('');
  const [cardExpiryElementError, setCardExpiryElementError] = useState('');
  const [cardCvcElementError, setCardCvcElementError] = useState('');
  const [xIdempotencyKey, setXIdempotencyKey] = useState(uuidv4());

  const initialValues = {
    fullname: '',
    address: '',
    unitNumber: '',
    city: '',
    state: '',
    zipcode: '',
  };

  const handleValidation = (values) => {
    const errors = {};

    if (!values?.fullname?.trim()) {
      errors.fullname = 'Please enter cardholder name';
    }
    if (!values?.address?.trim()) {
      errors.address = 'Please enter address';
    }
    if (!values?.city?.trim()) {
      errors.city = 'Please enter city';
    }
    if (!values?.state) {
      errors.state = 'Please enter state';
    }
    if (!values?.zipcode) {
      errors.zipcode = 'Please enter zip code';
    }
    if (values?.zipcode && values?.zipcode?.length < 5) {
      errors.zipcode = 'Please enter 5-digit zip code';
    }

    setIsFormValid(Object.keys(errors).length === 0);

    return errors;
  };

  const closePaymentMethodAddedDrawer = (): void => {
    paymentAddedRef?.current?.close();
  };

  const openPaymentMethodAddedDrawer = (): void => {
    paymentAddedRef?.current?.open();
  };

  /**
   * Add Card Method
   */
  const onAddCard = (values): void => {
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const addressData = {
      name: values.fullname,
      address_line1: values.address,
      address_line2: values.unitNumber,
      address_city: values.city,
      address_state: values.state,
      address_zip: values.zipcode,
    };

    stripe
      .createToken(elements.getElement(CardNumberElement), addressData)
      .then(({ token }) => {
        let paymentType: string = 'CREDIT_CARD';

        if (token.card.funding === 'debit') {
          paymentType = 'DEBIT_CARD';
        }

        const { address, unit, city, state, zipcode: zipCode } = values;
        const submittedAddress = {
          address,
          unit,
          city,
          state,
          zipCode,
        };

        const input: AddPaymentMethodInput = {
          cardToken: token,
          paymentType,
          name: token.card.brand,
          nickName: token.card.name || token.card.brand,
          accountType: paymentType.replace(/_/g, ' ').toLowerCase(),
          address: submittedAddress,
        };

        addPaymentMethod({
          variables: { input },
          context: { headers: { 'x-idempotency-key': xIdempotencyKey } },
        })
          .then(({ data }) => {
            setNewPaymentMethodId(data.addTenantPaymentMethod.id);
            onAddedBankAccount();
            if (showAutoPayDrawer) {
              openPaymentMethodAddedDrawer();
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            ErrorModalContextActions.onOpenCriticalError({
              title: 'We have encountered an issue',
              description: 'Please retry, and if issue persists, please contact support.',
            });
            loggerService.error(err);
          });
      })
      .catch((err) => {
        setLoading(false);
        ErrorModalContextActions.onOpenCriticalError({
          title: 'We have encountered an issue',
          description: 'Please retry, and if issue persists, please contact support.',
        });
        loggerService.error(err);
      })
      .finally(() => {
        setXIdempotencyKey(uuidv4());
      });
  };

  const handleSubmit = (e) => {
    onAddCard(e);
  };

  const handleFormikSubmit = (e) => formikRef.current.handleSubmit(e);
  return (
    <>
      <Stack
        h="100%"
        {...(isDesktopScreen ? { p: '48px 32px' } : { p: '36px 24px' })}
        position="relative"
        zIndex="1"
        overflow="auto"
      >
        <Stack w="100%" h="100%" justifyContent="space-between">
          <Stack
            spacing={isDesktopScreen ? '24px' : '16px'}
            {...addPaymentMethodContainerStyles}
            pb={!isDesktopScreen && '110px'}
          >
            <Stack
              spacing={isDesktopScreen ? '16px' : '4px'}
              w="100%"
              h={isDesktopScreen && '100%'}
              justifyContent="space-between"
            >
              <Text {...addPaymentMethodTitleStyles(isDesktopScreen)}>Let’s Add Your Card</Text>
              <Text {...addPaymentMethodStripeSubtitleStyles}>Enter your card information</Text>

              <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validate={handleValidation}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ values, handleChange, handleBlur, errors, touched }) => (
                  <>
                    <FormControl isInvalid={errors.fullname && touched.fullname}>
                      {/* full name Input */}
                      <BaselaneInput
                        label="Cardholder&lsquo;s name"
                        id="fullname"
                        name="fullname"
                        value={values.fullname}
                        placeholder=""
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        customformLabelStyles={formLabelStyles}
                        customformInputStyles={formInputStyles}
                        errorLeftElement={<IconExclamationCircle w="13" h="13" />}
                      />
                    </FormControl>

                    <Stack
                      isInline={isDesktopScreen}
                      flexDirection={isDesktopScreen ? 'row' : 'column'}
                      {...formContainerStyles}
                    >
                      <Box w={isDesktopScreen ? '50%' : '100%'} {...resetMarginTop}>
                        <FormLabel htmlFor="card number" {...formLabelStyles}>
                          Card number
                        </FormLabel>
                        <Box {...addPaymentMethodStripeStyles}>
                          <CardNumberElement
                            options={CARD_OPTIONS}
                            onChange={(event) => {
                              setIsCardNumberComplete(event.complete);
                              setCardNumberElementError(event?.error?.message);
                            }}
                          />
                        </Box>
                        {cardNumberElementError && (
                          <Stack {...formErrorStyles} isInline>
                            {' '}
                            <Box mt="2px">
                              <IconExclamationCircle w="13" h="13" />
                            </Box>
                            <Text ml="18px">{cardNumberElementError}</Text>
                          </Stack>
                        )}
                      </Box>
                      <Stack isInline w={isDesktopScreen ? '50%' : '100%'}>
                        <Box w="50%">
                          <FormLabel htmlFor="expirationdate" {...formLabelStyles}>
                            Expiration date
                          </FormLabel>
                          <Box {...addPaymentMethodStripeStyles}>
                            <CardExpiryElement
                              options={CARD_OPTIONS}
                              onChange={(event) => {
                                setIsExpirationDateComplete(event.complete);
                                setCardExpiryElementError(event?.error?.message);
                              }}
                            />
                          </Box>
                          {cardExpiryElementError && (
                            <Stack {...formErrorStyles} isInline>
                              <Box mt="2px">
                                <IconExclamationCircle w="13" h="13" />
                              </Box>
                              <Text>{cardExpiryElementError}</Text>
                            </Stack>
                          )}
                        </Box>
                        <Box w="50%">
                          <FormLabel htmlFor="cvc" {...formLabelStyles}>
                            CVC
                          </FormLabel>
                          <Box {...addPaymentMethodStripeStyles}>
                            <CardCvcElement
                              options={CARD_OPTIONS}
                              onChange={(event) => {
                                setIsCVCComplete(event.complete);
                                setCardCvcElementError(event?.error?.message);
                              }}
                            />
                          </Box>
                          {cardCvcElementError && (
                            <Stack {...formErrorStyles} isInline>
                              <Box mt="2px">
                                <IconExclamationCircle w="13" h="13" />
                              </Box>
                              <Text ml="8px">{cardCvcElementError}</Text>
                            </Stack>
                          )}
                        </Box>
                      </Stack>
                    </Stack>
                    <Text {...addPaymentMethodStripeSubtitleStyles}>Billing Address</Text>

                    <AddressElement
                      errors={errors}
                      touched={touched}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />

                    {!isDesktopScreen && <InformationProtected />}
                  </>
                )}
              </Formik>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <StripeFooter
        close={close}
        loading={loading}
        isCardNumberComplete={isCardNumberComplete}
        isCVCComplete={isCVCComplete}
        isExpirationDateComplete={isExpirationDateComplete}
        isFormValid={isFormValid}
        handleFormikSubmit={handleFormikSubmit}
      />
      <PaymentMethodAdded
        reference={paymentAddedRef}
        bankAddedStatus="CONNECTED"
        close={closePaymentMethodAddedDrawer}
        closeFlow={closeFlow}
        paymentMethodId={newPaymentMethodId}
      />
    </>
  );
}

StripeScreen.defaultProps = {
  noCache: false,
  showAutoPayDrawer: true,
  onAddedBankAccount: () => {},
};

export default StripeScreen;
