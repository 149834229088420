// @flow
import React from 'react';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';
import LeaseSummary from '../LeaseSummary';
import { useLeaseSummaryDrawer } from '../../contexts/LeaseSummaryDrawerContext';

// Provider
const LeaseSummaryDrawer = () => {
  const { onClose, ref } = useLeaseSummaryDrawer();

  return (
    <BaselaneDrawer closeEvent={onClose} ref={ref} title="Summary" content={<LeaseSummary />} />
  );
};

export default LeaseSummaryDrawer;
