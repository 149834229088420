// @flow
import { OperationVariables, useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { GET_STRIPE_PUBLIC_KEY } from '@core/apollo/requests/stripe.graphql';
import type { StripePublicKeyType } from '@core/apollo/types/stripe.type';

/**
 * Return Stripe Public Key
 * @return {QueryResult<StripePublicKeyType, OperationVariables>}
 */
export function getStripePublicKey(): QueryResult<StripePublicKeyType, OperationVariables> {
  return useQuery(GET_STRIPE_PUBLIC_KEY);
}
