export const leaseDropdownButtonStyles = {
  fontSize: { base: 'sm', lg: 'md' },
  color: 'brand.neutral.700',
  width: '100%',
  height: '56px',
  paddingRight: '20px',
  backgroundColor: 'brand.neutral.white',
  fontWeight: { base: 'medium', lg: 'normal' },
  variant: 'ghost',
  iconSpacing: '18px',
  whiteSpace: 'normal',
  wordBreak: 'normal',
  padding: '10px 16px !important',
  _active: {
    backgroundColor: 'brand.neutral.white',
  },

  _hover: {
    backgroundColor: 'brand.neutral.white',
  },
};

export const leaseDropdownButtonIconStyles = {
  width: '15px',
  height: '15px',
  margin: '0 13px 0 10px',
};

export const leaseDropdownButtonArrowMobileStyles = {
  width: '8px',
  height: '5px',
};

export const leaseDropdownButtonArrowDesktopStyles = {
  width: '12px',
  height: '8px',
};

export const leaseDropdownListStyles = {
  border: 'none',
  boxShadow: 'none',
  borderRadius: '0',
  paddingTop: '0',
  marginTop: '-8px',
  width: { base: '100vw', lg: 'calc(100vw - 80px)' },
  height: { base: 'calc(100vh - 112px)', lg: 'auto' },
};

export const leaseDropdownItemOptionStyles = {
  height: '56px',
  paddingLeft: '24px',
  type: 'radio',

  _active: {
    backgroundColor: 'transparent',
  },

  _focus: {
    backgroundColor: 'transparent',
  },
};
