import customTheme from '@theme/theme';

export const maxWidth = (point, isManual) => {
  if (isManual) {
    return `(max-width: ${point})`;
  }
  return `(max-width: ${customTheme.breakpoints[point]})`;
};

export const minWidth = (point, isManual) => {
  if (isManual) {
    return `(min-width: ${point})`;
  }
  return `(min-width: ${customTheme.breakpoints[point]})`;
};
