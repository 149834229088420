import React from 'react';

function IconRadioButtonActive() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="#257ED0" />
      <circle cx="8" cy="8" r="4" fill="white" />
    </svg>
  );
}

export default IconRadioButtonActive;
