// @flow
import React from 'react';
import { Icon } from '@chakra-ui/react';
import type { IconProps } from '@icons/types';

function IconWarningCircleOutline({
  width = 24,
  height = 24,
  color = 'currentColor',
  ...rest
}: IconProps) {
  return (
    <Icon
      {...rest}
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4a8 8 0 100 16 8 8 0 000-16zm-7.071.929A10 10 0 1119.07 19.07 10 10 0 014.93 4.93zM12 7a1 1 0 011 1v4a1 1 0 11-2 0V8a1 1 0 011-1zm-1 9a1 1 0 011-1h.01a1 1 0 110 2H12a1 1 0 01-1-1z"
        fill={color}
      />
    </Icon>
  );
}

export default IconWarningCircleOutline;
