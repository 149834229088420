import React from 'react';

function IconInfo({
  width,
  height,
  color,
  ...rest
}: {
  width?: string,
  height?: string,
  color?: string,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {width === '16' ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00001 2.66665C6.58552 2.66665 5.22897 3.22855 4.22877 4.22874C3.22858 5.22894 2.66668 6.58549 2.66668 7.99998C2.66668 8.70036 2.80463 9.39389 3.07265 10.041C3.34068 10.688 3.73353 11.276 4.22877 11.7712C4.72402 12.2665 5.31196 12.6593 5.95903 12.9273C6.6061 13.1954 7.29963 13.3333 8.00001 13.3333C8.70039 13.3333 9.39392 13.1954 10.041 12.9273C10.6881 12.6593 11.276 12.2665 11.7712 11.7712C12.2665 11.276 12.6593 10.688 12.9274 10.041C13.1954 9.39389 13.3333 8.70036 13.3333 7.99998C13.3333 6.58549 12.7714 5.22894 11.7712 4.22874C10.7711 3.22855 9.4145 2.66665 8.00001 2.66665ZM3.28596 3.28593C4.53621 2.03569 6.2319 1.33331 8.00001 1.33331C9.76812 1.33331 11.4638 2.03569 12.7141 3.28593C13.9643 4.53618 14.6667 6.23187 14.6667 7.99998C14.6667 8.87546 14.4942 9.74236 14.1592 10.5512C13.8242 11.36 13.3331 12.095 12.7141 12.714C12.095 13.3331 11.3601 13.8241 10.5512 14.1592C9.7424 14.4942 8.87549 14.6666 8.00001 14.6666C7.12453 14.6666 6.25763 14.4942 5.44879 14.1592C4.63995 13.8241 3.90502 13.3331 3.28596 12.714C2.66691 12.095 2.17584 11.36 1.84081 10.5512C1.50578 9.74237 1.33334 8.87546 1.33334 7.99998C1.33334 6.23187 2.03572 4.53618 3.28596 3.28593ZM7.33334 5.33331C7.33334 4.96512 7.63182 4.66665 8.00001 4.66665H8.00668C8.37487 4.66665 8.67334 4.96512 8.67334 5.33331C8.67334 5.7015 8.37487 5.99998 8.00668 5.99998H8.00001C7.63182 5.99998 7.33334 5.7015 7.33334 5.33331ZM6.66668 7.99998C6.66668 7.63179 6.96515 7.33331 7.33334 7.33331H8.00001C8.3682 7.33331 8.66668 7.63179 8.66668 7.99998V9.99998C9.03487 9.99998 9.33334 10.2985 9.33334 10.6666C9.33334 11.0348 9.03487 11.3333 8.66668 11.3333H8.00001C7.63182 11.3333 7.33334 11.0348 7.33334 10.6666V8.66665C6.96515 8.66665 6.66668 8.36817 6.66668 7.99998Z"
          fill={color}
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 11.0506 2.20693 12.0909 2.60896 13.0615C3.011 14.0321 3.60028 14.914 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94942 18 10 18C11.0506 18 12.0909 17.7931 13.0615 17.391C14.0321 16.989 14.914 16.3997 15.6569 15.6569C16.3997 14.914 16.989 14.0321 17.391 13.0615C17.7931 12.0909 18 11.0506 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2ZM2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 11.3132 19.7413 12.6136 19.2388 13.8268C18.7362 15.0401 17.9997 16.1425 17.0711 17.0711C16.1425 17.9997 15.0401 18.7362 13.8268 19.2388C12.6136 19.7413 11.3132 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C2.00035 16.1425 1.26375 15.0401 0.761205 13.8268C0.258658 12.6136 0 11.3132 0 10C5.96046e-08 7.34784 1.05357 4.8043 2.92893 2.92893ZM9 6C9 5.44772 9.44771 5 10 5H10.01C10.5623 5 11.01 5.44772 11.01 6C11.01 6.55228 10.5623 7 10.01 7H10C9.44771 7 9 6.55228 9 6ZM8 10C8 9.44771 8.44772 9 9 9H10C10.5523 9 11 9.44771 11 10V13C11.5523 13 12 13.4477 12 14C12 14.5523 11.5523 15 11 15H10C9.44771 15 9 14.5523 9 14V11C8.44772 11 8 10.5523 8 10Z"
          fill={color}
        />
      )}
    </svg>
  );
}

IconInfo.defaultProps = {
  width: '20',
  height: '20',
  color: '#257ED0',
};

export default IconInfo;
