// @flow
import React, { Node } from 'react';

import { Text, Box } from '@chakra-ui/react';

import { tourHeaderIconStyles, tourHeaderTitleStyles } from './style';

// Props
type TourTooltipHeaderProps = {
  icon?: Node,
  title?: string,
};

// Component
const TourTooltipHeader = ({ icon, title }: TourTooltipHeaderProps) => {
  return (
    <Box>
      <Box {...tourHeaderIconStyles}>{icon}</Box>
      <Text variant="accent" {...tourHeaderTitleStyles}>
        {title}
      </Text>
    </Box>
  );
};

TourTooltipHeader.defaultProps = {
  icon: null,
  title: '',
};

export default TourTooltipHeader;
