import { useLocation } from 'react-router-dom';
import { decode } from 'js-base64';
import { useErrorModal } from '@core/contexts/ErrorModalContext';

export function useTenantUrlData(throwErrorIfMissingInviteCode = false) {
  const location = useLocation();
  const { onOpenSpecificError } = useErrorModal();

  // decode tenant data from inviteCode
  const { search } = location || {};
  const params = new URLSearchParams(search);

  let tenantData = {};
  let urlId = null;
  let inviteCode = null;

  if (params) {
    const inviteCodeRaw = params.get('inviteCode');

    if (!inviteCodeRaw && throwErrorIfMissingInviteCode) {
      onOpenSpecificError({
        title: 'There is no invite code in your invite link',
        description: 'Please ask your landlord to send you invite link again or contact support',
      });
      throw new Error('There is no invite code');
    }

    const id = params.get('id');
    const idObject = id !== null ? decode(id) : '';

    let parsedObj;
    try {
      parsedObj = JSON.parse(idObject);
    } catch (e) {
      parsedObj = idObject;
    }

    let data = {};
    if (typeof parsedObj === 'string') {
      data = { email: parsedObj };
    } else {
      data = parsedObj;
    }
    urlId = id;
    tenantData = data;
    inviteCode = inviteCodeRaw;
  }
  return { tenantData, urlId, inviteCode };
}
