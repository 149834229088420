export const preloaderContainerStyles = {
  position: 'absolute',
  left: 0,
  right: 0,
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9,
  backgroundColor: 'brand.darkBlue.800A',
  opacity: '0.1',
};
