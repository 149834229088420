export const disabledStylesWithBorder = {
  sx: {
    _disabled: {
      color: 'brand.neutral.500',
      bg: 'white !important',
      opacity: 0.6,
    },
  },
};

export const cardButtonStyles = (type) => {
  const baseStyles = {
    textAlign: 'left',
    color: 'brand.neutral.700',
    minWidth: 'auto',
    minHeight: '129px',
    h: '129px',
    borderRadius: '12px',
    alignItems: 'center',
    bg: 'brand.neutral.white',
    borderWidth: '1px',
    borderColor: 'brand.neutral.700',
    w: '100%',
    padding: '20px',
    cursor: 'pointer',
    _hover: {
      bg: 'brand.darkBlue.50',
    },
    _focus: {
      boxShadow: '0 0 0 2px #9FD8FF',
    },
    _active: {
      bg: 'brand.blue.100',
    },
  };

  const cleanStyles = {
    height: 'auto',
    minHeight: '72px',
    borderRadius: '6px',
    color: 'brand.neutral.700',
    borderColor: 'brand.darkBlue.150',
    boxShadow: '1px 2px 6px rgba(3, 24, 64, 0.04)',
    spacing: 2,
    p: '16px',
    _hover: { bgColor: 'brand.blue.50', borderColor: 'brand.blue.800A' },
    sx: {
      _disabled: {
        color: 'brand.neutral.500',
        bg: 'white !important',
        opacity: 0.6,
        borderColor: 'transparent !important',
      },
    },
    _active: { bgColor: 'brand.blue.100' },
  };

  const actionStyles = {
    h: '56px',
    w: '100%',
    minHeight: 'none',
    borderColor: 'transparent',
    color: 'brand.blue.700',
    borderRadius: '4px',
    textStyle: 'sm',
    _hover: {
      borderColor: 'transparent',
      bgColor: 'brand.blue.50',
    },
    _active: {
      bgColor: 'brand.blue.50',
    },
    _focus: {
      bgColor: 'brand.blue.50',
      borderWidth: '1px',
      borderColor: 'brand.blue.250',
    },
    _disabled: {
      color: 'brand.neutral.500',
      bg: 'white !important',
      opacity: 0.6,
      borderColor: 'transparent !important',
    },
  };

  if (type === 'clean') {
    return { ...baseStyles, ...cleanStyles };
  }
  if (type === 'action') {
    return { ...baseStyles, ...actionStyles };
  }
  return baseStyles;
};
