import {
  IconCheck,
  IconClose,
  IconExclamationCircle,
  IconVerifyEmail,
  IconWarningCircleOutline,
  IconCheckCircleOutline,
  IconInfo,
} from '@icons';

const icons = {
  checkcircleoutline: IconCheckCircleOutline,
  exclamationcircle: IconExclamationCircle,
  verifyemail: IconVerifyEmail,
  info: IconInfo,
  warningcircleoutline: IconWarningCircleOutline,
  check: IconCheck,
  close: IconClose,
};

export default icons;
