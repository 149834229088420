// @flow

import { useNavMenu } from '@core/contexts/NavMenuContext';

import type { StepHandler } from '../step-handler.interface';
import { useProductTour } from '../../contexts/ProductTourContext';

const usePaymentMethodsHandler = (): StepHandler => {
  const { tourHelpers } = useProductTour();
  const { onClose: onCloseNavMenu } = useNavMenu();

  function handleNextStep() {
    tourHelpers?.endTour();
    tourHelpers?.reset(true);
    onCloseNavMenu();
  }

  return {
    next: handleNextStep,
  };
};

export default usePaymentMethodsHandler;
