export const appContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

export const headerStyles = {
  flexShrink: 0,
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 9,
};

export const mainStyles = (authenticated) => ({
  height: '100%',
  flex: '1 0 auto',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  top: authenticated ? '64px' : '56px',
});

export const mobileSideNavBarStyles = (isOpen) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1,
  width: isOpen ? '312px' : '0px',
  height: '100%',
  overflowX: 'hidden',
  transition: 'width .1s ease-in-out',
  color: 'brand.darkBlue.900',
});
