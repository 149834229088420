// @flow
import React from 'react';
import type { Node, ComponentType } from 'react';
import { Box, Text, VStack, HStack } from '@chakra-ui/react';
import {
  titleContainerStyles,
  iconContainerStyles,
  additionalTextStyles,
  infoBlockVStackStyles,
  mainTextStyles,
} from '@shared/components/BaselaneInfoBlock/style';

// Helpers
/**
 * Return alignItem rule by textAlign
 * @return {string}
 */
function getBlockAlign(textAlign: string): string {
  switch (textAlign) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    default:
      return 'center';
  }
}

// Props
type BaselaneInfoBlockProps = {
  children: Node,
  icon: ComponentType,
  additionalText?: string,
  textAlign?: 'center' | 'left' | 'right',
  isVerticalInfoBlock?: boolean,
};

// Component
function BaselaneInfoBlock({
  children,
  icon,
  additionalText,
  textAlign,
  isVerticalInfoBlock,
}: BaselaneInfoBlockProps): ComponentType {
  return (
    <VStack {...infoBlockVStackStyles} alignItems={getBlockAlign(textAlign)}>
      <HStack {...titleContainerStyles(isVerticalInfoBlock)}>
        <Box {...iconContainerStyles(isVerticalInfoBlock)}>{icon}</Box>

        <Box {...mainTextStyles} textAlign={textAlign}>
          {children}
        </Box>
      </HStack>
      {additionalText && (
        <Text {...additionalTextStyles} textAlign={textAlign}>
          {additionalText}
        </Text>
      )}
    </VStack>
  );
}

BaselaneInfoBlock.defaultProps = {
  additionalText: '',
  textAlign: 'center',
  isVerticalInfoBlock: false,
};

export default BaselaneInfoBlock;
