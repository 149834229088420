// @flow
import React from 'react';
import DatePicker from 'react-datepicker';

import './style.scss';

const BaselaneDatepicker = ({ maxDate, ...rest }: { maxDate: Date, rest: Object }) => {
  return (
    <DatePicker
      inline
      disabledKeyboardNavigation
      showDisabledMonthNavigation
      minDate={new Date()}
      maxDate={new Date(maxDate)}
      dateFormat="MMM dd, yyyy"
      {...rest}
    />
  );
};

export default BaselaneDatepicker;
