import React from 'react';

function IconProperty() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#002E51" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.614 22.262h1.598v2.07h-1.598v-2.07zm-5.47 0h1.598v2.07h-1.598v-2.07zm1.598-3.113v2.071h-1.598v-2.071h1.598zm2.735 0v2.071H13.88v-2.071h1.598zm4.199 7.717V18.65H9.478v6.896H8.384v1.32h5.119V22.7h2.351v4.167h3.822zm-3.062-5.646v-2.071h1.598v2.071h-1.598zm5.174 1.042h1.598v2.07h-1.598v-2.07zm5.47 0h1.598v2.07h-1.598v-2.07zm0-1.042v-2.071h1.598v2.071h-1.598zm-2.735 0v-2.071h1.598v2.071h-1.598zm-2.735 0v-2.071h1.598v2.071h-1.598zm-1.464-2.57v8.216h3.822V22.7h2.352v4.167h5.118v-1.32h-1.094V18.65H20.324zM32 18.002l-6.996-6.868L20 16.33l-5.004-5.195L8 18.002h24z"
        fill="#fff"
      />
    </svg>
  );
}

export default IconProperty;
