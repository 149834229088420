import React from 'react';

import type { IconProps } from '@icons/types';

function IconProfile({ color, width, height }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C12 8.20914 10.2091 10 8 10C5.79086 10 4 8.20914 4 6C4 3.79086 5.79086 2 8 2C10.2091 2 12 3.79086 12 6ZM10.5 6C10.5 7.38071 9.38071 8.5 8 8.5C6.61929 8.5 5.5 7.38071 5.5 6C5.5 4.61929 6.61929 3.5 8 3.5C9.38071 3.5 10.5 4.61929 10.5 6Z"
        fill={color}
      />
      <path
        d="M2.60467 14.7438C2.98769 13.9815 3.77065 13.5 4.62712 13.5H11.3729C12.2294 13.5 13.0123 13.9815 13.3953 14.7438L14.572 17.0854C14.7581 17.4559 15.2109 17.6061 15.5832 17.4208C15.9555 17.2356 16.1064 16.7851 15.9203 16.4146L14.7436 14.0729C14.1053 12.8025 12.8003 12 11.3729 12H4.62712C3.19967 12 1.89474 12.8025 1.25637 14.0729L0.0797377 16.4146C-0.106424 16.7851 0.0444899 17.2356 0.416813 17.4208C0.789135 17.6061 1.24188 17.4559 1.42804 17.0854L2.60467 14.7438Z"
        fill={color}
      />
    </svg>
  );
}

IconProfile.defaultProps = {
  color: '#192C3E',
  width: '16px',
  height: '20px',
};

export default IconProfile;
