export const menuStyles = {
  profileButton: {
    borderRadius: '24px',
    py: '4px',
    pl: '4px',
    pr: '12px',
    cursor: 'pointer',
    iconSpacing: '4px !important',
    _active: {
      borderColor: 'brand.darkBlue.250',
      bg: 'brand.darkBlue.100',
    },
    sx: {
      '&.open': {
        borderColor: 'brand.darkBlue.200',
        bg: 'brand.darkBlue.50',
      },
      '&.open.pressed': {
        borderColor: 'brand.darkBlue.250',
        bg: 'brand.darkBlue.100',
      },
    },
  },
  username: {
    display: { base: 'none', '2lg': 'block' },
    maxW: '324px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textStyle: 'sm',
    color: 'brand.neutral.900',
    fontWeight: '500',
  },
  divider: {
    borderColor: 'brand.darkBlue.100',
    my: '12px',
  },
  list: {
    position: 'fixed',
    top: '56px',
    right: '32px',
    border: '1px solid',
    borderColor: 'brand.darkBlue.200',
    borderRadius: '6px',
    paddingX: '16px',
    paddingY: '16px',
    maxWidth: '480px',
    maxHeight: '640px',
    minWidth: '256px',
    boxShadow: '-4px 8px 24px 0px rgba(219, 219, 224, 0.2)',
    alignItems: 'center',
  },
  item: { borderRadius: '6px', padding: '6px', _hover: { bg: 'brand.blue.50' } },
};
