// @flow
import React, { Component, useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { usePlaidLink } from 'react-plaid-link';
import { Stack, Text } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import BaselaneButton from '@shared/components/BaselaneButton';
import BaselaneButtonCard from '@shared/components/BaselaneButtonCard';
import BaselaneAchFee from '@shared/components/BaselaneAchFee';
import {
  addTenantPaymentMethod,
  addTenantPaymentMethodNoCache,
} from '@core/apollo/services/tenant.apollo';
import type { AddPaymentMethodInput } from '@core/apollo/types/tenant.type';
import useDeviceDetect from '@shared/hooks/useDeviceDetect';
import { loggerService, bankAddedStorage } from '@core/services';
import { errorHasCode } from '@core/utils/errorParser';
import { IconBankAccountAdd } from '@icons';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';
import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';
import { useLease } from '@contexts/LeaseContext';
import { ErrorModalContextActions } from '@contexts/ErrorModalContext';
import { DASHBOARD } from '@routing/constants/route.constants';
import PaymentAdded from '../../PaymentAdded';

// Props
type AddBankAccountProps = {
  tenantPlaidLinkToken: string,
  setIsLoading?: Function,
  closeFlow: Function,
  setError?: Function,
  showAutoPayDrawer?: Boolean,
  onAddedBankAccount?: Function,
  onCardClick?: Function,
};

// Component
function AddBankAccount({
  tenantPlaidLinkToken,
  setIsLoading,
  closeFlow,
  setError,
  showAutoPayDrawer,
  onAddedBankAccount,
  onCardClick,
}: AddBankAccountProps): Component {
  const isDesktopScreen = useIsLargerThanSmallScreen();
  const { isMobile } = useDeviceDetect();
  const paymentAddedRef = useRef(null);
  const [addPaymentMethod] = showAutoPayDrawer
    ? addTenantPaymentMethod()
    : addTenantPaymentMethodNoCache();
  const [bankAddedStatus, setBankAddedStatus] = useState('');
  const [bankAddedId, setBankAddedId] = useState('');
  const history = useHistory();
  const { selectedLease } = useLease();
  const [xIdempotencyKey, setXIdempotencyKey] = useState(uuidv4());

  const onSuccess = useCallback(
    (bankToken, { accounts, institution }): void => {
      setIsLoading(true);
      if (accounts && +accounts.length > 1) {
        return;
      }

      if (accounts && +accounts.length === 1) {
        const input: AddPaymentMethodInput = {
          bankToken,
          paymentType: 'BANK_ACCOUNT',
          accountId: accounts[0].id,
          name: institution.name,
          nickName: accounts[0].name,
          accountType: accounts[0].subtype,
          bankId: institution.institution_id,
        };

        addPaymentMethod({
          variables: { input },
          context: {
            headers: {
              'x-idempotency-key': xIdempotencyKey,
            },
          },
        })
          .then((data) => {
            const { status, id } = data?.data?.addTenantPaymentMethod || {};
            setBankAddedStatus(status);
            setBankAddedId(id);
            if (showAutoPayDrawer) {
              setIsLoading(false);
              paymentAddedRef?.current?.open();
            }
            onAddedBankAccount();
          })
          .catch((error) => {
            setError(true);
            setIsLoading(false);
            if (errorHasCode(error, 'BANK_NOT_SUPPORTED')) {
              ErrorModalContextActions.onOpenCriticalError({
                title: 'We have encountered an issue',
                description:
                  'This institution does not currently support automated payments. Please select another one!',
              });
            } else {
              ErrorModalContextActions.onOpenCriticalError({
                title: 'We have encountered an issue',
                description: 'Please retry, and if issue persists, please contact support.',
              });
              closeFlow();
            }
          })
          .finally(() => {
            setXIdempotencyKey(uuidv4());
          });
      }
    },
    [addPaymentMethod]
  );

  const { open, error, exit } = usePlaidLink({
    token: tenantPlaidLinkToken,
    onSuccess,
  });

  if (error) {
    exit();
    loggerService.error(error);
  }

  return (
    <>
      <BaselaneDrawer
        isShowFooter
        ref={paymentAddedRef}
        title="Add New Payment Method"
        headerVariant={isMobile ? 'secondary' : 'primary'}
        desktopSize="xl"
        customFooter={
          <Stack
            isInline={isDesktopScreen}
            spacing="16px"
            w="100%"
            flexDirection={isDesktopScreen ? 'row' : 'column-reverse'}
          >
            <BaselaneButton
              id="close-payment-added-drawer"
              size={isDesktopScreen ? 'lg' : 'xl'}
              onClick={() => {
                paymentAddedRef?.current?.close();
                closeFlow();
              }}
              variant="newSecondary"
            >
              Close
            </BaselaneButton>
            {bankAddedStatus === 'CONNECTED' ? (
              <BaselaneButton
                size={isDesktopScreen ? 'lg' : 'xl'}
                onClick={() => {
                  paymentAddedRef?.current?.close();
                  closeFlow();
                  bankAddedStorage.write(bankAddedId);
                  history.push(DASHBOARD);
                }}
                variant="newPrimary"
                mb={isDesktopScreen ? 'initial' : '8px !important'}
              >
                {selectedLease?.tenantAutoPayEnrolled
                  ? 'Use Payment Method for Auto Pay'
                  : 'Enroll in Auto Pay'}
              </BaselaneButton>
            ) : null}
          </Stack>
        }
        content={<PaymentAdded status={bankAddedStatus} />}
      />

      <BaselaneButtonCard
        type="clean"
        id="payment-method-bank-account"
        onClick={() => {
          open();
          onCardClick();
        }}
      >
        <Stack isInline spacing="21px" alignItems="center">
          <IconBankAccountAdd />
          <Stack spacing={0}>
            <Text {...{ textTransform: 'uppercase', textStyle: 'headline-xs', lineHeight: '20px' }}>
              Bank Account
            </Text>
            <BaselaneAchFee />
          </Stack>
        </Stack>
      </BaselaneButtonCard>
    </>
  );
}

AddBankAccount.defaultProps = {
  setIsLoading: () => {},
  setError: () => {},
  showAutoPayDrawer: true,
  onAddedBankAccount: () => {},
  onCardClick: () => {},
};

export default AddBankAccount;
