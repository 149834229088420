import React from 'react';

type IconCalendarProps = {
  w?: string,
  h?: string,
  color?: string,
};

const IconCalendar = ({ w, h, color }: IconCalendarProps) => {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33398 1.33325C5.70217 1.33325 6.00065 1.63173 6.00065 1.99992V2.66659H10.0007V1.99992C10.0007 1.63173 10.2991 1.33325 10.6673 1.33325C11.0355 1.33325 11.334 1.63173 11.334 1.99992V2.66659H12.6673C13.1978 2.66659 13.7065 2.8773 14.0815 3.25237C14.4566 3.62744 14.6673 4.13615 14.6673 4.66659V12.6666C14.6673 13.197 14.4566 13.7057 14.0815 14.0808C13.7065 14.4559 13.1978 14.6666 12.6673 14.6666H3.33398C2.80355 14.6666 2.29484 14.4559 1.91977 14.0808C1.5447 13.7057 1.33398 13.197 1.33398 12.6666V4.66659C1.33398 4.13615 1.5447 3.62744 1.91977 3.25237C2.29484 2.8773 2.80355 2.66659 3.33398 2.66659H4.66732V1.99992C4.66732 1.63173 4.96579 1.33325 5.33398 1.33325ZM4.66732 3.99992H3.33398C3.15717 3.99992 2.9876 4.07016 2.86258 4.19518C2.73756 4.32021 2.66732 4.48977 2.66732 4.66659V12.6666C2.66732 12.8434 2.73756 13.013 2.86258 13.138C2.9876 13.263 3.15717 13.3333 3.33398 13.3333H12.6673C12.8441 13.3333 13.0137 13.263 13.1387 13.138C13.2637 13.013 13.334 12.8434 13.334 12.6666V4.66659C13.334 4.48977 13.2637 4.32021 13.1387 4.19518C13.0137 4.07016 12.8441 3.99992 12.6673 3.99992H11.334V4.66659C11.334 5.03478 11.0355 5.33325 10.6673 5.33325C10.2991 5.33325 10.0007 5.03478 10.0007 4.66659V3.99992H6.00065V4.66659C6.00065 5.03478 5.70217 5.33325 5.33398 5.33325C4.96579 5.33325 4.66732 5.03478 4.66732 4.66659V3.99992ZM4.00065 7.33325C4.00065 6.96506 4.29913 6.66659 4.66732 6.66659H11.334C11.7022 6.66659 12.0007 6.96506 12.0007 7.33325C12.0007 7.70144 11.7022 7.99992 11.334 7.99992H4.66732C4.29913 7.99992 4.00065 7.70144 4.00065 7.33325Z"
        fill={color}
      />
    </svg>
  );
};

IconCalendar.defaultProps = {
  w: '16',
  h: '16',
  color: 'currentColor',
};

export default IconCalendar;
