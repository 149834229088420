// @flow
import type { PaymentMethodType } from '@core/apollo/types/tenant-payment-method.type';
import isNumber from '@core/utils/validators/isNumber';
// Default convenience fee factor
const convenienceFeeFactor = 3.49;

export function getFee(paymentMethod) {
  const paymentMethodObject = (paymentMethod ?? []).find(
    (pm) => pm.paymentType === 'CREDIT_CARD' || pm.paymentType === 'DEBIT_CARD'
  );
  return paymentMethodObject?.fee ?? convenienceFeeFactor;
}

export function calculateConvenienceFee(
  amount: number,
  paymentMethodType: PaymentMethodType,
  userRole: string,
  factor = convenienceFeeFactor
): number {
  if (userRole?.toUpperCase() !== 'TENANT') {
    return 0;
  }

  if (!isNumber(amount)) {
    return 0;
  }

  if (paymentMethodType === 'CREDIT_CARD' || paymentMethodType === 'DEBIT_CARD') {
    return +((amount / 100) * factor).toFixed(2);
  }

  return 0;
}
