import React from 'react';

function IconPropertyNoBackground() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.614 15.262h1.598v2.07H8.614v-2.07zm-5.47 0h1.598v2.07H3.144v-2.07zm1.598-3.113v2.071H3.144v-2.071h1.598zm2.735 0v2.071H5.88v-2.071h1.597zm4.199 7.717V11.65H1.478v6.896H.384v1.32h5.119V15.7h2.351v4.167h3.822zM8.614 14.22v-2.071h1.598v2.071H8.614zm5.174 1.042h1.598v2.07h-1.598v-2.07zm5.47 0h1.598v2.07h-1.598v-2.07zm0-1.042v-2.071h1.598v2.071h-1.598zm-2.735 0v-2.071h1.598v2.071h-1.598zm-2.735 0v-2.071h1.598v2.071h-1.598zm-1.464-2.57v8.216h3.822V15.7h2.352v4.167h5.118v-1.32h-1.094V11.65H12.324zM24 11.002l-6.996-6.868L12 9.33 6.996 4.134 0 11.002h24z"
        fill="#192C3E"
      />
    </svg>
  );
}

export default IconPropertyNoBackground;
