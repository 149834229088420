const sideNavBarWidthPx = '80px';
const sideNavBarHoverWidthPx = '260px';

export const sideNavBarStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1,
  width: sideNavBarWidthPx,
  height: '100%',
  overflowX: 'hidden',
  color: 'transparent',
  transition: 'width .1s ease-in-out, color 0s ease-out',
  _hover: {
    width: sideNavBarHoverWidthPx,
    color: 'brand.darkBlue.900',
    transition: 'width .1s ease-in-out, color .5s ease-in',
  },
};

export const topNavBarStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
};

export const desktopMainStyles = (hideNav) => ({
  marginLeft: hideNav ? 0 : sideNavBarWidthPx,
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  maxH: '100%',
  position: 'relative',
  top: '56px',
});
