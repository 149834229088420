export const navItemStyles = {
  display: 'flex',
  alignItems: 'center',
  height: '48px',
  marginTop: '0px',
  color: 'inherit',
  _active: {
    fontWeight: '500',
  },
  _hover: {
    bg: 'brand.darkBlue.100',
  },
};

export const navLinkStyles = {
  display: 'flex',
  paddingLeft: '32px',
  width: '100%',
  height: '100%',
  color: 'inherit',
  fontWeight: '100',
  _active: {
    fontWeight: '500',
  },
};
