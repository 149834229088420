// @flow
import React, { useState, Component, useEffect } from 'react';

import {
  Text,
  Menu,
  Flex,
  MenuButton,
  Button,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
} from '@chakra-ui/react';
import type { LeaseDropdownSelectListType } from '@shared/types';
import IconProperties from '@icons/IconProperties';
import IconArrowDropDown from '@icons/IconArrowDropDown';
import IconRadioButtonActive from '@icons/IconRadioButtonActive';
import IconArrowDropUp from '@icons/IconArrowDropUp';
import IconRadioButton from '@icons/IconRadioButton';
import {
  leaseDropdownButtonArrowDesktopStyles,
  leaseDropdownButtonArrowMobileStyles,
  leaseDropdownButtonIconStyles,
  leaseDropdownButtonStyles,
  leaseDropdownItemOptionStyles,
  leaseDropdownListStyles,
} from '@shared/components/LeaseDropdown/style';
import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';

import './style.scss';

type LeaseDropdownProps = {
  selectList: LeaseDropdownSelectListType[],
  onSelect: Function,
  selectedAddress: string,
};

const SELECTED_LEASE_SESSION_STORAGE_KEY = 'selectedLease';

function LeaseDropdown({ selectList, onSelect, selectedAddress }: LeaseDropdownProps) {
  const [opened, setOpened] = useState(false);
  const [title, setTitle] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const isDesktopScreen = useIsLargerThanSmallScreen();

  const isDisabled = !selectList || selectList.length <= 1;

  useEffect(() => {
    if (selectList && selectList.length) {
      const cachedSelectedLeaseId = sessionStorage.getItem(SELECTED_LEASE_SESSION_STORAGE_KEY);

      const selected =
        selectList.find((item) => item.value === selectedAddress) ||
        (cachedSelectedLeaseId &&
          selectList.find(
            (item: LeaseDropdownSelectListType): boolean => item.value === cachedSelectedLeaseId
          )) ||
        selectList[0];
      onSelect(selected.value);
      setTitle(selected.label);
      setSelectedValue(selected.value);
    }
  }, [selectList]);

  useEffect(() => {
    document.body.style.overflow = opened ? 'hidden' : '';
  }, [opened]);

  /**
   * On Change Selected Value
   * @param {string} value
   */
  const onChange = (value: string) => {
    setSelectedValue(value);
    setTitle(
      selectList.find((item: LeaseDropdownSelectListType): boolean => item.value === value).label
    );

    if (onSelect) {
      onSelect(value);
    }

    sessionStorage.setItem(SELECTED_LEASE_SESSION_STORAGE_KEY, value);
  };

  /**
   * Return Icon Arrow
   * @return {JSX.Element}
   */
  function getIconArrow(): Component {
    if (isDisabled) {
      return null;
    }

    return opened ? (
      <IconArrowDropUp
        {...(isDesktopScreen
          ? leaseDropdownButtonArrowDesktopStyles
          : leaseDropdownButtonArrowMobileStyles)}
      />
    ) : (
      <IconArrowDropDown
        {...(isDesktopScreen
          ? leaseDropdownButtonArrowDesktopStyles
          : leaseDropdownButtonArrowMobileStyles)}
      />
    );
  }

  /**
   * Return Icon Radio Button
   * @param {string} value
   * @return {JSX.Element}
   */
  function getIconRadioButton(value: string): Component {
    return value === selectedValue ? <IconRadioButtonActive /> : <IconRadioButton />;
  }

  return (
    <Menu onOpen={() => setOpened(true)} onClose={() => setOpened(false)}>
      <MenuButton
        {...leaseDropdownButtonStyles}
        as={Button}
        isDisabled={isDisabled}
        rightIcon={isDesktopScreen ? null : getIconArrow()}
        leftIcon={isDesktopScreen ? getIconArrow() : null}
      >
        <Flex>
          {!isDesktopScreen && <IconProperties {...leaseDropdownButtonIconStyles} />}
          <Text whiteSpace="white-space" color="brand.neutral.900">
            {title}
          </Text>
        </Flex>
      </MenuButton>
      <MenuList {...leaseDropdownListStyles}>
        <MenuOptionGroup defaultValue={selectedValue} type="radio" onChange={onChange}>
          {selectList &&
            selectList.map((item: LeaseDropdownSelectListType) => {
              return (
                <MenuItemOption
                  {...leaseDropdownItemOptionStyles}
                  className="option-icon-opacity"
                  icon={getIconRadioButton(item.value)}
                  key={item.value || ''}
                  value={item.value || ''}
                >
                  {item.label}
                </MenuItemOption>
              );
            })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}

export default LeaseDropdown;
