export const modalContentStyles = (isLandscape) => ({
  bg: 'white',
  borderRadius: '12px',
  p: { xxs: '0 8px', xs: '8px', sm: '8px', md: '15px', lg: '15px', xl: '75px 25px 33px' },
  w: { xxs: '275px', xs: '320px', sm: '472px', md: '630px', lg: '780px' },
  textAlign: 'center',
  boxShadow: 'none',
  m: { xxs: '0', xs: '0', sm: '0' },
  maxHeight: isLandscape ? '100%' : 'auto',
  overflowY: 'auto',
});

export const modalHeaderStyles = {
  color: 'brand.neutral.900',
  textStyle: 'headline-xl',
};

export const modalBodyStyles = {
  color: 'brand.neutral.600',
  textStyle: 'sm',
};
