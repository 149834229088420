export const absoluteButtonContainerStyles = {
  position: 'absolute',
  inset: 0,
};

export const draggableElementStyles = {
  position: 'absolute',
  inset: 0,
  width: '40%',
  zIndex: 9,
};

export const IconArrowNarrowRightStyles = {
  position: 'absolute',
  left: '18px',
};
