// @flow
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, HStack, Spacer } from '@chakra-ui/react';
import { useAuth } from '@contexts/AuthContext';
import BaselaneNavMenu from '@shared/components/BaselaneNavMenu';
import {
  SIGN_UP_WIZARD,
  ONBOARDING,
  ALREADY_SIGNED_UP,
  EXPIRED_INVITATION,
} from '@routing/constants/route.constants';
import {
  topNavMenuHeaderStyles,
  topNavMenuWrapperStyles,
} from '@shared/components/BaselaneNavMenu/style';
import { desktopMainStyles, sideNavBarStyles, topNavBarStyles } from '@layouts/Desktop/style';
import Profiles from '../Profiles';

const DesktopAuthenticatedLayout = ({ children }: { children: React.Node }) => {
  const { pathname } = useLocation();
  const { tenant } = useAuth();
  const dashboard = !!(pathname === '/dashboard/invoices');
  const settings = !!(pathname === '/settings/profile');
  let title = dashboard ? `Welcome, ${tenant.firstName}` : 'Payment Methods';
  if (settings) {
    title = 'Settings';
  }
  return (
    <>
      {pathname !== SIGN_UP_WIZARD &&
        pathname !== ALREADY_SIGNED_UP &&
        pathname !== ONBOARDING &&
        pathname !== EXPIRED_INVITATION && (
          <Box {...topNavBarStyles}>
            <HStack {...topNavMenuWrapperStyles}>
              <Box {...topNavMenuHeaderStyles(true)}>{title}</Box>
              <Spacer />
              <Box p="12px 32px 0 0">
                <Profiles />
              </Box>
            </HStack>
          </Box>
        )}
      {pathname !== SIGN_UP_WIZARD &&
        pathname !== ALREADY_SIGNED_UP &&
        pathname !== ONBOARDING &&
        pathname !== EXPIRED_INVITATION && (
          <Box {...sideNavBarStyles}>
            <BaselaneNavMenu /> {/* requires tenant */}
          </Box>
        )}
      <Box
        {...desktopMainStyles(
          pathname === SIGN_UP_WIZARD ||
            pathname === ALREADY_SIGNED_UP ||
            pathname === ONBOARDING ||
            pathname === EXPIRED_INVITATION
        )}
        id="desktop"
        paddingTop="8px"
      >
        {children}
      </Box>
    </>
  );
};

export default DesktopAuthenticatedLayout;
