// @flow
import React, { Node } from 'react';

import { Center } from '@chakra-ui/react';

import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';

// Helper
function getDefaultComponent(deviceType: 'mobile' | 'laptop'): Node {
  return <Center pt="200px">`For best viewing, please use a {deviceType}`</Center>;
}

// Props
type BaselaneDeliveryProps = {
  mobile?: Node,
  desktop?: Node,
};

// Component
const BaselaneDelivery = ({ mobile, desktop }: BaselaneDeliveryProps): Node => {
  const isDesktopScreen = useIsLargerThanSmallScreen();

  return isDesktopScreen ? desktop : mobile;
};

BaselaneDelivery.defaultProps = {
  mobile: getDefaultComponent('laptop'),
  desktop: getDefaultComponent('mobile'),
};

export default BaselaneDelivery;
