// @flow
import React from 'react';

function IconWarningIllustration() {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4472 69.1056L41.2111 13.5777C41.9482 12.1036 44.0518 12.1036 44.7889 13.5777L72.5528 69.1056C73.2177 70.4354 72.2507 72 70.7639 72H15.2361C13.7493 72 12.7823 70.4354 13.4472 69.1056Z"
        fill="#FCE3A3"
      />
      <path
        d="M7.44721 65.1056L35.2111 9.57771C35.9482 8.10361 38.0518 8.10361 38.7889 9.57771L66.5528 65.1056C67.2177 66.4354 66.2507 68 64.7639 68H9.23607C7.7493 68 6.78231 66.4354 7.44721 65.1056Z"
        stroke="#000619"
      />
      <path d="M37 36L37 46" stroke="#000619" strokeLinecap="round" />
      <circle cx="37" cy="51" r="1" fill="#000619" />
    </svg>
  );
}

export default IconWarningIllustration;
