// @flow
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { LOGIN, SETTINGS } from '@routing/constants/route.constants';
import { useAuth } from '@contexts/AuthContext';
import { useProductTour } from '@tour/contexts/ProductTourContext';
import {
  IconProfile,
  IconLogOut,
  IconHelpCenter,
  IconVirtualAssistant,
  IconProductTour,
} from '@icons';
import { openMessenger, shutdownMessenger } from '@core/utils/messengerHelpers';
import { authService } from '@core/auth';

import BaselaneMenu from '@shared/components/BaselaneMenu';

const Profiles = () => {
  const history = useHistory();
  const { tenant } = useAuth();
  const { firstName, lastName } = tenant ?? {};
  const { tourHelpers } = useProductTour();
  const { value: zendeskEnabled } = useGate('enable_zendesk_bubble');

  const handleProfilesClick = () => history.push(SETTINGS);

  const handleVirtualAssistantClick = () => {
    openMessenger(zendeskEnabled);
  };

  const handleLogoutClick = async () => {
    shutdownMessenger(zendeskEnabled);
    await authService.logout();
    history.push(LOGIN);
  };

  const handleStartProductTour = () => {
    tourHelpers?.startTour();
  };

  const menuItems = [
    {
      id: 'profile_setting',
      name: 'Profile & settings',
      onClick: handleProfilesClick,
      icon: <IconProfile />,
    },
    {
      id: 'help_center',
      name: 'Help center',
      as: 'a',
      href: 'https://support.baselane.com/hc/en-us',
      target: '_blank',
      icon: <IconHelpCenter />,
    },
    {
      id: 'support',
      name: 'Virtual assistant',
      onClick: () => handleVirtualAssistantClick(zendeskEnabled),
      icon: <IconVirtualAssistant />,
    },
    {
      id: 'product_tour',
      name: 'Product tour',
      onClick: handleStartProductTour,
      icon: <IconProductTour />,
      hasDivider: true,
    },
    { id: 'log_out', name: 'Log out', onClick: handleLogoutClick, icon: <IconLogOut /> },
  ];
  return (
    <BaselaneMenu
      listItems={menuItems}
      label={`${firstName || ''} ${lastName || ''}`}
      placement="bottom-end"
      isProfile
    />
  );
};

export default Profiles;
