const filledTrack = {
  bg: 'brand.blue.700',
  borderRightRadius: '5px',
};

const track = {
  maxHeight: '10px',
  bg: 'brand.darkBlue.200',
};

const baseStyle = {
  track,
  filledTrack,
};

export const Progress = {
  baseStyle,
};
