// @flow
import React, { createContext, useContext, Component, useState, useEffect, useMemo } from 'react';
import { first } from 'lodash';

import type { TenantLease } from '@core/apollo/types/tenant-lease.type';
import { getTenantLeases } from '@core/apollo/services/tenant-leases.apollo';
import { useAuth } from '@core/contexts/AuthContext';
import type { LeaseDropdownSelectListType } from '@shared/types';

// Type
type LeaseContextType = {
  leases: TenantLease[],
  selectedLease: TenantLease,
  setSelectedLease: (lease: TenantLease) => {},
  refetchTenantLeases: Function,
  isTenantLeasesLoading: Boolean,
};

// Helpers
function mapAddressesToListOfOptions(tenantLeases: TenantLease[]): LeaseDropdownSelectListType[] {
  if (!tenantLeases) {
    return [];
  }

  // const activeLeases = tenantLeases.filter((lease) => lease.archived === 'false');

  return tenantLeases.map(({ property, id }): LeaseDropdownSelectListType => {
    const address = property?.address?.unit
      ? `${property?.address?.address}, ${property?.address?.unit}, ${property?.address?.city}, ${property?.address?.state} ${property?.address?.zipCode}`
      : `${property?.address?.address}, ${property?.address?.city}, ${property?.address?.state} ${property?.address?.zipCode}`;
    const addressLabel =
      property?.isMultiUnitProperty && property?.name ? `${address} - ${property?.name}` : address;
    return {
      value: id,
      label: property?.id ? addressLabel : 'Archived Lease - Address Unavailable',
    };
  });
}

// Context
const LeaseContext = createContext<LeaseContextType>();

// Provider
export const LeaseProvider = ({ children }: { children: Component }): LeaseContext => {
  const {
    data,
    error: errorTenantLeases,
    refetch: refetchTenantLeases,
    loading: isTenantLeasesLoading,
  } = getTenantLeases();

  const [selectedLease, setSelectedLease] = useState<TenantLease>();
  const [selectedAddress, setSelectedAddress] = useState<string>();
  const [addressOptions, setAddressOptions] = useState<LeaseDropdownSelectListType[]>([]);

  // display per session dismissable Lease payment method warning banner
  const [dashboardWarningBanner, setDashboardWarningBanner] = useState(true);

  const [leases, setLeases] = useState();
  const { logout } = useAuth();

  useEffect(() => {
    if (data) {
      const leasesResponse = data.tenantLeases ?? [];
      setLeases(leasesResponse);

      if (!selectedLease) {
        // const activeLeases = leasesResponse.filter((lease) => lease.archived === 'false');
        setSelectedLease(first(leasesResponse));
      } else {
        const findSelectedLease = leasesResponse.find((lease) => lease.id === selectedLease.id);
        setSelectedLease(findSelectedLease);
      }
    }
  }, [data]);

  useEffect(() => {
    if (errorTenantLeases?.networkError?.statusCode === 401) {
      logout();
    }
  }, [errorTenantLeases]);

  useEffect(() => {
    if (leases) {
      setAddressOptions(mapAddressesToListOfOptions(leases));

      if (!selectedAddress) {
        setSelectedAddress(first(addressOptions)?.value);
      }
    }
  }, [leases]);

  const state = useMemo(() => {
    const newState = {};
    newState.leases = leases;
    newState.selectedLease = selectedLease;
    newState.setSelectedLease = setSelectedLease;
    newState.refetchTenantLeases = refetchTenantLeases;
    newState.isTenantLeasesLoading = isTenantLeasesLoading;
    newState.selectedAddress = selectedAddress;
    newState.setSelectedAddress = setSelectedAddress;
    newState.addressOptions = addressOptions;
    newState.setAddressOptions = setAddressOptions;
    newState.dashboardWarningBanner = dashboardWarningBanner;
    newState.setDashboardWarningBanner = setDashboardWarningBanner;
    return newState;
  }, [
    leases,
    selectedLease,
    setSelectedLease,
    refetchTenantLeases,
    isTenantLeasesLoading,
    selectedAddress,
    setSelectedAddress,
    addressOptions,
    setAddressOptions,
    dashboardWarningBanner,
    setDashboardWarningBanner,
  ]);

  return <LeaseContext.Provider value={state}>{children}</LeaseContext.Provider>;
};

// Hook to simplify access to Selected Lease Address from the contexts
export const useLease = (): LeaseContextType => useContext(LeaseContext);
