// @flow
import React, { forwardRef } from 'react';
import { IconButton, Link } from '@chakra-ui/react';
import { LocationDescriptor, Link as RouterLink } from 'react-router-dom';

import { iconButtonStyles, iconLinkStyles } from './styles/buttonIcon.style';

type BaselaneButtonIconProps = {
  styles: Object,
  iconStyles?: Object,
  type: String,
  icon: any,
  isDisabled: Boolean,
  onChange: Function,
  onClick: Function,
  onBlur: Function,
  to?: LocationDescriptor,
  size: string,
  isActive: boolean,
  value: any,
};

const BaselaneButtonIcon: any = forwardRef(function BaselaneButtonIconElement(
  {
    styles,
    iconStyles,
    type,
    // remap icon to Icon so it is treated as a component
    icon: Icon,
    isDisabled,
    onChange,
    onClick,
    onBlur,
    to,
    size,
    isActive,
    value,
    ...rest
  }: BaselaneButtonIconProps,
  ref: any
): any {
  if (to) {
    const linkIconStyles = {
      ...{
        w: '18px',
        h: '18px',
        display: 'flex-item',
      },
      ...iconStyles,
    };

    return (
      <Link {...{ ...iconLinkStyles, ...styles }} as={RouterLink} to={to}>
        <Icon {...linkIconStyles} />
      </Link>
    );
  }

  return (
    // NOTE: Refer to Chakra UI documentation on options for variant and size props
    <IconButton
      variant={type}
      {...{
        ...iconButtonStyles,
        ...styles,
        Icon,
        isDisabled,
        ref,
        onChange,
        onClick,
        onBlur,
        size,
        isActive,
        value,
        ...rest,
      }}
    />
  );
});

BaselaneButtonIcon.defaultProps = {
  to: null,
  iconStyles: {},
};

export default BaselaneButtonIcon;
