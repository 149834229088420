// @flow
import React from 'react';

import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';
import type { DrawerHeaderProps, DrawerHeaderVariant } from '../types';
import PrimaryDrawerHeader from './PrimaryDrawerHeader';
import SecondaryDrawerHeader from './SecondaryDrawerHeader';

// Variants mapping
const headerVariants: { [variantKey: DrawerHeaderVariant]: Function } = {
  primary: PrimaryDrawerHeader,
  secondary: SecondaryDrawerHeader,
};

// Component
const BaselaneDrawerHeader = ({
  title = '',
  variant = 'primary',
  onClose = () => {},
}: DrawerHeaderProps) => {
  const isDesktopScreen = useIsLargerThanSmallScreen();

  const DrawerHeaderComponent = headerVariants[isDesktopScreen ? 'primary' : variant];
  return <DrawerHeaderComponent title={title} onClose={onClose} />;
};

export default BaselaneDrawerHeader;
