// @flow
import moment from 'moment';

const formatDate = (
  date: Date | number,
  format: string = 'MMM D, YYYY',
  dateInputFormat: mixed = null
) => moment(date, dateInputFormat).format(format);

export default formatDate;
