export const buttonStyles = {
  bg: 'white',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'brand.darkBlue.200',
  textStyle: 'xs',
  color: 'brand.neutral.500',
  m: 0,
  h: '32px',
  px: '16px',
};

export const leftButton = {
  borderRadius: '4px 0 0 4px',
  borderRight: 0,
};

export const rightButton = {
  borderRadius: '0 4px 4px 0',
  borderLeftColor: 'brand.darkBlue.250',
};

export const activeStyles = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'brand.darkBlue.200',
  bg: 'brand.blue.100',
  color: 'brand.blue.800A',
};

export const focusStyles = {
  outline: 'none',
};

export const hoverStyles = {
  borderColor: 'brand.darkBlue.250',
};

export const disabledStyles = {
  pointerEvents: 'none',
};

export const disabledStylesInactive = {
  ...disabledStyles,
  color: 'brand.darkBlue.250',
  borderColor: 'brand.darkBlue.100',
};
