// @flow
import React from 'react';
import useIsLargerThanSmallScreen from '@shared/hooks/useIsLargerThanSmallScreen';
import StatsigWrapper from '@core/StatsigWrapper';
import ZendeskScript from '@core/ZendeskScript';
import DesktopAnonymousLayout from '@layouts/Desktop';
import MobileAnonymousLayout from '@layouts/Mobile';

/**
 * Initialization and layout for public pages
 */
type AnonymousProps = {
  children: React.Node,
};

const Anonymous = ({ children }: AnonymousProps) => {
  const isDesktopScreen = useIsLargerThanSmallScreen();

  return (
    <StatsigWrapper>
      <ZendeskScript>
        {isDesktopScreen ? (
          <DesktopAnonymousLayout>{children}</DesktopAnonymousLayout>
        ) : (
          <MobileAnonymousLayout>{children}</MobileAnonymousLayout>
        )}
      </ZendeskScript>
    </StatsigWrapper>
  );
};

export default Anonymous;
