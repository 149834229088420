// @flow
import { gql } from '@apollo/client';

export const TENANT_USER_METADATA = gql`
  fragment TenantUserMetadata on Tenant {
    userMetadata {
      metadata
    }
  }
`;

export const GET_TENANT = gql`
  query Tenant {
    tenant {
      id
      uid
      firstName
      lastName
      onboardingCompleted
      email
      emailVerified
      createdAt
      role
      phoneNumber
      phoneNumberVerified
      provider
      paymentMethod {
        id
        paymentType
        name
        number
        nickName
        accountType
        logo
        status
        fee
      }
      ...TenantUserMetadata
    }
  }
  ${TENANT_USER_METADATA}
`;

export const UPDATE_TENANT_METADATA = gql`
  mutation updateTenant($metadata: JSON) {
    updateTenant(input: { metadata: $metadata }) {
      ...TenantUserMetadata
    }
  }
  ${TENANT_USER_METADATA}
`;

export const CREATE_TENANT = gql`
  mutation CreateTenant($inviteCode: String!, $name: String, $phoneNumber: String) {
    createTenant(inviteCode: $inviteCode, name: $name, phoneNumber: $phoneNumber) {
      id
      uid
      firstName
      lastName
      onboardingCompleted
      email
      createdAt
      role
      phoneNumber
      phoneNumberVerified
      provider
      paymentMethod {
        id
        paymentType
        name
        number
        nickName
        accountType
        logo
        status
        fee
      }
    }
  }
`;

export const ADD_TENANT_PAYMENT_METHOD = gql`
  mutation addTenantPaymentMethod($input: TenantPaymentMethodInput) {
    addTenantPaymentMethod(input: $input) {
      id
      paymentType
      name
      number
      nickName
      accountType
      logo
      status
      address {
        address
        unit
        city
        state
        zipCode
      }
    }
  }
`;

export const UPDATE_TENANT_PAYMENT_METHOD = gql`
  mutation updateTenantPaymentMethod($input: UpdateTenantPaymentMethodInput) {
    updateTenantPaymentMethod(input: $input) {
      id
      paymentType
      name
      number
      nickName
      accountType
      status
      address {
        address
        unit
        city
        state
        zipCode
      }
    }
  }
`;

export const DELETE_TENANT_PAYMENT_METHOD = gql`
  mutation deleteTenantPaymentMethod($id: ID!) {
    deleteTenantPaymentMethod(id: $id)
  }
`;

export const UPDATE_TENANT = gql`
  mutation updateTenant($input: UpdateUserInput) {
    updateTenant(input: $input) {
      id
      uid
      firstName
      lastName
      onboardingCompleted
      email
      createdAt
      role
      phoneNumber
      phoneNumberVerified
      provider
      paymentMethod {
        id
        paymentType
        name
        number
        nickName
        accountType
        logo
      }
    }
  }
`;
