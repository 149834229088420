import React from 'react';

import type { IconProps } from '@icons/types';

function IconLogOut({ color, width, height }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75 2C0.783502 2 0 2.7835 0 3.75V16.25C0 17.2165 0.783501 18 1.75 18H7.25C7.66421 18 8 17.6642 8 17.25C8 16.8358 7.66421 16.5 7.25 16.5H1.75C1.61193 16.5 1.5 16.3881 1.5 16.25V3.75C1.5 3.61193 1.61193 3.5 1.75 3.5H7.25C7.66421 3.5 8 3.16421 8 2.75C8 2.33579 7.66421 2 7.25 2H1.75Z"
        fill={color}
      />
      <path
        d="M9.76109 4.46967C9.4682 4.76256 9.4682 5.23744 9.76109 5.53033L13.4808 9.25L4.75 9.25C4.33579 9.25 4 9.58579 4 10C4 10.4142 4.33579 10.75 4.75 10.75L13.4808 10.75L9.76109 14.4697C9.4682 14.7626 9.4682 15.2374 9.76109 15.5303C10.054 15.8232 10.5289 15.8232 10.8218 15.5303L15.751 10.601C16.083 10.2691 16.083 9.73091 15.751 9.39896L10.8218 4.46967C10.5289 4.17678 10.054 4.17678 9.76109 4.46967Z"
        fill={color}
      />
    </svg>
  );
}

IconLogOut.defaultProps = {
  color: '#192C3E',
  width: '16px',
  height: '20px',
};

export default IconLogOut;
