import React from 'react';
import { BaselaneButton } from '@shared/components';

type FinishLaterButtonProps = {
  handleFinishEvent: Function,
};

const FinishLaterButton = ({ handleFinishEvent }: FinishLaterButtonProps) => {
  return (
    <BaselaneButton variant="minimal" onClick={handleFinishEvent}>
      Finish Later
    </BaselaneButton>
  );
};

export default FinishLaterButton;
