// @flow
import React, { Component } from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import { Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import { map } from 'lodash';

import type { TabDataType } from '@shared/types/baselaneTabs.type';
import {
  tabListStyles,
  tabNavLinkStyles,
  tabPanelStyles,
  tabsStyles,
} from '@shared/components/BaselaneTabs/style';
import { tourStepAttrSelector } from '@tour/steps.config';

// Props
type BaselaneTabsProps = {
  tabs: TabDataType,
  children: React.Node,
};

// Component
const BaselaneTabs = ({ tabs, children }: BaselaneTabsProps): Component => {
  const { pathname } = useLocation();

  return (
    <Tabs {...tabsStyles} variant="unstyled" index={tabs[pathname] ? tabs[pathname].index : 0}>
      <TabList {...tabListStyles}>
        {map(tabs, (tabItem, path) => (
          <NavLink key={path} to={path}>
            <Tab {...tabNavLinkStyles} {...{ [tourStepAttrSelector]: tabItem?.tourStep }}>
              {tabItem.title}
            </Tab>
          </NavLink>
        ))}
      </TabList>
      <TabPanels {...tabPanelStyles}>{children}</TabPanels>
    </Tabs>
  );
};

export default BaselaneTabs;
