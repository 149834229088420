/**
 * Various messenger functions, condition on Zendesk feature flag.
 * Cleanup: after Zendesk has fully been migrated, this file isn't necessary anymore. We can directly call zendeskAPI where needed.
 */

import { zendeskAPI } from '@core/ZendeskScript/zendesk';

export const openMessenger = (zendeskEnabled) => {
  if (zendeskEnabled) {
    zendeskAPI('messenger', 'open');
  } else if (typeof window !== 'undefined' && window.Intercom) {
    window.Intercom('show');
  }
};

export const shutdownMessenger = (zendeskEnabled) => {
  if (zendeskEnabled) {
    zendeskAPI('messenger', 'logoutUser'); // does this work the same as intercom shutdown?
  } else if (typeof window !== 'undefined' && window.Intercom) {
    window.Intercom('shutdown');
  }
};
