// @flow
import React from 'react';

import type { IconProps } from '@icons/types';

const IconWarningWithBackground = ({
  width = 64,
  color = '#CD9800',
  background = '#FEF8E9',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill={background} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 18.6667C28.4638 18.6667 25.0724 20.0714 22.5719 22.5719C20.0714 25.0724 18.6667 28.4638 18.6667 32C18.6667 33.751 19.0115 35.4848 19.6816 37.1024C20.3517 38.7201 21.3338 40.19 22.5719 41.4281C23.81 42.6662 25.2799 43.6483 26.8976 44.3184C28.5152 44.9885 30.249 45.3333 32 45.3333C33.751 45.3333 35.4848 44.9885 37.1024 44.3184C38.7201 43.6483 40.19 42.6662 41.4281 41.4281C42.6662 40.19 43.6483 38.7201 44.3184 37.1024C44.9885 35.4848 45.3333 33.751 45.3333 32C45.3333 28.4638 43.9286 25.0724 41.4281 22.5719C38.9276 20.0714 35.5362 18.6667 32 18.6667ZM20.2149 20.2149C23.3405 17.0893 27.5797 15.3333 32 15.3333C36.4203 15.3333 40.6595 17.0893 43.7851 20.2149C46.9107 23.3405 48.6667 27.5797 48.6667 32C48.6667 34.1887 48.2356 36.356 47.398 38.3781C46.5604 40.4001 45.3328 42.2375 43.7851 43.7851C42.2375 45.3328 40.4002 46.5604 38.3781 47.398C36.356 48.2356 34.1887 48.6667 32 48.6667C29.8113 48.6667 27.644 48.2356 25.6219 47.398C23.5998 46.5604 21.7625 45.3328 20.2149 43.7851C18.6672 42.2375 17.4396 40.4001 16.602 38.3781C15.7644 36.356 15.3333 34.1887 15.3333 32C15.3333 27.5797 17.0893 23.3405 20.2149 20.2149ZM32 23.6667C32.9205 23.6667 33.6667 24.4129 33.6667 25.3333V32C33.6667 32.9205 32.9205 33.6667 32 33.6667C31.0795 33.6667 30.3333 32.9205 30.3333 32V25.3333C30.3333 24.4129 31.0795 23.6667 32 23.6667ZM30.3333 38.6667C30.3333 37.7462 31.0795 37 32 37H32.0167C32.9371 37 33.6833 37.7462 33.6833 38.6667C33.6833 39.5871 32.9371 40.3333 32.0167 40.3333H32C31.0795 40.3333 30.3333 39.5871 30.3333 38.6667Z"
        fill={color}
      />
    </svg>
  );
};

export default IconWarningWithBackground;
