import React from 'react';

import type { IconProps } from '@icons/types';

function IconEye({ w, h, color, onClick }: IconProps) {
  return (
    <svg
      onClick={onClick}
      width={w}
      height={h}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 8.33366C9.55805 8.33366 9.13413 8.50925 8.82157 8.82181C8.50901 9.13437 8.33341 9.5583 8.33341 10.0003C8.33341 10.4424 8.50901 10.8663 8.82157 11.1788C9.13413 11.4914 9.55805 11.667 10.0001 11.667C10.4421 11.667 10.866 11.4914 11.1786 11.1788C11.4912 10.8663 11.6667 10.4424 11.6667 10.0003C11.6667 9.5583 11.4912 9.13437 11.1786 8.82181C10.866 8.50925 10.4421 8.33366 10.0001 8.33366ZM7.64306 7.6433C8.26818 7.01818 9.11603 6.66699 10.0001 6.66699C10.8841 6.66699 11.732 7.01818 12.3571 7.6433C12.9822 8.26842 13.3334 9.11627 13.3334 10.0003C13.3334 10.8844 12.9822 11.7322 12.3571 12.3573C11.732 12.9825 10.8841 13.3337 10.0001 13.3337C9.11603 13.3337 8.26818 12.9825 7.64306 12.3573C7.01794 11.7322 6.66675 10.8844 6.66675 10.0003C6.66675 9.11627 7.01794 8.26842 7.64306 7.6433Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.92676 9.99967C3.95634 12.9133 6.73611 14.9997 10.0001 14.9997C13.2648 14.9997 16.0439 12.9134 17.0734 9.99967C16.0439 7.08595 13.2648 4.99967 10.0001 4.99967C6.73611 4.99967 3.95634 7.086 2.92676 9.99967ZM1.25337 9.75001C2.42099 6.03176 5.8949 3.33301 10.0001 3.33301C14.1062 3.33301 17.5792 6.03181 18.7468 9.75001C18.7978 9.91254 18.7978 10.0868 18.7468 10.2493C17.5792 13.9675 14.1062 16.6663 10.0001 16.6663C5.8949 16.6663 2.42099 13.9676 1.25337 10.2493C1.20233 10.0868 1.20233 9.91254 1.25337 9.75001Z"
        fill={color}
      />
    </svg>
  );
}

IconEye.defaultProps = {
  width: '20',
  height: '20',
  color: 'currentColor',
};

export default IconEye;
