import React from 'react';
import AchFeeText from '../AchFeeText';
import AchFeeBonus from '../AchFeeBonus';

function AchFeePaidBy({ whoIsPaying, feeAmount }: { feeAmount: String, whoIsPaying: String }) {
  if (whoIsPaying() === 'LANDLORD') {
    return <AchFeeBonus feeAmount={feeAmount} />;
  }
  if (whoIsPaying() === 'TENANT') {
    return <AchFeeText text={`${feeAmount} processing fee per payment`} />;
  }
}

export default AchFeePaidBy;
