export const drawerContentStyles = (isDesktop: boolean = false) => ({
  height: isDesktop ? '100%' : 'calc(100% - 24px)',
  background: isDesktop ? 'brand.neutral.white' : 'transparent',
  margin: '0',
});

export const drawerBodyStyles = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'brand.neutral.white',
  m: '0px',
  paddingTop: '24px',
  paddingBottom: '80px',
};

export const drawerFooterStyles = () => {
  const footerStyles = {
    position: 'fixed',
    zIndex: '3',
    right: '0px',
    left: '0px',
    bottom: '0',
    backgroundColor: 'brand.neutral.white',
    boxShadow: 'none',
    borderTop: '1pt solid #DADFE6',
    minH: '72px',
    w: '100%',
  };

  return {
    min769: {
      ...footerStyles,
      p: '16px 24px',
    },
    min577max768: {
      ...footerStyles,
      p: '16px 20px',
      justifyContent: 'left',
    },
    max576: {
      ...footerStyles,
      p: '16px 16px',
    },
  };
};

export const drawerFooterContainerStyles = (isDesktop: boolean = false) => {
  if (!isDesktop) {
    return {
      padding: '20x 16px!important',
      margin: '20px 16px',
      width: 'auto',
    };
  }

  return {};
};

export const mobileDrawerHeaderStyles = {
  bg: 'brand.neutral.white',
  p: '8px 24px',
  color: 'brand.neutral.700',
  fontSize: 'lg',
  fontWeight: 'semibold',
  lineHeight: '26px',
  boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minH: '48px',
};

export const newDrawerHeaderStyles = {
  min769: {
    ...mobileDrawerHeaderStyles,
    boxShadow: 'none',
    borderBottom: '1pt solid #DADFE6',
    p: '16px 24px',
    minH: '64px',
    zIndex: '1000',
  },
  min577max768: {
    ...mobileDrawerHeaderStyles,
    boxShadow: 'none',
    borderBottom: '1pt solid #DADFE6',
    borderTopRadius: '0px',
    p: '16px 20px',
    minH: '64px',
    zIndex: '1000',
  },
  max576: {
    ...mobileDrawerHeaderStyles,
    boxShadow: 'none',
    borderBottom: '1pt solid #DADFE6',
    p: '12px 16px',
    borderTopRadius: '12px',
    minH: '56px',
    zIndex: '1000',
  },
};

export const newDrawerFooterStyles = (isDesktop: boolean = false) => ({
  min769: {
    ...drawerFooterStyles(isDesktop),
    p: '16px 24px',
  },
  min577max768: {
    ...drawerFooterStyles(isDesktop),
    p: '16px 20px',
  },
  max576: {
    ...drawerFooterStyles(isDesktop),
    p: '16px 16px',
  },
});

export const newDrawerBodyStyles = {
  min769: {
    ...drawerBodyStyles,
    color: 'brand.neutral.700',
    p: '24px',
  },
  min577max768: {
    ...drawerBodyStyles,
    color: 'brand.neutral.700',
    p: '20px',
  },
  max576: {
    ...drawerBodyStyles,
    color: 'brand.neutral.700',
    p: '16px',
  },
};
