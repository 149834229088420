export const iconButtonStyles = {
  padding: '0 !important',
  borderRadius: '4px !important', // TODO: Apply borderRadius: 6px when applying button styles from updated design system
  minWidth: '32px !important',
};

export const iconLinkStyles = {
  padding: '0 !important',
  borderRadius: '4px !important',
  minWidth: '32px !important',
  width: '32px',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
