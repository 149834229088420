import { fontColors } from '../colors';

export const baseStyles = {
  height: '100%',
  direction: 'column',
  borderRadius: '5px',
  boxShadow: { base: 'shadows.sm', lg: 'none' },
  backgroundColor: 'brand.neutral.white',
  cursor: 'default',
};

export const fontStyles = {
  header: {
    fontSize: 'lg',
    lineHeight: '24px',
    fontWeight: 'medium',
  },
};

export const cardOneStyles = {
  header: {
    p: '19px 24px',
    alignItems: 'center',
    borderColor: 'brand.darkBlue.200',
    borderBottomWidth: '1px ',
    borderStyle: ' solid',
    color: 'brand.neutral.700',
    maxHeight: '64px',
  },
  content: {
    w: '100%',
    height: '100%',
  },
};

export const cardTwoStyles = {
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: '4px',
    borderColor: 'brand.darkBlue.200',
    borderWidth: '1px ',
    boxShadow: 'none',
    color: 'brand.neutral.600',
    textStyle: 'xs',
    h: 'auto',
    position: 'relative',
  },
  innerContainer: {
    w: '100%',
    justify: 'space-between',
    p: '10px 16px',
  },
  borderContainer: {
    position: 'absolute',
    top: '0px',
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    h: '100%',
    borderLeftWidth: '4px',
  },
  closeButton: {
    outline: 'none',
    ml: '32px',
  },
};

export const getIconContainerStyles = (color) => {
  return fontColors[color];
};

export const cardSkeletonStyles = {
  container: {
    mb: '24px',
    mx: '24px',
    p: '24px',
    bg: 'brand.blue.100',
    h: 'auto',
  },
  title: {
    textStyle: 'headline-sm',
    color: 'brand.neutral.700',
    mb: '8px',
  },
  description: {
    textStyle: 'xs',
    color: 'brand.neutral.600',
    mb: '16px',
  },
};
