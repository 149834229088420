import React from 'react';

import type { IconProps } from '@icons/types';

function IconChevronUp({ color = '#012550', width = '16px', height = '16px' }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.75C0 2.33579 0.335786 2 0.75 2H15.25C15.6642 2 16 2.33579 16 2.75C16 3.16421 15.6642 3.5 15.25 3.5L0.75 3.5C0.335787 3.5 0 3.16421 0 2.75ZM0 8C0 7.58579 0.335786 7.25 0.75 7.25H15.25C15.6642 7.25 16 7.58579 16 8C16 8.41421 15.6642 8.75 15.25 8.75H0.75C0.335787 8.75 0 8.41421 0 8ZM0.75 12.5C0.335786 12.5 0 12.8358 0 13.25C0 13.6642 0.335786 14 0.75 14L15.25 14C15.6642 14 16 13.6642 16 13.25C16 12.8358 15.6642 12.5 15.25 12.5L0.75 12.5Z"
        fill={color}
      />
    </svg>
  );
}

export default IconChevronUp;
