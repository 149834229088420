import IconAddCard from './IconAddCard';
import IconArrowDropDown from './IconArrowDropDown';
import IconArrowDropUp from './IconArrowDropUp';
import IconBank from './IconBank';
import IconBankAccountAdd from './IconBankAccountAdd';
import IconBankAccountProgress from './IconBankAccountProgress';
import IconBankAccountSuccess from './IconBankAccountSuccess';
import IconBurgerMenu from './IconBurgerMenu';
import IconCalendar from './IconCalendar';
import IconCheck from './IconCheck';
import IconCheckCircleOutline from './IconCheckCircleOutline';
import IconChevronDown from './IconChevronDown';
import IconChevronLeft from './IconChevronLeft';
import IconChevronRight from './IconChevronRight';
import IconChevronUp from './IconChevronUp';
import IconClose from './IconClose';
import IconCompleted from './IconCompleted';
import IconDashboard from './IconDashboard';
import IconDefaultBank from './IconDefaultBank';
import IconExclamationCircle from './IconExclamationCircle';
import IconEye from './IconEye';
import IconEyeHide from './IconEyeHide';
import IconHelpCenter from './IconHelpCenter';
import IconInfo from './IconInfo';
import IconKey from './IconKey';
import IconLightning from './IconLightning';
import IconLockClosed from './IconLockClosed';
import IconLogOut from './IconLogOut';
import IconProductTour from './IconProductTour';
import IconProfile from './IconProfile';
import IconProperties from './IconProperties';
import IconProperty from './IconProperty';
import IconPropertyApartment from './IconPropertyApartment';
import IconPropertyNoBackground from './IconPropertyNoBackground';
import IconRightArrow from './IconRightArrow';
import IconRocket from './IconRocket';
import IconShieldCheck from './IconShieldCheck';
import IconStar from './IconStar';
import IconStars from './IconStars';
import IconStatusOverdue from './IconStatusOverdue';
import IconSupport from './IconSupport';
import IconTrash from './IconTrash';
import IconVerifiedBadge from './IconVerifiedBadge';
import IconVerifyEmail from './IconVerifyEmail';
import IconVirtualAssistant from './IconVirtualAssistant';
import IconWarningIllustration from './IconWarningIllustration';
import IconWarning from './IconWarning';
import IconWarningCircleOutline from './IconWarningCircleOutline';

export { default as IconAddCard } from './IconAddCard';
export { default as IconArrowDropDown } from './IconArrowDropDown';
export { default as IconArrowDropUp } from './IconArrowDropUp';
export { default as IconBank } from './IconBank';
export { default as IconBankAccountAdd } from './IconBankAccountAdd';
export { default as IconBankAccountProgress } from './IconBankAccountProgress';
export { default as IconBankAccountSuccess } from './IconBankAccountSuccess';
export { default as IconBurgerMenu } from './IconBurgerMenu';
export { default as IconCalendar } from './IconCalendar';
export { default as IconCheck } from './IconCheck';
export { default as IconCheckCircleOutline } from './IconCheckCircleOutline';
export { default as IconChevronDown } from './IconChevronDown';
export { default as IconChevronLeft } from './IconChevronLeft';
export { default as IconChevronRight } from './IconChevronRight';
export { default as IconChevronUp } from './IconChevronUp';
export { default as IconClose } from './IconClose';
export { default as IconCompleted } from './IconCompleted';
export { default as IconDashboard } from './IconDashboard';
export { default as IconDefaultBank } from './IconDefaultBank';
export { default as IconExclamationCircle } from './IconExclamationCircle';
export { default as IconEye } from './IconEye';
export { default as IconEyeHide } from './IconEyeHide';
export { default as IconHelpCenter } from './IconHelpCenter';
export { default as IconInfo } from './IconInfo';
export { default as IconKey } from './IconKey';
export { default as IconLightning } from './IconLightning';
export { default as IconLockClosed } from './IconLockClosed';
export { default as IconLogOut } from './IconLogOut';
export { default as IconProductTour } from './IconProductTour';
export { default as IconProfile } from './IconProfile';
export { default as IconProperties } from './IconProperties';
export { default as IconProperty } from './IconProperty';
export { default as IconPropertyApartment } from './IconPropertyApartment';
export { default as IconRightArrow } from './IconRightArrow';
export { default as IconRocket } from './IconRocket';
export { default as IconShieldCheck } from './IconShieldCheck';
export { default as IconStar } from './IconStar';
export { default as IconStars } from './IconStars';
export { default as IconStatusOverdue } from './IconStatusOverdue';
export { default as IconSupport } from './IconSupport';
export { default as IconTrash } from './IconTrash';
export { default as IconVerifiedBadge } from './IconVerifiedBadge';
export { default as IconVerifyEmail } from './IconVerifyEmail';
export { default as IconVirtualAssistant } from './IconVirtualAssistant';
export { default as IconWarningIllustration } from './IconWarningIllustration';
export { default as IconWarning } from './IconWarning';
export { default as IconWarningCircleOutline } from './IconWarningCircleOutline';

// List of all icons
export const AllIcons = [
  IconAddCard,
  IconArrowDropDown,
  IconArrowDropUp,
  IconBank,
  IconBankAccountAdd,
  IconBankAccountProgress,
  IconBankAccountSuccess,
  IconBurgerMenu,
  IconCalendar,
  IconCheck,
  IconCheckCircleOutline,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
  IconClose,
  IconCompleted,
  IconDashboard,
  IconDefaultBank,
  IconExclamationCircle,
  IconEye,
  IconHelpCenter,
  IconInfo,
  IconKey,
  IconLightning,
  IconLockClosed,
  IconLogOut,
  IconProductTour,
  IconProfile,
  IconProperties,
  IconProperty,
  IconPropertyApartment,
  IconPropertyNoBackground,
  IconRightArrow,
  IconRocket,
  IconShieldCheck,
  IconStar,
  IconStars,
  IconStatusOverdue,
  IconSupport,
  IconTrash,
  IconVerifiedBadge,
  IconVerifyEmail,
  IconVirtualAssistant,
  IconWarningIllustration,
  IconWarning,
  IconWarningCircleOutline,
];

// Type that represents the union of all icon components
export type BaselaneIcon =
  | IconAddCard
  | IconArrowDropDown
  | IconArrowDropUp
  | IconBank
  | IconBurgerMenu
  | IconCalendar
  | IconCheck
  | IconCheckCircleOutline
  | IconChevronDown
  | IconChevronLeft
  | IconChevronRight
  | IconChevronUp
  | IconClose
  | IconCompleted
  | IconDashboard
  | IconDefaultBank
  | IconExclamationCircle
  | IconEye
  | IconEyeHide
  | IconHelpCenter
  | IconInfo
  | IconKey
  | IconLightning
  | IconLockClosed
  | IconLogOut
  | IconLogo
  | IconProductTour
  | IconProfile
  | IconProperties
  | IconProperty
  | IconPropertyApartment
  | IconPropertyNoBackground
  | IconRightArrow
  | IconRocket
  | IconShieldCheck
  | IconStar
  | IconStars
  | IconStatusOverdue
  | IconSupport
  | IconTrash
  | IconVerifiedBadge
  | IconVerifyEmail
  | IconVirtualAssistant
  | IconWarningIllustration
  | IconWarning
  | IconWarningCircleOutline;
